import noop from 'lodash/noop';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { ShopId } from '../constants/shop';
import * as translations from '../translations';

const TranslationsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  let messages, locale;

  switch (process.env.SHOP_ID) {
    case ShopId.CH:
      messages = translations.ch;
      locale = 'de-CH';
      break;
    default:
      messages = translations.de;
      locale = 'DE';
  }

  return (
    <IntlProvider locale={locale} messages={messages} onError={noop}>
      {children}
    </IntlProvider>
  );
};

export default TranslationsProvider;
