import { captureMessage, setTag } from '@sentry/browser';
import IconCheck from 'assets/icons/check-toast-refferal.svg';
import IconCircleCheck from 'assets/icons/circle-check.svg';
import IconClose from 'assets/icons/close.svg';
import cn from 'classnames';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { Toast, ToastId } from 'react-toastify';
import { gtm, viewElement } from 'tracking';
import { resolveTranslation } from './translations';

let toast: Toast;
if (typeof window !== 'undefined') {
  // eslint-disable-next-line
  toast = require('react-toastify').toast;
}

/**
 * Input for FormattedHTMLMessage
 * Since it's difficult to retrieve props interface from class component, FormattedHTMLMessage,
 * these are just approximate input built from scratch.
 * Not ideal but I assume it's okay since we can detect errors on build time when the definitions has become different.
 */
interface FormattedHTMLMessageProps extends Omit<MessageDescriptor, 'id'> {
  values?: Record<string, any>;
  tagName?: React.ElementType<any>;
}

type ToastOptions = Parameters<typeof toast.success>[1];

interface ErrorMessageProps extends FormattedHTMLMessageProps {
  /**
   * Actually message Id, in our case such as 'account:main-page:title'.
   * If you want to just show arbitrary string, use defaultMessage instead
   * */
  error: MessageDescriptor['id'];
  fallbackError?: string;
  /** Amazon request ID from api header to track down one specific api call */
  xAmznRequestId?: string;
  /** Amazon trace ID from api header to track down one specific api call */
  xAmznTraceId?: string;
}

type _ErrorMessageProps = ErrorMessageProps;

type ShowErrorToastProps = _ErrorMessageProps & ToastOptions;

const ErrorTranslation: React.FC<ErrorMessageProps> = ({
  error,
  fallbackError,
  defaultMessage,
  values,
}) => {
  const intl = useIntl();
  try {
    const possibleTranslations = [
      error,
      fallbackError,
      !defaultMessage && 'error:unknown',
    ].filter((key) => typeof key === 'string') as string[];
    return (
      resolveTranslation(intl, possibleTranslations, values) ||
      defaultMessage ||
      (null as any)
    );
  } catch (error) {
    return null;
  }
};

export const showErrorToast = ({
  error,
  fallbackError,
  defaultMessage,
  values,
  autoClose = 5000,
  caller,
  xAmznTraceId,
  xAmznRequestId,
  ...toastProps
}: ShowErrorToastProps & { caller?: string }): void => {
  if (typeof window === 'undefined') {
    return;
  }

  const timestamp = new Date().getTime().toString();
  const uniqueId = Math.random().toString(36).substring(2);

  gtm(
    viewElement({
      element: ['Error Toast', uniqueId, timestamp],
    })
  );

  setTag('errorToastId', uniqueId);

  if (typeof error === 'string' && error.includes('not found')) {
    setTag('errorType', 'product-not-found');
  }

  captureMessage('Error Toast', {
    level: 'error',
    extra: {
      uniqueId,
      timestamp,
      error,
      caller,
      xAmznTraceId,
      xAmznRequestId,
    },
  });

  const toastId = (error ||
    fallbackError ||
    defaultMessage ||
    'error:unknown') as unknown as ToastId;

  if (toast.isActive(toastId)) {
    toast.update(toastId, {
      autoClose,
    });
  } else {
    toast.error(
      <ErrorTranslation
        error={error}
        fallbackError={fallbackError}
        defaultMessage={defaultMessage}
        values={values}
      />,
      { toastId, autoClose, ...toastProps }
    );
  }
};

interface SuccessMessageProps extends FormattedHTMLMessageProps {
  /**
   * Actually message Id, in our case such as 'account:main-page:title'.
   * If you want to just show arbitrary string, use defaultMessage instead
   * */
  message: MessageDescriptor['id'];
  couponToast?: boolean;
  iconCheck?: boolean;
  iconClassName?: string;
}

type _SuccessMessageProps = SuccessMessageProps;

export type ShowSuccessToastProps = _SuccessMessageProps & ToastOptions;

const CloseButton = (): JSX.Element => (
  <IconClose className="my-auto h-3 w-3 text-[#3ECBAF]" />
);

export const showSuccessToast = ({
  message,
  defaultMessage,
  description,
  values,
  tagName,
  autoClose = 5000,
  couponToast,
  iconCheck = false,
  iconClassName,
  progressClassName,
  ...toastProps
}: ShowSuccessToastProps): void => {
  const toastId = (message || defaultMessage) as unknown as ToastId;
  if (typeof window === 'undefined' || !toastId) {
    return;
  }

  if (toast.isActive(toastId)) {
    toast.update(toastId, {
      autoClose,
    });
  } else {
    toast.success(
      <>
        {iconCheck && (
          <IconCircleCheck
            className={cn('mr-2 inline-block h-6 w-6', iconClassName)}
          />
        )}
        <FormattedMessage
          id={message}
          defaultMessage={defaultMessage}
          {...{
            description,
            values,
            tagName,
          }}
        />
      </>,
      {
        toastId,
        className: couponToast
          ? 'bg-[#E2F4F4] border border-[#80D1CD] rounded-lg text-brand-primary p-3 text-sm m-2 shadow-lg'
          : 'bg-brand-primary',
        progressClassName,
        autoClose: couponToast ? false : autoClose,
        ...(couponToast && { position: toast.POSITION.TOP_RIGHT }),
        ...(couponToast && { closeButton: <CloseButton /> }),
        ...toastProps,
      }
    );
  }
};

type ShowSuccessReactToast = {
  toastId: string;
  content: React.ReactNode;
};

export const showSuccessReactToast = ({
  toastId,
  content,
}: ShowSuccessReactToast): void => {
  if (toast.isActive(toastId)) {
    toast.update(toastId, {
      autoClose: 5000,
    });
  } else {
    toast.success(content);
  }
};

type ShowNewSuccessToastProps = {
  /** Id for toast. could be any string */
  toastId: string;
  /** Main content. if it's string, it's passed to format message  */
  mainContent?: React.ReactNode;
  /** Sub content. if it's string, it's passed to format message  */
  subContent?: React.ReactNode;
};

/**
 * Success toast with new design
 */
export const showNewSuccessToast = ({
  toastId,
  mainContent,
  subContent,
}: ShowNewSuccessToastProps): void => {
  if (toast.isActive(toastId)) {
    toast.update(toastId, {
      autoClose: 5000,
    });
  } else {
    toast(
      <div className="flex flex-row flex-nowrap">
        <div className="">
          <IconCheck className="mr-2" />
        </div>
        <div>
          {mainContent && (
            <span className="text-base font-black text-brand-primary">
              {typeof mainContent === 'string' ? (
                <p>
                  <FormattedMessage id={mainContent} />
                </p>
              ) : (
                <>{mainContent}</>
              )}
            </span>
          )}
          {subContent && (
            <span className="text-grey ">
              {typeof subContent === 'string' ? (
                <p>
                  <FormattedMessage id={subContent} />
                </p>
              ) : (
                <>{subContent}</>
              )}
            </span>
          )}
        </div>
      </div>,
      {
        closeButton: <IconClose className="mt-1 h-4 w-4 text-grey" />,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        className:
          'bg-white border border-[#80D1CD] rounded-lg text-brand-primary text-sm shadow-lg p-4',
      }
    );
  }
};

type ShowRefferalSuccessToast = {
  message: React.ReactNode;
  id: string;
};
/**
 * @TODO : migrate to generic function
 */
export const showReferralSuccessToast = ({
  message,
  id,
}: ShowRefferalSuccessToast): void => {
  if (toast.isActive(id)) {
    toast.update(id, {
      autoClose: 5000,
    });
  } else {
    toast.success(
      <div className="flex flex-row flex-nowrap">
        <div>
          <IconCheck className="mx-2 flex" />
        </div>
        <div>{message}</div>
        <div className="h-0 w-5">
          <CloseButton />
        </div>
      </div>,
      {
        className:
          'bg-white border border-[#80D1CD] rounded-lg text-brand-primary text-sm shadow-lg',
        position: toast.POSITION.TOP_RIGHT,
      }
    );
  }
};
