import cn from 'classnames';
import React, { ReactElement } from 'react';

interface ICloneProps {
  className: string;
}

const Clone: React.FC<React.PropsWithChildren<ICloneProps>> = ({
  children,
  className,
}) => {
  return React.cloneElement(React.Children.only(children as ReactElement), {
    className: cn(className, (children as ReactElement).props.className),
  });
};

export default Clone;
