import cn from 'classnames';
import SmallCaps from 'components/typography/small-caps';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import IconDhlGreen from '../../../../assets/icons/dhl-green.svg';
import IconDhl from '../../../../assets/icons/dhl.svg';
import IconDiePost from '../../../../assets/icons/die-post.svg';
import IconDpd from '../../../../assets/icons/dpd.svg';

interface DeliveryLogosProps {
  className?: string | undefined;
}

const DeliveryLogos: React.FC<DeliveryLogosProps> = ({ className }) => {
  return (
    <div
      className={cn(
        'flex flex-col justify-center px-4 pt-6 text-center  md:justify-start md:pt-0 md:text-left lg:px-8',
        className
      )}
    >
      <SmallCaps>
        <div className="whitespace-nowrap text-brand-primary">
          <FormattedMessage id="footer:title-shipping" />
        </div>
      </SmallCaps>
      <div className="flex items-center justify-center pt-2 md:justify-start">
        {process.env.SHOP_ID === 'CH' ? (
          <IconDiePost className="h-5 w-auto lg:h-6" />
        ) : (
          <>
            <IconDpd className="mr-3 h-8 w-auto" />
            <div
              style={{ background: '#FECC00' }}
              className="mr-3 flex h-12 w-20 items-center justify-center"
            >
              <IconDhl className="h-auto w-20" />
            </div>
            <IconDhlGreen className="mr-3 h-9 w-auto " />
          </>
        )}
      </div>
    </div>
  );
};

export default DeliveryLogos;
