/**
 *  Wrapped `Object.keys` function. Returns `keys` as Union type.
 *  @example
 *  const obj = {
 *    a: 10,
 *    b: 'text',
 *    c: true,
 *  };
 *
 *  Object.keys(obj).forEach((key) => {
 *    // Compile error because key are typed string
 *    console.log(key, obj[key]);
 *  });
 *
 *  typedKeys(obj).forEach((key) => {
 *     // No errors because keys are typed 'a' | 'b' | 'c'
 *     console.log(key, obj[key]);
 *  });
 */
export function typedKeys<T extends object>(obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>;
}

/**
 * Useful for filter out null / undefined values from array.
 *
 * @example
 *   const array = [null, 'hoge', null, 'hoge'];
 *
 *   array
 *     .filter((v) => !!v)
 *     .forEach((v) => {
 *       // v is still evaluated as null | string
 *       v.toLowerCase();
 *     });
 *
 *   array.filter(omitNullableHandler)
 *   .forEach((v) => {
 *     // v is evaluated as string
 *     v.toLowerCase();
 *   });
 *
 */
export function omitNullableHandler<T>(item: T): item is NonNullable<T> {
  return item !== null && item !== undefined;
}

/**
 * Retrieve return type of Promise
 * After TypeScript 4.5 version, there's built-in version of Awaited
 */
export type Awaited<T> = T extends PromiseLike<infer U> ? U : T;

/** /
 * Check if object is empty object
 */
export function isEmptyObject(obj: Record<string, any>): boolean {
  return Object.keys(obj).length === 0;
}

/**
 * Asserts if boolean Array
 */
export function IsBooleanArray(
  array: string[] | boolean[]
): array is boolean[] {
  if ((array as any[]).every((value) => typeof value === 'boolean')) {
    return true;
  } else {
    return false;
  }
}
