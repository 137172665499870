import type { ProductType } from './product';

export type PdProductItem =
  | BasicPdProductItem
  | PdSubscriptionProductItem
  | GiftProductItem;

export interface BasicPdProductItem {
  id: number;
  variantId: number;
  productId: number;
  /** @deprecated Same value as variantId. use variantId instead */
  masterVariantId: number;
  masterProductId: string;
  productTitle: string;
  variantTitle: string;
  productType: ProductType;
  quantity: number;
  /** Price with subscribe discount by coupon and subscribe discount considered (including Ninetailed personalization) */
  price: number;
  handle?: string;
  /** Default price without any discount */
  defaultPrice: number;
  imageSrc?: string;
  title: string;
  taxRatios: Array<[number, number]>;
  sku: string;
  tags: string;
  /** Weight in grams */
  grams: number;
  /** This is present if the item is calculated based on experiment variant specified optimize headers. Debug purpose only */
  pricingVariant?: string;
  /** True if this item is subscribable */
  subscribable: boolean;
  /** Compared at price to show the strike price. Usually only valid for bundles */
  compareAtPrice?: number;
  /** First subscription discount percentage value. e.g 10 -> 10% discount */
  subscriptionDiscount?: number;
  /** Discounted price after first order. which means 10% discount default */
  subscriptionPrice?: number;
  /** Amount sum of discounted price */
  discountReduction: number;
}

/** @TODO : add comment item interface when it's in Subscription  */
export interface PdSubscriptionProductItem extends BasicPdProductItem {
  compareAtPrice: number;
  /** like  "4week" */
  interval: string;
  /** Reduced price for subscription with Ninetailed personalization considered (i.e global discount)*/
  subscriptionPrice: number;
}

export function isSubscriptionItem(item): item is PdSubscriptionProductItem {
  return typeof item.interval === 'string' && item.interval !== '';
}

export interface GiftProductItem extends BasicPdProductItem {
  /** This is present When item is redeem for points and if not, this is zero.*/
  points: number;
  /** True when item is added as a redemption gift */
  redeemItem: boolean;
}

export function isGiftProductItem(item): item is GiftProductItem {
  return item.redeemItem === true && item.points > 0 && item.price === 0;
}
