export default {
  'puppyclub:puppy-phase:A':
    'Der Pets Deli Pfötchenclub - jetzt kostenlos anmelden!',
  'puppyclub:puppy-phase:B': 'Über meinen Welpen',
  'puppyclub:puppy-phase:C': 'Über mich',

  'puppyclub:register': 'KOSTENLOS ANMELDEN',

  'puppyclub:puppy-phase:thank-you':
    'Danke für deine Anmeldung und willkommen im Pets Deli Pfötchenclub!',
  'puppyclub:puppy-phase:thank-you-email-confirmation':
    'Wir schicken dir eine Bestätigung per E-Mail - schau am besten gleich rein!',
  'puppyclub:puppy-phase:thank-you-subtitle':
    'In den nächsten Wochen erwarten dich tolle exklusive Angebote, viele hilfreiche Informationen rund um deinen Welpen und natürlich ganz viel Pfötchencontent. Viel Spaß!',
};
