import { ProductType, ProteinGroup } from 'api/foodaid/questions';

export default {
  [ProteinGroup.Poultry]: 'Geflügel',
  [ProteinGroup.BeefAndVeal]: 'Rind & Kalb',
  [ProteinGroup.Horse]: 'Pferd',
  [ProteinGroup.Wild]: 'Wild',
  [ProteinGroup.Fish]: 'Fisch',
  [ProteinGroup.Lamb]: 'Lamm',
  [ProteinGroup.Goat]: 'Ziege',
  [ProteinGroup.Kangaroo]: 'Känguru',
  [ProteinGroup.VegetarianOrVegan]: 'Vegetarisch/Vegan',
  [ProductType.Dryfood]: 'Trockenfutter',
  [ProductType.Wetfood]: ' Nassfutter',
  [ProductType.Barf]: ' BARF',
  [`usp-${ProteinGroup.Poultry}`]:
    '<fragment> <li> Leicht verdaulich & fettarm</li> <li> Beliebteste Fleischsorte </li> </fragment>',
  [`usp-${ProteinGroup.BeefAndVeal}`]:
    '<fragment> <li>Hohe Akzeptanz </li> <li>Reich an Eisen & Phosphor</li> </fragment> ',
  [`usp-${ProteinGroup.Horse}`]:
    '<fragment> <li>Ideal für Allergiker </li> <li> Besonders mager </li> </fragment> ',
  [`usp-${ProteinGroup.Wild}`]:
    '<fragment> <li>Fleisch aus freier Wildbahn </li> <li> Hochverdaulich</li> </fragment> ',
  [`usp-${ProteinGroup.Fish}`]:
    '<fragment> <li>Reich an natürlichen Omega-3-Fettsäuren </li> </fragment> ',
  [`usp-${ProteinGroup.Lamb}`]:
    '<fragment> <li>Ideal für Allergiker </li> <li>Besonders geschmacksintensiv </li> </fragment> ',
  [`usp-${ProteinGroup.Goat}`]:
    '<fragment> <li>Ideal für Allergiker </li> <li> Besondres gut verdaulich</li> </fragment> ',
  [`usp-${ProteinGroup.Kangaroo}`]:
    '<fragment> <li>Ideal für Allergiker </li> <li> Besondres gut verdaulich </li> </fragment> ',
  [`usp-${ProteinGroup.VegetarianOrVegan}`]:
    '<fragment> <li>Für umweltbewusste Fellnasen </li> <li> Perfekt bei Unverträglichkeiten gegen tierische Proteine </li> </fragment> ',
};
