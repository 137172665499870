import { useCommon } from 'contexts/common';
import { useState } from 'react';
import useIntersection, { UseIntersectionProps } from './use-interaction';

export interface useImpressionTrackingProps
  extends Omit<UseIntersectionProps, 'callBack'> {
  /** Key to identify tracking store */
  impressionStoreKey: string;
  /** Impression Id */
  impressionId: string | number;
  /** Tracking function, which is gtm impression */
  trackingFn: UseIntersectionProps['callBack'];
}

/** Track impression once per page visit */
const useImpressionTracking = ({
  impressionStoreKey,
  impressionId,
  trackingFn,
  ...others
}: useImpressionTrackingProps): void => {
  const { trackedImpressionStorage, setTrackedImpressionStorage } = useCommon();
  const [hasImpressionTracked, setHasImpressionTracked] =
    useState<boolean>(false);

  useIntersection({
    callBack: (isInViewport: boolean) => {
      if (!isInViewport || hasImpressionTracked) return;

      const targetArray = trackedImpressionStorage[impressionStoreKey] || [];
      if (!targetArray.includes(impressionId)) {
        try {
          trackingFn(isInViewport);
        } catch (error) {
          console.log('impression tracking error', error);
        } finally {
          setHasImpressionTracked(true);

          setTrackedImpressionStorage({
            ...trackedImpressionStorage,
            [impressionStoreKey]: [...targetArray, impressionId],
          });
        }
      }
    },
    ...others,
  });
};

export default useImpressionTracking;
