// Helper type to extract keys from TestData
export type Keys<T> = T extends object ? keyof T : never;

// Deep merge function
export function deepMerge<T>(target: T, source: Partial<T>): T {
  const result = { ...target };
  for (const key in source) {
    if (source[key] instanceof Array) {
      // @ts-expect-error hogehoge
      const concatedArray = (result[key] || []).concat(source[key]);
      result[key] = concatedArray.filter(
        (value, index) => concatedArray.indexOf(value) === index
      );
    } else if (typeof source[key] === 'object' && source[key] !== null) {
      // @ts-expect-error hogehoge
      result[key] = deepMerge(result[key] || {}, source[key]);
    } else {
      // @ts-expect-error hogehoge
      result[key] = source[key];
    }
  }
  return result;
}
