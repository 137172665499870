export default {
  // hot-fix : PT-2864
  subscriptionPriceTestAmounts: {
    0: 10,
    1: 20,
    2: 10,
    3: 10,
  },
  subscriptionDiscount: 10,
};
