import { IntervalOptions, SubscriptionInterval } from 'constants/order';
import { useMemo } from 'react';

interface Input {
  productIds?: number[];
}

interface Output {
  translationKey: string;
  translationKeyCdp: string;
  value: SubscriptionInterval;
  daysCount: number;
}

const PRODUCT_IDS =
  process.env.PD_ENV === 'production'
    ? [8240648618250, 8240677191946, 6978956361762]
    : [1576422309930];
/**
 * Returns filtered interval options based on productId
 * It is used to filter interval options for products that are not available for 2 and 6 weeks.
 * Actually it is used on the PDP and on the cart subscription interval form.
 * @see https://petsdeli.atlassian.net/browse/PT-4911
 */
const useFilteredIntervalOptions = ({
  productIds = [],
}: Input): { filteredIntervalOptions: Output[] } => {
  const filteredIntervalOptions = useMemo(() => {
    if (productIds && PRODUCT_IDS.some((id) => productIds.indexOf(id) > -1)) {
      return IntervalOptions.filter(
        (option) =>
          option.value !== SubscriptionInterval.TWO_WEEKS &&
          option.value !== SubscriptionInterval.SIX_WEEKS
      );
    }
    return IntervalOptions;
  }, [productIds]);

  return { filteredIntervalOptions };
};

export default useFilteredIntervalOptions;
