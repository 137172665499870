import useExperiment from 'hooks/common/use-experiment';
import { useRouter } from 'next/router';
import React, { memo, useEffect } from 'react';

type PropTypes = {
  testId: string;
  currentPath: string;
};

/**
 * Component to create isolated scope to utilize useExperiment.
 * Attach handler for redirection.
 */
const RedirectionTestWrapper: React.FC<PropTypes> = ({
  testId,
  currentPath,
}) => {
  const { baseUrl, redirectUrl, trackComponentViewFn } = useExperiment({
    baseEntryId: testId,
  });

  const { replace } = useRouter();

  useEffect(() => {
    if (redirectUrl && baseUrl && currentPath.indexOf(baseUrl) > -1) {
      // Make sure to capture Ninetailed experience
      replace(redirectUrl).then(trackComponentViewFn);
    }
  }, [baseUrl, currentPath, redirectUrl, replace, trackComponentViewFn]);

  return null;
};

export default memo(RedirectionTestWrapper);
