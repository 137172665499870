import { useProfile } from '@ninetailed/experience.js-next';
import { AudienceIds } from 'constants/audience-id';

type Output = {
  isEc: boolean;
  loading: boolean;
};

export const useIsExistingCustomer = (): Output => {
  const { profile, loading } = useProfile();

  const isExistingCustomer = profile?.audiences.includes(
    AudienceIds.EC_AUDIENCE_ID
  );

  return {
    loading,
    isEc: !!isExistingCustomer,
  };
};
