export default {
  'magazine:top-page:title': 'Pets Deli Magazin',
  'magazine:top-page:subtitle':
    'Entdecke unsere Themen rund um die Ernährung, Gesundheit und Erziehung von Hunden und Katzen.',
  'magazine:top-page:in-focus': 'Aktuelle Themen',
  'magazine:top-page:main-theme': 'Beliebte Kategorien',
  'magazine:top-page:top-articles': 'Unsere Artikel',
  'magazine:author-page:title': 'Neueste Artikel von {name}',
  'magazine:article-page:section-cta:default-title': 'Lesen Sie auch',
};
