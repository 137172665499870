export default {
  'express-checkout:page-title': 'Bestellung abschließen',
  'express-checkout:cart-items:size': 'Größe',
  'express-checkout:cart-items:subscription': 'Abo',
  'express-checkout:cart-items:remove': 'Entfernen',

  'express-checkout:header':
    'Möchtest du deinen Einkauf wirklich abbrechen und zurück zum Warenkorb?',
  'express-checkout:header:button-continue': 'Einkauf fortsetzen',
  'express-checkout:header:button-to-cart': 'Zum Warenkorb',

  'express-checkout:summary-prices:not-use-discount': 'Nicht verwenden',
  'express-checkout:summary-prices:use-discount': 'Verwenden',

  'express-checkout:confirm:error-message':
    'Leider ist beim Bezahlvorgang etwas schief gelaufen.',
  'express-checkout:confirm:thank-you-message:header':
    'Danke für deine Bestellung',
  'express-checkout:confirm:thank-you-message:sub-header':
    'Wir benachrichtigen dich, sobald sich deine Sendung auf den Weg zu dir macht.',
  'express-checkout:confirm:button-continue-shopping': 'Weiter stöbern',

  'express-checkout:summary-form:shipping-and-payment:shipping': 'Lieferung',
  'express-checkout:summary-form:shipping-and-payment:extra-options':
    'Zusatzoptionen',
  'express-checkout:summary-form:shipping-and-payment:eco-option': 'Eco Option',
  'express-checkout:summary-form:shipping-and-payment:eco-option-point-1':
    'Umweltfreundliche Isolierverpackung aus recycelter PET-Wolle',
  'express-checkout:summary-form:shipping-and-payment:eco-option-point-2':
    'Nachhaltig und wieder vollständig recyclebar',

  'express-checkout:summary-form:re-auth-loading-message':
    'Die Abwicklung der Zahlung kann einige Sekunden in Anspruch nehmen.',

  'express-checkout:summary-form:addresses:complete-address':
    'Vor der Zahlung bitte die Adresse vervollständigen.',
};
