import { KinesisClient, PutRecordsCommand } from '@aws-sdk/client-kinesis';

interface SendRecordsParams {
  streamArn: string;
  records: Array<{ Data: Uint8Array; PartitionKey: string }>;
}

/**
 * Sends records to the specified Kinesis stream.
 */
export const sendRecordsToKinesis = async ({
  streamArn,
  records,
}: SendRecordsParams): Promise<void> => {
  const client = new KinesisClient({
    region: 'eu-central-1',
    credentials: {
      accessKeyId: 'AKIARLMMW2SUVTHXEG5L',
      secretAccessKey: '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',
    },
  });

  try {
    await client.send(
      new PutRecordsCommand({
        StreamARN: streamArn,
        Records: records,
      })
    );
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error sending data to Kinesis:', error.message);
    } else {
      throw error;
    }
  }
};

const streamArn =
  'arn:aws:kinesis:eu-central-1:093173765289:stream/eventtracking';

/**
 * Sends the provided data to the Kinesis stream.
 */
export const sendData = async ({ data, partitionKey }): Promise<void> => {
  const records = [
    {
      Data: data,
      PartitionKey: partitionKey,
    },
  ];

  await sendRecordsToKinesis({ streamArn, records });
};
