export const customAttributesFromShopifyFormat = (
  customAttributes: Array<any>
) =>
  Object.fromEntries(
    customAttributes.map(({ key, value }: any) => [key, value])
  );

export const customAttributesToShopifyFormat = (customAttributes: {
  [key: string]: string;
}) =>
  customAttributes
    ? Object.entries(customAttributes).map(([key, value]) => ({ key, value }))
    : [];
