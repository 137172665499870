import { create } from 'zustand';

interface HeaderStore {
  isHeaderHidden: boolean;
  setHeaderHidden: (isHidden: boolean) => void;
}

export const useHeaderStore = create<HeaderStore>((set) => ({
  isHeaderHidden: true,
  setHeaderHidden: (isHidden: boolean) => set({ isHeaderHidden: isHidden }),
}));
