import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { track } from 'api/track';
import ApplePayButton from 'components/apple-pay-button';
import ModalSlideUp from 'components/modal-slide-up';
import {
  formatCustomAttributes,
  getShopifyItemType,
  ItemMergeKeys,
  ShopifyItemType,
} from 'hooks/checkout/utils';
import useApplyCoupon from 'hooks/coupon/use-apply-coupon';
import { KnownCustomAttributes } from 'interfaces/checkout';
import get from 'lodash/get';
import { customAttributesToShopifyFormat } from 'models/shopify-common';
import CouponModalPopUp from 'modules/checkout/components/coupon-modal-pop-up';
import { useRouter } from 'next/router';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocalStorage, useSessionStorage } from 'react-use';
import {
  CheckoutLineItemInput,
  CheckoutLineItemsReplaceMutation,
} from 'shopifyTypes';
import { Gtm, gtm } from 'tracking/gtm';
import { isAxiosError } from 'utils/axios';
import { showErrorToast } from 'utils/toasts';
import { createPetsdeliCheckout } from '../api/checkout';
import { SubscriptionInterval } from '../constants/order';
import { CheckoutHookProps, LineItem, useCheckout } from '../hooks/checkout';
import {
  CreateEffectiveCartPayloadInput,
  EffectiveCartPayload,
  useEffectiveCart,
  useEffectiveCartPayload,
  useEffectiveCartPayloadItems,
} from '../hooks/effective-cart/useEffectiveCart';
import { Cart, EffectiveCart, SubscriptionElement } from '../interfaces/cart';
import {
  isGiftProductItem,
  isSubscriptionItem,
  PdProductItem,
} from '../interfaces/line-item';
import { Discount, DiscountType } from '../interfaces/order';
import { ShippingMethod } from '../interfaces/shipping';
import { customerLastIncompleteCheckoutStore } from '../local-storage';
import { useAuth } from './auth';
import { useReferalToken } from './referal';
import { useTrackingContext } from './tracking';

type RedemptionLineItem = LineItem & {
  /** Points for redemption gift  */
  points: number;
};

export interface EffectiveCartSubscriptionItem extends SubscriptionElement {
  items: PdProductItem[];
}

export interface CartContextInterface {
  canUseCoupon: boolean;
  cart: Cart;
  cartTotal: number;
  containsPreorderItem: boolean;
  containsPreorderItemsOnly: boolean;
  containsSubscriptionItem: boolean;
  containsRedemptionItem: boolean;
  couponCode: string | null | undefined;
  discount?: Discount;
  effectiveCart: EffectiveCart | undefined;
  effectiveCartErrors?: EffectiveCart['errors'];
  effectiveCartPayload: EffectiveCartPayload;
  effectiveCartPayloadItems: CreateEffectiveCartPayloadInput['items'];
  isBonusModalOpen: boolean;
  isCartEmpty: boolean;
  isCartReady: boolean;
  groupedItems: {
    subscriptionItems: EffectiveCartSubscriptionItem[];
    oneOffPurchaseItems: EffectiveCart['oneOffPurchaseItems'];
  };
  isCartUpdating: boolean;
  isCheckoutBeingCreated: boolean;
  items: EffectiveCart['items'];
  itemsCount: number;
  loading: boolean;
  shippingMethod: ShippingMethod | null;
  useCredits: boolean;
  oosItems: PdProductItem[];
  addProductsToCart: CheckoutHookProps['addItems'];
  addProductToCart: CheckoutHookProps['addItem'];
  addSubItemToCart: (arg: {
    variantId: number;
    quantity: number;
    interval: SubscriptionInterval;
  }) => ReturnType<CheckoutHookProps['addItem']>;
  addSubItemsToCart: (
    arg: Array<{
      variantId: number;
      quantity: number;
      interval: SubscriptionInterval;
    }>
  ) => ReturnType<CheckoutHookProps['addItem']>;
  addRedemptionGiftToCart: (input: RedemptionLineItem) => Promise<void>;
  /** @deprecated use updateItemQuantity instead */
  changeProductQuantity: CheckoutHookProps['updateLineItem'];
  goToCheckout: () => Promise<any>;
  refetchCart: () => void;
  reFetchEffectiveCart: () => Promise<EffectiveCart | undefined>;
  resetCheckout: () => void;
  setCouponCode: (code: string | null) => void;
  setExpressCheckoutCustomerId: (customerId: number | null) => void;
  setIsApplePayModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsBonusModalOpen: (v: boolean) => void;
  setShippingMethod: (shippingMethod: ShippingMethod | null) => void;
  setUseCredits: (useCredits: boolean) => void;
  updateItems: (input: {
    items: PdProductItem[];
    interval?: SubscriptionInterval;
  }) => Promise<CheckoutLineItemsReplaceMutation>;
  updateItemQuantity: (input: {
    item: PdProductItem;
    quantity: number;
  }) => Promise<void>;
  /** Update subscription interval of item, if interval is omitted, item is converted to one-time purchase   */
  updateItemSubscriptionInterval: (input: {
    item: PdProductItem;
    interval?: SubscriptionInterval;
  }) => Promise<void>;
  /** Update subscription interval of items, if interval is omitted, item is converted to one-time purchase   */
  updateItemsSubInterval: (input: {
    items: { item: PdProductItem; interval?: SubscriptionInterval | '' }[];
  }) => Promise<CheckoutLineItemsReplaceMutation>;
  updateRedemptionGiftQuantity: (input: {
    variantId: number;
    quantity: number;
  }) => Promise<void>;
}

const CartContext = React.createContext<CartContextInterface>({} as any);

interface PropTypes {
  children: React.ReactNode;
}

/**
 * Factory method for addOrderAttribute.
 * We need to add customAttribute of sort so that we can keep the order in which items are added to cart after shopify checkout creation.
 */
const addOrderAttributeFactory =
  (sortValue: number) =>
  (customAttributes: CheckoutLineItemInput['customAttributes']) => {
    if (!customAttributes) {
      return [
        {
          key: 'sort',
          value: '' + sortValue,
        },
      ];
    }
    if (!customAttributes.find((val) => val.key === 'sort')) {
      customAttributes?.push({
        key: 'sort',
        value: '' + sortValue,
      });
    }
    return customAttributes;
  };

export const CartContextProvider: React.FC<PropTypes> = ({ children }) => {
  const paypalExpressInitialOptions = {
    clientId: process.env.PAYPAL_CLIENT_ID,
    currency: process.env.CURRENCY_CODE,
    components: 'buttons',
    intent: 'authorize',
    commit: false,
  };

  const [isApplePayModalOpen, setIsApplePayModalOpen] = useState(false);
  const [needCheckoutReset, setNeedCheckoutReset] = useState(false);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [isBonusModalOpen, setIsBonusModalOpen] = useState<boolean>(false);
  const { referalToken } = useReferalToken();
  const router = useRouter();
  const { mapCheckoutItems } = useTrackingContext();
  const { customer, isCustomerReady, shippingAddress, isSessionFetched } =
    useAuth();
  const [isCheckoutBeingCreated, setIsCheckoutBeingCreated] = useState(false);
  const lastIncompleteCheckoutId = customerLastIncompleteCheckoutStore.get();
  const [expressCheckoutCustomerId, setExpressCheckoutCustomerId] = useState<
    number | null
  >(null);
  const [couponCode, setCouponCode] = useLocalStorage<string | null>(
    'pd:cart-coupon-code',
    null
  );
  const [shippingMethod, setShippingMethod] =
    useSessionStorage<ShippingMethod | null>('pd:cart-shipping', null);
  const [useCredits, setUseCredits] = useSessionStorage<boolean>(
    'pd:checkout-use-credits',
    true
  );
  const [hasSeenAutoApplyCoupon, setHasSeenAutoApplyCoupon] =
    useSessionStorage<boolean>('pd:has-seen-auto-apply-coupon', false);
  /**
   * Call coupon code tracking API when session is already fetched
   */
  useEffect(() => {
    if (isSessionFetched && couponCode) {
      track({ name: 'Coupon Assignment', code: couponCode });
    }
  }, [couponCode, isSessionFetched]);

  const {
    checkout,
    loading,
    isCartUpdating,
    updateLineItem,
    addItem,
    addItems,
    refetchCheckout,
    resetCheckout,
    newUpdateLineItem,
    newUpdateLineItems,
  } = useCheckout({
    customer,
    id: lastIncompleteCheckoutId,
  });

  const { couponCode: couponCodeViaAudience } = useApplyCoupon();

  useEffect(() => {
    if (!router.isReady) return;

    if (couponCodeViaAudience && !hasSeenAutoApplyCoupon) {
      setCouponCode(couponCodeViaAudience);
      setShowCouponModal(true);
      setHasSeenAutoApplyCoupon(true);
    } else if (router.query && router.query.coupon_code) {
      setCouponCode(
        Array.isArray(router.query.coupon_code)
          ? router.query.coupon_code[0]
          : router.query.coupon_code
      );
      if (router.query.omitModal) return;
      setShowCouponModal(true);
    }
  }, [
    couponCodeViaAudience,
    hasSeenAutoApplyCoupon,
    router.isReady,
    router.query,
    setCouponCode,
    setHasSeenAutoApplyCoupon,
  ]);

  /** Method to add default custom attribute, sort */
  const addDefaultAttributes = useMemo(() => {
    if (checkout.items.length === 0) {
      return addOrderAttributeFactory(0);
    }

    let sortedValues: number[] = [];

    try {
      sortedValues = checkout.items
        .map((item) => item.customAttributes[KnownCustomAttributes.Sort])
        .map((sortValue) => Number(sortValue))
        .sort((prev, current) => {
          if (prev > current) {
            return 1;
          } else {
            return -1;
          }
        });
    } catch (error) {
      console.error('Unrecoverable shopify checkout error', checkout);
      setNeedCheckoutReset(true);
    }

    return addOrderAttributeFactory(
      sortedValues[sortedValues.length - 1] + 1 || 0
    );
  }, [checkout]);

  /**
   * On some cases, user's stored shopify checkout is outdated and incompatible to the current logic.
   * which doesn't have any customAttributes and causes an error above.
   * */
  useEffect(() => {
    if (needCheckoutReset) {
      resetCheckout();
      setNeedCheckoutReset(false);
      showErrorToast({
        error: 'error:products:unrecoverable-shopify-checkout-error',
        caller: 'CartContextProvider',
      });
    }
  }, [resetCheckout, needCheckoutReset]);

  const addProductToCart: CartContextInterface['addProductToCart'] =
    useCallback(
      async (variantId, quantity, customAttributes = []) =>
        addItem(variantId, quantity, addDefaultAttributes(customAttributes)),
      [addItem, addDefaultAttributes]
    );

  const addSubItemToCart: CartContextInterface['addSubItemToCart'] =
    useCallback(
      ({ variantId, quantity, interval }) => {
        return addProductToCart(variantId, quantity, [
          {
            key: 'interval',
            value: interval,
          },
        ]);
      },
      [addProductToCart]
    );

  const addProductsToCart: CartContextInterface['addProductsToCart'] =
    useCallback(
      (items) => {
        return addItems(
          items.map((item) => ({
            ...item,
            customAttributes: addDefaultAttributes(item.customAttributes),
          }))
        );
      },
      [addItems, addDefaultAttributes]
    );

  const addSubItemsToCart: CartContextInterface['addSubItemsToCart'] =
    useCallback(
      (items) => {
        return addProductsToCart(
          items.map((item) => ({
            ...item,
            customAttributes: [
              {
                key: 'interval',
                value: item.interval,
              },
            ],
          }))
        );
      },
      [addProductsToCart]
    );

  /** Function to add redemption item into cart  */
  const addRedemptionGiftToCart: CartContextInterface['addRedemptionGiftToCart'] =
    useCallback(
      async (input: RedemptionLineItem) => {
        await addItem(
          input.variantId,
          1,
          // Add custom attributes so that we can trace back which one is redeemption Item
          addDefaultAttributes([
            {
              key: KnownCustomAttributes.RedeemItem,
              value: 'true',
            },
            {
              key: KnownCustomAttributes.RedemptionPoints,
              value: input.points.toString(),
            },
          ])
        );
      },
      [addItem, addDefaultAttributes]
    );

  /** Format item for update mutation */
  const formatItemForUpdate = useCallback(
    ({
      item,
      interval,
    }: {
      item: PdProductItem;
      interval?: SubscriptionInterval | '';
    }) => {
      const isSubscribeOperation =
        interval !== undefined && !isSubscriptionItem(item);

      const oneTimeOrSubItems = checkout.items.filter((shopifyItem) => {
        const compareType = isSubscribeOperation
          ? ShopifyItemType.OneTime
          : ShopifyItemType.Subscription;
        return getShopifyItemType(shopifyItem) === compareType;
      });

      const targetItems = oneTimeOrSubItems
        .filter((shopifyItem) => shopifyItem.variant.id === item.variantId)
        .filter((shopifyItem) => {
          if (isSubscribeOperation) {
            // if this is subscribe operation this should be already identified
            return true;
          } else if (isSubscriptionItem(item)) {
            return (
              shopifyItem.customAttributes[KnownCustomAttributes.Interval] ===
              item.interval
            );
          }
        });

      if (!targetItems.length) {
        console.log({
          targetItems,
        });
        throw new Error(`can't find item with id : ${item.variantId}`);
      }

      return {
        variantId: item.variantId,
        quantity: item.quantity,
        targetCustomAttributes: [
          ...formatCustomAttributes(targetItems[0]?.customAttributes),
        ],
        updateCustomAttributes: [
          {
            key: KnownCustomAttributes.Interval,
            value: interval || '',
          },
        ],
        itemMergeKey: ItemMergeKeys.Subscription,
      };
    },
    [checkout.items]
  );

  const updateItemSubscriptionInterval: CartContextInterface['updateItemSubscriptionInterval'] =
    useCallback(
      async ({ item, interval }) => {
        await newUpdateLineItem(
          formatItemForUpdate({
            item,
            interval,
          })
        );
      },
      [formatItemForUpdate, newUpdateLineItem]
    );

  const updateItemsSubInterval: CartContextInterface['updateItemsSubInterval'] =
    useCallback(
      async ({ items }) => {
        return await newUpdateLineItems({
          items: items.map(formatItemForUpdate),
        });
      },
      [formatItemForUpdate, newUpdateLineItems]
    );

  const updateItemQuantity: CartContextInterface['updateItemQuantity'] =
    useCallback(
      async ({ item, quantity }) => {
        if (isGiftProductItem(item)) {
          throw new Error('unexpected usage of updateItemQuantity');
        }
        const isSubscription = isSubscriptionItem(item);
        const interval = isSubscription ? item.interval : undefined;

        const oneTimeOrSubItems = checkout.items.filter((shopifyItem) => {
          const type = getShopifyItemType(shopifyItem);
          return (
            type === ShopifyItemType.Subscription ||
            type === ShopifyItemType.OneTime
          );
        });

        const targetItems = oneTimeOrSubItems
          .filter((shopifyItem) => shopifyItem.variant.id === item.variantId)
          .filter((shopifyItem) => {
            return shopifyItem.customAttributes['interval'] === interval;
          });

        if (targetItems.length !== 1) {
          throw new Error(
            `can't find redemptionItem with id : ${item.variantId}`
          );
        }

        await newUpdateLineItem({
          variantId: item.variantId,
          quantity,
          targetCustomAttributes: formatCustomAttributes(
            targetItems[0]?.customAttributes
          ),
          itemMergeKey: isSubscription
            ? ItemMergeKeys.Subscription
            : ItemMergeKeys.None,
        });
      },
      [checkout.items, newUpdateLineItem]
    );

  const updateItems: CartContextInterface['updateItems'] = useCallback(
    async ({ items }) => {
      const newItems = items.map((item) => {
        if (isGiftProductItem(item)) {
          throw new Error('unexpected usage of updateItems');
        }
        const isSubscription = isSubscriptionItem(item);
        const interval = isSubscription ? item.interval : undefined;

        const oneTimeOrSubItems = checkout.items.filter((shopifyItem) => {
          const type = getShopifyItemType(shopifyItem);
          return (
            type === ShopifyItemType.Subscription ||
            type === ShopifyItemType.OneTime
          );
        });

        const targetItems = oneTimeOrSubItems
          .filter((shopifyItem) => shopifyItem.variant.id === item.variantId)
          .filter((shopifyItem) => {
            return shopifyItem.customAttributes['interval'] === interval;
          });

        if (targetItems.length === 0) {
          throw new Error(`Can't find item with id : ${item.variantId}`);
        }

        return {
          variantId: item.variantId,
          quantity: item.quantity,
          targetCustomAttributes: customAttributesToShopifyFormat(
            targetItems[0]?.customAttributes
          ),
          itemMergeKey: isSubscription
            ? ItemMergeKeys.Subscription
            : ItemMergeKeys.None,
        };
      });

      return await newUpdateLineItems({
        items: newItems,
      });
    },
    [checkout.items, newUpdateLineItems]
  );

  /** Function to update the quantity of redemption item in cart  */
  const updateRedemptionGiftQuantity: CartContextInterface['updateRedemptionGiftQuantity'] =
    useCallback(
      async ({ variantId, quantity }) => {
        const subscriptionItems = checkout.items.filter(
          (shopifyItem) =>
            getShopifyItemType(shopifyItem) === ShopifyItemType.RedemptionGift
        );

        const targetItems = subscriptionItems.filter(
          (shopifyItem) => shopifyItem.variant.id === variantId
        );

        if (targetItems.length !== 1) {
          throw new Error(`can't find redemptionItem with id : ${variantId}`);
        }

        await newUpdateLineItem({
          variantId,
          quantity,
          targetCustomAttributes: formatCustomAttributes(
            targetItems[0]?.customAttributes
          ),
          itemMergeKey: ItemMergeKeys.RedemptionGift,
        });
      },
      [checkout.items, newUpdateLineItem]
    );

  /** effectiveCartPayloadItems */
  const effectiveCartPayloadItems = useEffectiveCartPayloadItems({
    items: checkout.items,
  });

  const effectiveCartPayload = useEffectiveCartPayload({
    shippingAddress,
    shippingMethod,
    couponCode,
    useCredits,
    expressCheckoutCustomerId,
    items: effectiveCartPayloadItems,
  });

  const {
    effectiveCart,
    loading: effectiveCartLoading,
    refetch,
    error: cartError,
  } = useEffectiveCart(effectiveCartPayload);

  const effectiveCartItems = useMemo(() => {
    return effectiveCart ? effectiveCart.items : [];
  }, [effectiveCart]);

  const groupedItems = useMemo(() => {
    const subscriptionItems =
      effectiveCart?.subscriptions?.map((subscription) => ({
        ...subscription,
        items: effectiveCart?.items.filter((item) =>
          subscription.items.includes(item.variantId)
        ),
      })) ?? [];
    const oneOffPurchaseItems =
      effectiveCart?.items.filter((item) => !isSubscriptionItem(item)) ?? [];

    return { subscriptionItems, oneOffPurchaseItems };
  }, [effectiveCart]);

  /** When bad request happens, reset shopify checkout to restart the whole cart */
  useEffect(() => {
    if (
      cartError &&
      isAxiosError(cartError) &&
      cartError.response?.status === 400
    ) {
      console.error('cart api error', cartError.response.data.error);
      resetCheckout();
    }
  }, [cartError, resetCheckout]);

  const goToCheckout = useCallback(async () => {
    setIsCheckoutBeingCreated(true);
    try {
      gtm({
        group: Gtm.GroupName.Cart,
        name: Gtm.CartEventName.CheckoutOptionSelect,
        data: {
          type: Gtm.CheckoutType.Standard,
          checkout: {
            items: mapCheckoutItems(effectiveCartItems),
          },
        },
      });

      const customerId = customer ? customer.customerId : undefined;

      const payload = {
        shopifyCheckoutId: checkout.id,
        items: effectiveCartPayloadItems,
        cid: customerId,
        referalToken,
      };

      const petsdeliCheckout = await createPetsdeliCheckout(payload);
      router.push(`/checkout/address?uuid=${petsdeliCheckout.id}`);
    } catch (error) {
      console.error(error, 'goToCheckout');
      showErrorToast({ error: 'error:unknown', caller: 'CartContextProvider' });
    } finally {
      setIsCheckoutBeingCreated(false);
    }
  }, [
    customer,
    effectiveCartItems,
    checkout.id,
    effectiveCartPayloadItems,
    referalToken,
    router,
    mapCheckoutItems,
  ]);

  const cartTotal = get(effectiveCart, 'cartTotal', 0);
  const itemsCount = checkout.items.reduce(
    (count, item) => count + item.quantity,
    0
  );

  const containsPreorderItemsOnly = useMemo(() => {
    return effectiveCartItems.every((item) => item.tags.includes('Preorder'));
  }, [effectiveCartItems]);
  const containsPreorderItem = useMemo(() => {
    return effectiveCartItems.some((item) => item.tags.includes('Preorder'));
  }, [effectiveCartItems]);
  const containsSubscriptionItem = useMemo(() => {
    return effectiveCartItems.some((item) => isSubscriptionItem(item));
  }, [effectiveCartItems]);
  const containsRedemptionItem = useMemo(() => {
    return effectiveCartItems.some((item) => isGiftProductItem(item));
  }, [effectiveCartItems]);
  const canUseCoupon = useMemo(
    () => effectiveCart?.discount?.type !== DiscountType.REFERAL,
    [effectiveCart?.discount]
  );

  const oosItems = useMemo(
    () => effectiveCartItems.filter((item) => item.tags.includes('test_oos')),
    [effectiveCartItems]
  );

  const value: CartContextInterface = {
    canUseCoupon,
    cart: checkout,
    cartTotal,
    containsPreorderItem,
    containsPreorderItemsOnly,
    containsSubscriptionItem,
    containsRedemptionItem,
    couponCode,
    discount: effectiveCart && effectiveCart.discount,
    effectiveCart,
    effectiveCartErrors: effectiveCart && effectiveCart.errors,
    effectiveCartPayload,
    effectiveCartPayloadItems,
    isBonusModalOpen,
    isCartEmpty: !loading && checkout.items.length === 0,
    isCartReady:
      isCustomerReady &&
      !loading &&
      !effectiveCartLoading &&
      (!lastIncompleteCheckoutId || Boolean(checkout.id)),
    isCartUpdating,
    isCheckoutBeingCreated,
    items: effectiveCartItems,
    itemsCount,
    loading: loading || effectiveCartLoading,
    oosItems,
    shippingMethod,
    useCredits,
    groupedItems,
    addProductsToCart,
    addProductToCart,
    addRedemptionGiftToCart,
    addSubItemsToCart,
    addSubItemToCart,
    changeProductQuantity: updateLineItem,
    goToCheckout,
    refetchCart: refetchCheckout,
    reFetchEffectiveCart: refetch,
    resetCheckout,
    setCouponCode,
    setExpressCheckoutCustomerId,
    setIsApplePayModalOpen,
    setIsBonusModalOpen,
    setShippingMethod,
    setUseCredits,
    updateItemQuantity,
    updateItems,
    updateItemsSubInterval,
    updateItemSubscriptionInterval,
    updateRedemptionGiftQuantity,
  };

  return (
    <CartContext.Provider value={value}>
      <PayPalScriptProvider options={paypalExpressInitialOptions}>
        {children}
        {couponCode && showCouponModal && (
          <CouponModalPopUp couponCode={couponCode} />
        )}
        <ModalSlideUp
          open={isApplePayModalOpen}
          onClose={() => setIsApplePayModalOpen(false)}
          title={
            <div>
              <p className="mb-1">
                <FormattedMessage id="cart:apple-pay-modal:title" />
              </p>
              <p className="text-sm font-normal">
                <FormattedMessage id="cart:apple-pay-modal:content" />
              </p>
            </div>
          }
        >
          <ApplePayButton className="h-10 max-w-xxs grow" />
        </ModalSlideUp>
      </PayPalScriptProvider>
    </CartContext.Provider>
  );
};

export const useCart = (): CartContextInterface => useContext(CartContext);
