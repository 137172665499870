/**
 * Check if url is absolute url. Useful to determine if it's internal link or not
 */
export const isAbsoluteUrl = (url: string): boolean => {
  try {
    new URL(url); // if `url` is invalid, throw an erorr
    return true;
  } catch {
    return false;
  }
};
