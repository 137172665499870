import axios from 'axios';
import { contentfulClient } from 'connectors/contentful';
import { Entry } from 'contentful';
import {
  GetAbTestByIdQuery,
  GetAbTestByIdQueryVariables,
  GetAllAbTestIdsQuery,
  GetAllRedirectionTestIdsQuery,
  GetAllSpecialTestIdsQuery,
  GetSegmentationContainerIdsQuery,
  GetSegmentationContainerIdsQueryVariables,
  SegmentationDataContainerCollectionQuery,
  SegmentationDataContainerCollectionQueryVariables,
} from 'generated/graphql';
import { ISegmentationDataContainer } from 'global';
import { getAbTestByIdQuery } from 'queries/contentful/queries/getAbTestById';
import { getAllAbTestIdsQuery } from 'queries/contentful/queries/getAllAbTestIds';
import { getAllRedirectionTestIdsQuery } from 'queries/contentful/queries/getAllRedirectionTestIds';
import { getAllSpecialTestIdsQuery } from 'queries/contentful/queries/getAllSpecialTestIds';
import { getSegmentationContainerIdsQuery } from 'queries/contentful/queries/getSegmentationContainerIds';
import { segmentationDataContainerCollectionQuery } from 'queries/contentful/queries/segmentationDataContainerCollection';
import { omitNullableHandler } from 'utils/type-helper';

type ProductHandle = string;
type SystemId = string;
export type getSegmentationContainerIdsPrs = {
  [key in ProductHandle]: ISegmentationDataContainer;
};

/**
 * Get all special test ids.
 */
export const getAllSpecialTestIds = async (): Promise<Array<string>> => {
  const res = await queryGraphql<GetAllSpecialTestIdsQuery, undefined>({
    query: getAllSpecialTestIdsQuery,
  });

  return (
    res.data.experimentConfigCollection?.items
      .map((item) => item?.sys.id)
      .filter(omitNullableHandler) || []
  );
};

/**
 * Get all redirection test ids.
 */
export const getAllRedirectionTestIds = async (): Promise<string[]> => {
  const res = await queryGraphql<GetAllRedirectionTestIdsQuery, undefined>({
    query: getAllRedirectionTestIdsQuery,
  });

  return (
    res.data.experimentConfigCollection?.items
      .map((item) => item?.sys.id)
      .filter(omitNullableHandler) || []
  );
};

/**
 * Fetch all A/B test ids.
 */
export const getAllAbTestIds = async (): Promise<string[]> => {
  const res = await queryGraphql<GetAllAbTestIdsQuery, undefined>({
    query: getAllAbTestIdsQuery,
  });

  return (
    res.data.experimentConfigCollection?.items
      .map((item) => item?.sys.id)
      .filter(omitNullableHandler) || []
  );
};

/**
 * Fetch all A/B test ids.
 */
export const getAbTestById = async ({
  id,
}: {
  id: string;
}): Promise<GetAbTestByIdQuery> => {
  const { data } = await queryGraphql<
    GetAbTestByIdQuery,
    GetAbTestByIdQueryVariables
  >({
    query: getAbTestByIdQuery,
    variables: {
      id,
    },
  });

  return data;
};

export const getSegmentationContainerIds = async (
  input: GetSegmentationContainerIdsQueryVariables
): Promise<getSegmentationContainerIdsPrs> => {
  const res = await queryGraphql<
    GetSegmentationContainerIdsQuery,
    GetSegmentationContainerIdsQueryVariables
  >({
    query: getSegmentationContainerIdsQuery,
    variables: input,
  });

  const rep: {
    [key in ProductHandle]: SystemId | Entry<ISegmentationDataContainer>;
  } = {};

  res.data.productPageCollection?.items
    .filter((item) => !!item?.segmentationData?.sys.id)
    .filter(omitNullableHandler)
    .forEach((item) => {
      if (item.handle && item.segmentationData?.sys.id) {
        rep[item.handle] = item.segmentationData?.sys.id;
      }
    });

  res.data.productPagesCollection?.items
    .filter((item) => !!item?.segmentationData?.sys.id)
    .filter(omitNullableHandler)
    .forEach((item) => {
      if (item.handle && item.segmentationData?.sys.id) {
        rep[item.handle] = item.segmentationData?.sys.id;
      }
    });

  const segmentContainerEntries =
    await contentfulClient.getEntries<ISegmentationDataContainer>({
      content_type: 'segmentationDataContainer',
      'sys.id[in]': Object.values(rep).toString(),
      include: 5,
    });

  Object.entries(rep).forEach((entry) => {
    const segmentContainerEntry = segmentContainerEntries.items.find(
      (item) => item.sys.id === entry[1]
    );
    if (segmentContainerEntry) {
      rep[entry[0]] = segmentContainerEntry;
    }
  });

  return rep as unknown as getSegmentationContainerIdsPrs;
};

export const fetchSegmentationDataContainerCollection = () => {
  return queryGraphql<
    SegmentationDataContainerCollectionQuery,
    SegmentationDataContainerCollectionQueryVariables
  >({
    query: segmentationDataContainerCollectionQuery,
  });
};

const queryGraphql = <Type = unknown, Variables = Record<string, unknown>>({
  query,
  variables,
}: {
  query?: string;
  variables?: Variables;
}): Promise<{ data: Type }> =>
  axios
    .post<{ data: Type }>(
      `https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_SPACE}/environments/${process.env.CONTENTFUL_ENV}`,
      { query, variables },
      {
        headers: {
          Authorization: `Bearer db857604e8d1eac420e74525ec9395d6ecf464bf54a2b10750ceb814aaca0a8d`,
        },
      }
    )
    .then((res) => res.data);
