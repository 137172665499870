import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { gtm, pageView } from 'tracking';

const PageViewTrigger: React.FC = () => {
  const router = useRouter();

  useEffect(() => {
    gtm(pageView());
  }, [router.asPath]);

  return null;
};

export default PageViewTrigger;
