import { AxiosError, AxiosRequestConfig } from 'axios';
import merge from 'lodash/merge';
import { HttpStatus } from '../constants/http-status';
import { showErrorToast } from '../utils/toasts';

export const ERROR_CODES_TO_HANDLE = [
  HttpStatus.BAD_REQUEST,
  HttpStatus.FORBIDDEN,
  HttpStatus.NOT_FOUND,
  HttpStatus.INTERNAL_SERVER_ERROR,
  HttpStatus.BAD_GATEWAY,
  HttpStatus.SERVICE_UNAVAILABLE,
];

export const requestHandlingInterceptor = (): ((
  config: AxiosRequestConfig
) => AxiosRequestConfig) => {
  const commonHeaders: { [headerName: string]: any } = {};

  commonHeaders.shopid = process.env.SHOP_ID;

  return (config: AxiosRequestConfig): AxiosRequestConfig => {
    if (config.withoutAuthentication) {
      return config;
    }

    return merge(config, {
      headers: {
        common: commonHeaders,
      },
      withCredentials: true,
    });
  };
};

export const showApiErrorToast = ({ error }: { error: AxiosError }) => {
  const { status } = error.response || {};
  const { error: backEndError } = error.response!.data;
  const backEndErrorTranslationKey = `error:back-end:${backEndError}`;
  const httpStatusErrorTranslationKey = `error:http-status:${status}`;
  showErrorToast({
    error: backEndErrorTranslationKey,
    fallbackError: httpStatusErrorTranslationKey,
    caller: 'errorsHandlingInterceptor',
    xAmznTraceId: error.response?.headers['x-amzn-trace-id'],
    xAmznRequestId: error.response?.headers['x-amzn-requestid'],
  });
};

/**
 * This error handler is not helping us at all.
 * Since errors should handled in each component to display relevant error message to users.
 * */
export const errorsHandlingInterceptor = (error: AxiosError): void => {
  // To omit error message toast when products json endpoint is used. Should be refactored.
  // TODO : https://petsdeli.atlassian.net/browse/PT-2262
  if (error.config.url && error.config.url.indexOf('/products/') > -1) {
    throw error;
  }

  // Hot fix : just surpress error message. we need to deal with it properly later.
  // TODO : https://petsdeli.atlassian.net/browse/PT-2919
  if (error.config.url && error.config.url.indexOf('/close/') > -1) {
    throw error;
  }

  const { status } = error.response || {};
  const { errorHandling } = error.config;

  const { statusesToOmit = [], omit } = errorHandling || {};
  const shouldOmit = omit ? omit(error) : false;

  // 401 error should be handled respectively based on situation.
  // Not sure if we need this sloppy implementation.
  // if (!shouldOmit && status === HttpStatus.UNAUTHORIZED) {
  //   customerLocalStorageItemsCleanup();
  //   // setTimeout(() => window.location.reload(), 0);
  //   throw error;
  // }

  if (
    !shouldOmit &&
    status &&
    !statusesToOmit.includes(status) &&
    ERROR_CODES_TO_HANDLE.includes(status)
  ) {
    showApiErrorToast({ error });
  }

  throw error;
};
