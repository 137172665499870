import { SegmentationIds } from 'constants/segmentation';

export default {
  [`personalized-ribbon-${SegmentationIds.Intolerance}`]:
    '<b>Bestseller</b>  bei Allergien',
  [`personalized-ribbon-${SegmentationIds.PickyEater}`]:
    '<b>Bestseller</b> für wählerische Esser',
  [`personalized-ribbon-${SegmentationIds.Overweight}`]:
    '<b>Bestseller</b>  bei Übergewicht',
  [`personalized-ribbon-${SegmentationIds.Digestion}`]:
    '<b>Bestseller</b>  bei sensibler Verdauung',
  [`personalized-ribbon-${SegmentationIds.SkinFur}`]:
    '<b>Bestseller</b>  bei Haut- & Fellproblemen',
  [`personalized-ribbon-${SegmentationIds.Disease}`]:
    '<b>Bestseller</b>  bei Hunden mit Gewichtsproblemen',
  [`personalized-ribbon-${SegmentationIds.Welpe}`]:
    '<b>Bestseller</b>  für Welpen ',
  [`personalized-ribbon-${SegmentationIds.Senior}`]:
    '<b>Bestseller</b>  für Senioren ',
  [`personalized-ribbon-${SegmentationIds.Bulldog}`]:
    '<b>Bestseller</b>  für Bulldoggen',
  [`personalized-ribbon-${SegmentationIds.Chihuahua}`]:
    '<b>Bestseller</b>  für Chihuahuas',
  [`personalized-ribbon-${SegmentationIds.Dachshund}`]:
    '<b>Bestseller</b>  für Dackel',
  [`personalized-ribbon-${SegmentationIds.Havanese}`]:
    '<b>Bestseller</b>  für Malteser & Havaneser',
  [`personalized-ribbon-${SegmentationIds.Poodle}`]:
    '<b>Bestseller</b>  für Pudel',
  [`personalized-ribbon-${SegmentationIds.Retriever}`]:
    '<b>Bestseller</b>  für Retriever',
  [`personalized-ribbon-${SegmentationIds.ShepherdDog}`]:
    '<b>Bestseller</b>  für Schäferhunde',
  [`personalized-ribbon-${SegmentationIds.Spitz}`]:
    '<b>Bestseller</b>  für Spitze',
  [`personalized-ribbon-${SegmentationIds.Terrier}`]:
    '<b>Bestseller</b>  für Terrier',
  [`personalized-ribbon-${SegmentationIds.Herdingdog}`]:
    '<b>Bestseller</b>  für Hütehunde',
  [`personalized-ribbon-${SegmentationIds.CatPickyEater}`]:
    '<b>Bestseller</b>  für wählerische Esser',
  [`personalized-ribbon-${SegmentationIds.CatDigestion}`]:
    '<b>Bestseller</b>  bei sensibler Verdauung',
  [`personalized-ribbon-${SegmentationIds.CatOverweight}`]:
    '<b>Bestseller</b>  für Katzen mit Übergewicht',
  [`personalized-ribbon-${SegmentationIds.Sterilized}`]:
    '<b>Bestseller</b>  für sterilisierte Katzen',
  [`personalized-ribbon-${SegmentationIds.Outdoor}`]:
    '<b>Bestseller</b>  für Freigänger',
  [`personalized-ribbon-${SegmentationIds.Kitten}`]:
    '<b>Bestseller</b>  für Kitten (0-1 Jahre)',
  [`personalized-ribbon-${SegmentationIds.CatSenior}`]:
    '<b>Bestseller</b>  für Senioren (10+ Jahren)',
};
