import { create } from 'zustand';

interface CommonStoreInterface {
  clientIpv6: string | undefined;
  isIpv6fetched: boolean;
  setClientIpv6: (clientIpv6: string | undefined) => void;
  setIsIpv6fetched: (isIpv6fetched: boolean) => void;
}

/**
 * Zustand Store for common data
 */
export const useCommonStore = create<CommonStoreInterface>((set) => ({
  clientIpv6: undefined,
  isIpv6fetched: false,
  setClientIpv6: (clientIpv6) => set({ clientIpv6 }),
  setIsIpv6fetched: (isIpv6fetched) => set({ isIpv6fetched }),
}));
