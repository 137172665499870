import { useAuth } from 'contexts/auth';
import { useMemo } from 'react';

type Output = {
  isNewCustomer: boolean;
};

/**
 * Hooks to check if the user is a new customer or not, based on the orders count.
 */
const useFreeShippingInformation = (): Output => {
  const { customer } = useAuth();

  const isNewCustomer = useMemo(() => {
    if (!customer || customer.ordersCount === 0) {
      return true;
    } else {
      return false;
    }
  }, [customer]);

  return { isNewCustomer };
};

export default useFreeShippingInformation;
