export default {
  'corona-faq:header': 'Häufige Fragen',
  'corona-faq-1-title':
    'Ist mein Hundefutter und Katzenfutter weiterhin verfügbar?',
  'corona-faq-1-text':
    'Aktuell können wir dir sagen, dass <strong>alle Produkte in ausreichender Menge</strong> verfügbar sind. Für den Fall, dass wir ein Futter mal nicht vorrätig haben, kümmern wir uns in kürzester Zeit um Nachschub und bieten dir auch eine Alternative für diesen Zeitraum an. Dazu beraten wir dich gerne von montags bis freitags von 9-18 Uhr unter der <a>030-22 05 6 44 05</a> oder mit unserem <Link> Online-Futterberater </Link>.',
  'corona-faq-2-title': 'Welche Lieferzeiten gelten aktuell?',
  'corona-faq-2-text':
    ' <p>Momentan rechnen wir mit keinen Lieferverzögerungen, sodass du deine Bestellung innerhalb von <strong>1-3 Werktagen</strong> erhältst. Der <strong>Expressversand</strong> unserer Frische-Menüs erfolgt in der Regel am nächsten Tag der Bestellung, wenn diese vor 12 Uhr getätigt wurde. Du erhältst nach jeder Bestellung eine separate Versandbestätigung, sobald dein Paket unterwegs ist.</p>',
  'corona-faq-3-title':
    'Kann ich mich durch die Ware mit dem Coronavirus infizieren?',
  'corona-faq-3-text':
    '    <p>Der aktuelle Stand laut <strong>WHO</strong> ist: <strong>Eine Infizierung über die Ware</strong> bzw. die Kontaminierung eines Pakets und dessen Inhalt über eine infizierte Person ist <strong>unwahrscheinlich</strong>. Bisherige Studien sprechen für eine <strong>geringe Lebensdauer</strong> von Coronaviren auf Oberflächen.</p><p>Wir legen in der Produktion, in unserem Lager und im Umgang mit unseren Lieferanten großen Wert auf <strong>hohe Standards und Vorsichtsmaßnahmen</strong>. Diese sichern wir durch Lücken zwischen verschiedenen Schichten, Sicherheitsabstände und <strong>sehr hohe Hygienestandards</strong> wie das Tragen von Masken und regelmäßiges Händewaschen und-desinfizieren.</p><p>Wenn du dir unsicher bist, kannst du die Ware und deine Hände nach dem Auspacken desinfizieren, um dich und andere zu schützen.</p>',
};
