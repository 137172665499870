import { ErrorKey } from 'hooks/checkout/useExpressCheckoutError/type';
import { HttpStatus } from '../../constants/http-status';

export default {
  'error:invalid_login': 'Passwort oder E-Mail sind leider nicht korrekt.',
  'error:email_in_use':
    'Für diese E-Mail existiert bereits ein Account. Nutze den Login, um dich bei deinem Pets Deli Account einzuloggen.',
  'error:account_suspended':
    'Um Zugang zu deinem Kundenkonto zu erhalten, wende dich bitte an unseren Kundenservice: office@petsdeli.de',

  // Password reset
  'error:Could not find customer': 'Kunde konnte nicht gefunden werden',
  'error:Ungültige URL zum Zurücksetzen':
    'Der Link ist abgelaufen. Fordere einen neuen an!',

  'error:graphql:Too many requests. Please try again in a few seconds':
    'Zu viele Anfragen. Versuche ein bisschen später',

  'error:unknown':
    'Leider ist ein Fehler aufgetreten. Bitte versuch es später erneut.',
  'error:unknown_error':
    'Leider ist ein Fehler aufgetreten. Bitte versuch es später erneut.',
  [`error:http-status:${HttpStatus.BAD_REQUEST}`]:
    'Die Anfrage-Nachricht war fehlerhaft aufgebaut',
  [`error:http-status:${HttpStatus.UNAUTHORIZED}`]:
    'Die Anfrage kann nicht ohne gültige Authentifizierung durchgeführt werden',
  [`error:http-status:${HttpStatus.FORBIDDEN}`]:
    'Die Anfrage wurde mangels Berechtigung des Clients nicht durchgeführt',
  [`error:http-status:${HttpStatus.NOT_FOUND}`]:
    'Die angeforderte Ressource wurde nicht gefunden',
  [`error:http-status:${HttpStatus.INTERNAL_SERVER_ERROR}`]:
    'Es ist ein Serverfehler aufgetreten',
  [`error:http-status:${HttpStatus.BAD_GATEWAY}`]:
    'Der Server konnte seine Funktion als Gateway oder Proxy nicht erfüllen',
  [`error:http-status:${HttpStatus.SERVICE_UNAVAILABLE}`]:
    'Der Server steht temporär nicht zur Verfügung',

  [`error:express-checkout:${ErrorKey.Undefined}`]:
    'Die Zahlung konnte nicht abgeschlossen werden. Bitte nutze eine andere Methode.',
  [`error:express-checkout:${ErrorKey.Unknown}`]:
    'Die Zahlung konnte nicht abgeschlossen werden. Bitte nutze eine andere Methode.',
  'error:account:bonus-program:insufficient': 'Punkt ist unzureichend',
  'error:express-checkout:invalid-address':
    'Unter dieser Adresse ist eine Bestellung über petsdeli.de nicht möglich.',
  [`error:express-checkout:${ErrorKey.InstrumentDeclined}`]:
    'Deine Zahlung ist leider fehlgeschlagen. Bitte überprüfe das Kartenlimit oder verfügbaren Betrag auf der Karte.',
  'error:page:page-title:page-not-found': 'Seite nicht gefunden',
  'error:page:page-title:error': 'Fehler',
  'error:page:header': 'Wir können die Seite, die du suchst, nicht finden.',
  'error:page:suggestions:header':
    'Hier sind stattdessen einige hilfreiche Links:',
  'error:page:suggestions:home': 'Home',
  'error:page:suggestions:foodaid': 'Futterberater',
  'error:page:suggestions:account': 'Account',
  'error:page:suggestions:cart': 'Warenkorb',
  'error:page:generic-error-message':
    'Leider ist gerade ein Fehler aufgetreten!',
  'error:invoice-download-error-message':
    'Ihre Rechnung ist noch nicht verfügbar.',

  'error:products:product-not-available':
    'Leider ist das ausgewählte Produkt nicht mehr verfügbar.',
  'error:products:unrecoverable-shopify-checkout-error':
    'Entschuldigung, etwas ist mit deinem Warenkorb schief gelaufen. Wir haben deinen Warenkorb zurückgesetzt.',
};
