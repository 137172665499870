export class Queue<T> extends Array<T> {
  delegate: { resolve: () => void; promise: Promise<void> };
  constructor() {
    super();
    this.delegate = this.createDelegate();
  }
  private createDelegate() {
    let resolve: () => void = () => {};
    const promise = new Promise<void>((r) => {
      resolve = r;
    });
    return { resolve, promise };
  }
  reset() {
    this.delegate = this.createDelegate();
    this.length = 0;
  }
  async run(mapFn: (x: T, i: number, ls: T[]) => Promise<void>) {
    const delegate = this.delegate;
    const events = [...this];
    this.reset();
    return Promise.all(events.map(mapFn))
      .then(() => {})
      .finally(() => delegate.resolve());
  }
  private resolve() {
    this.delegate.resolve();
  }
  promise() {
    return this.delegate.promise;
  }
}
