import IconMagazine from 'assets/icons/magazine.svg';
import IconPhone from 'assets/icons/phone-2.svg';
import cn from 'classnames';
import { FeatureFlag, getFeatureFlag } from 'constants/feature-flag';
import { useSegmentationContext } from 'contexts/segmentation';
import useLockBodyScroll from 'hooks/common/use-lock-body-scroll';
import useNavigationData from 'hooks/navigation/use-navigation-data';
import { NavigationItem } from 'interfaces/navigation-data';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { gtm, selectElement } from 'tracking';
import IconChevronLeft from '../../../../assets/icons/chevron-left-stroke.svg';
import IconMenuClose from '../../../../assets/icons/menu-close.svg';
import IconMenu from '../../../../assets/icons/menu.svg';
import { PetContextValues, usePetContext } from '../../../../contexts/pet';
import NavigationLink from '../navigation-link';
import styles from './mobile.module.css';

const ComponentName = 'Navigation';

interface PropTypes {
  className?: string;
}

/**
 * @TODO : Too many repetitive codes. Should be refactored
 */ const Mobile: React.FC<PropTypes> = ({ className }) => {
  const [visible, setVisibility] = React.useState(false);

  const intl = useIntl();

  const { mobile } = useNavigationData();
  const { current } = usePetContext();
  const { pushSegmentationFactory, getSegmentationFromUrl } =
    useSegmentationContext();

  const pushSegmentation = pushSegmentationFactory({ location: 'navi' });

  const [petType, setPetType] = React.useState<PetContextValues>('dogs');
  const [subNav, setSubNav] = React.useState(null as null | NavigationItem);

  const { asPath } = useRouter();

  const handleSwitch = useCallback(
    (petType: 'dogs' | 'cats'): void => {
      if (
        (asPath.includes(process.env.CATS) && petType === process.env.CATS) ||
        (asPath.includes(process.env.DOGS) && petType === process.env.DOGS)
      ) {
        return;
      } else {
        pushSegmentation({ segmentation: '' });
      }
    },
    [asPath, pushSegmentation]
  );

  useLockBodyScroll(visible);

  useEffect(() => {
    if (current !== false) {
      setPetType(current);
    }
  }, [current]);

  const navigationItem =
    (className: string, petType: string, subNav?: NavigationItem) =>
    (item: NavigationItem, index: number) => {
      const rootLabel = intl.formatMessage({
        id: `header:navigation-${petType}`,
      });

      return (
        <NavigationLink
          key={index}
          item={item}
          className={className}
          onClick={() => {
            if (item.itemType === 'entry' || item.itemType === 'card') {
              setVisibility(false);
            } else {
              setSubNav(item);
            }

            if ('url' in item && item.url) {
              const segmentation = getSegmentationFromUrl({
                url: item.url,
              });

              if (segmentation) {
                pushSegmentation({
                  segmentation: segmentation.id,
                });
              }
            }

            if (item.title) {
              gtm(
                selectElement({
                  element: subNav
                    ? [
                        ComponentName,
                        rootLabel,
                        String(subNav.title),
                        item.title,
                      ]
                    : [ComponentName, rootLabel, item.title],
                })
              );
            }
          }}
        />
      );
    };

  const navigationTree = (pet: 'dogs' | 'cats'): JSX.Element => {
    return (
      <>
        <div className="flex grow flex-col overflow-x-hidden overflow-y-scroll">
          {mobile[pet].map((nav, index) => {
            return (
              <React.Fragment key={index}>
                <div className="-mt-2 h-1 bg-white-broke" />
                <div className="bg-white pb-16 pt-4">
                  {nav.map(navigationItem('font-black', pet))}
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </>
    );
  };

  const navigation = (): JSX.Element => {
    return (
      <>
        <div
          data-test="mobile-navigation"
          className={cn(
            styles.navigation,
            'top-124 fixed bottom-0 left-0 z-mobile-navigation flex h-full w-10/12 flex-col items-stretch justify-between overflow-hidden overflow-x-hidden bg-white lg:hidden'
          )}
        >
          <div
            className="fixed right-0 top-0 p-2 "
            onClick={() => {
              setVisibility(false);
              setSubNav(null);
            }}
          >
            <IconMenuClose className="w-6 text-white" />
          </div>
          <div className="w-full text-center text-sm uppercase tracking-wide text-black ">
            <div className="flex bg-brand-primary">
              <button
                data-test="mobile-nav-dogs"
                className={`h-16 w-1/2 pt-1 font-black capitalize focus:outline-none`}
                onClick={() => {
                  gtm(
                    selectElement({
                      element: [
                        ComponentName,
                        intl.formatMessage({ id: 'header:navigation-dogs' }),
                      ],
                    })
                  );
                  setPetType('dogs');
                  handleSwitch('dogs');
                }}
              >
                <span
                  className={`relative border-white text-base text-white ${
                    petType !== 'dogs' && 'text-gray-125'
                  }`}
                >
                  <FormattedMessage id="header:navigation-dogs" />
                  {petType === 'dogs' && (
                    <div className="absolute left-0 top-full h-1 w-full rounded-lg border border-white bg-white" />
                  )}
                </span>
              </button>
              <button
                data-test="mobile-nav-cats"
                className={`h-16 w-1/2 pt-1 font-black capitalize focus:outline-none`}
                onClick={() => {
                  gtm(
                    selectElement({
                      element: [
                        ComponentName,
                        intl.formatMessage({ id: 'header:navigation-cats' }),
                      ],
                    })
                  );
                  setPetType('cats');
                  handleSwitch('cats');
                }}
              >
                <span
                  className={`relative border-white text-base text-white ${
                    petType !== 'cats' && 'text-gray-125'
                  }`}
                >
                  <FormattedMessage id="header:navigation-cats" />
                  {petType === 'cats' && (
                    <div className="absolute left-0 top-full h-1 w-full rounded-lg border border-white bg-white" />
                  )}
                </span>
              </button>
            </div>
          </div>
          <div className="flex flex-grow overflow-y-auto overflow-x-hidden">
            <div
              className={cn(
                styles.screen,
                'flex h-full transform flex-col bg-white transition-transform duration-300 ease-in-out',
                petType === 'cats' && '-translate-x-full'
              )}
            >
              {navigationTree('dogs')}
            </div>
            <div
              className={cn(
                styles.screen,
                'flex h-full transform flex-col bg-white transition-transform duration-300 ease-in-out',
                petType === 'cats' && '-translate-x-full'
              )}
            >
              {navigationTree('cats')}
            </div>
          </div>

          <div
            className={cn(
              styles.screen,
              'absolute top-0 h-full transform overflow-auto bg-white leading-[1.4rem] transition-transform duration-300 ease-in-out',
              subNav === null && '-translate-x-full'
            )}
          >
            <button
              className="mb-2 flex w-full items-center bg-brand-primary py-5 pl-4 pr-3 font-normal text-white focus:outline-none"
              onClick={() => {
                setSubNav(null);
              }}
            >
              <IconChevronLeft className="mb-1 h-6 w-4" />
              <FormattedMessage id="header:navigation:mobile:back" />
            </button>
            {petType &&
              subNav !== null &&
              (subNav.itemType === 'nested-card' ||
                subNav.itemType === 'nested-entry') && (
                <>
                  {subNav.items && (
                    <div className="py-4">
                      <div className="mb-2 px-4 text-lg font-black text-brand-primary">
                        {subNav.title}
                      </div>
                      {subNav.items.map(navigationItem('px8', petType, subNav))}
                    </div>
                  )}
                </>
              )}
          </div>
          <div className="absolute bottom-0 flex w-full items-center justify-between bg-grays-gray-6 p-4 text-brand-primary">
            {getFeatureFlag(FeatureFlag.magazin) && (
              <Link href="/magazin">
                <a
                  className="mx-2 flex h-8 w-2/3 items-center justify-center rounded-lg border border-brand-primary bg-white px-2 py-1 text-sm font-medium"
                  onClick={() => {
                    selectElement({
                      element: [
                        ComponentName,
                        intl.formatMessage({
                          id: 'header:navigation:mobile:magazine',
                        }),
                      ],
                    });
                    setVisibility(false);
                  }}
                >
                  <IconMagazine className="mr-2 w-5" />
                  <FormattedMessage id="header:navigation:mobile:magazine" />
                </a>
              </Link>
            )}

            <Link href="/pages/unsere-futterberatung-online-und-telefonisch">
              <a
                className={cn(
                  'mx-2 flex h-8 w-2/3 items-center justify-center rounded-lg border border-brand-primary bg-white px-2 py-1 text-sm font-medium',
                  getFeatureFlag(FeatureFlag.magazin) ? 'w-2/3' : 'w-full'
                )}
                onClick={() => {
                  selectElement({
                    element: [
                      ComponentName,
                      intl.formatMessage({
                        id: 'header:navigation:mobile:consulting',
                      }),
                    ],
                  });

                  setVisibility(false);
                }}
              >
                <IconPhone className="mr-2 w-5" />
                <FormattedMessage id="header:navigation:mobile:consulting" />
              </a>
            </Link>
          </div>
        </div>
        <div
          data-test="modal-backdrop"
          className={cn(
            'fixed inset-0 z-mobile-navigation-backdrop bg-background-tile-dark lg:hidden'
          )}
          onClick={() => {
            setVisibility(false);
            setSubNav(null);
          }}
        />
      </>
    );
  };

  return (
    <>
      <button
        aria-label="Navigation auf oder zuklappen"
        className={cn(
          className,
          'absolute flex flex-col items-center justify-center leading-none focus:outline-none lg:hidden'
        )}
        onClick={() => {
          if (!visible) {
            gtm(
              selectElement({
                element: ComponentName,
              })
            );
          }
          setVisibility((visible) => !visible);
        }}
      >
        {visible ? (
          <IconMenuClose className="h-auto w-5 text-brand-primary" />
        ) : (
          <IconMenu className="h-auto w-5 text-brand-primary" />
        )}
      </button>
      {visible && ReactDOM.createPortal(navigation(), document.body)}
    </>
  );
};

export default Mobile;
