import { useProfile } from '@ninetailed/experience.js-next';
import { useMemo } from 'react';

const AudienceIds = {
  /** Ninetailed audience ID for variant A */
  VariantA: '6r0EWaTzGjshPoyGBhohUW',
  /** Ninetailed audience ID for variant B */
  VariantB: '4amomiv6zQ7ccjdcoxGgUl',
} as const;

const CouponMap = {
  [AudienceIds.VariantA]: 'neukundev110',
  [AudienceIds.VariantB]: 'neukundev220',
};

const AudienceIdArray = Object.values(AudienceIds);

type AudienceIds = (typeof AudienceIds)[keyof typeof AudienceIds];

type Output = {
  couponCode: string | undefined;
};

/**
 * Hook to apply coupon code based on Ninetailed audiences.
 * Returns coupon code associated to an audience.
 * @see : https://petsdeli.atlassian.net/browse/PT-4632
 */
const useApplyCoupon = (): Output => {
  const { loading, profile } = useProfile();

  const couponCode = useMemo(() => {
    if (loading) return;

    const audienceSet = new Set(profile?.audiences);
    const audienceId = AudienceIdArray.find((id) => audienceSet.has(id));

    return audienceId ? CouponMap[audienceId as AudienceIds] : undefined;
  }, [loading, profile?.audiences]);

  return {
    couponCode,
  };
};

export default useApplyCoupon;
