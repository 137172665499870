import cn from 'classnames';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React from 'react';
import { useAuth } from '../../contexts/auth';
import Newsletter from './footer-newsletter';
import LinkSection from './link-section';
import SocialMediaSection from './social-media-section';

const ReferFriendSectionAsync = dynamic(
  () => import('./refer-friend-section'),
  { ssr: false }
);

interface IFooter {
  isCheckout: boolean;
  hideFooter?: boolean;
}

const Footer: React.FC<IFooter> = ({ isCheckout, hideFooter }) => {
  const { customer } = useAuth();
  const { referalToken } = useAuth();
  const router = useRouter();
  const isKonto = router.pathname.includes('account');

  const isBlackFriday = router.asPath.includes('pages/black');

  if (hideFooter) return null;

  return (
    <footer data-test="footer">
      {!isCheckout && (
        <>
          {customer?.ordersCount &&
          customer.ordersCount > 0 &&
          referalToken &&
          isKonto ? (
            <ReferFriendSectionAsync />
          ) : null}

          <div
            className={cn(
              'bg-softs-soft-blue pb-6',
              router.pathname !== '/pages/jobs' && 'lg:mt-20 lg:py-10'
            )}
          >
            <div className="container max-w-6xl">
              <div
                className={cn(
                  'flex flex-col lg:flex-row',
                  isBlackFriday && 'justify-center'
                )}
              >
                {!isBlackFriday && <Newsletter />}
                <SocialMediaSection />
              </div>
            </div>
          </div>
        </>
      )}
      <LinkSection isCheckout={isCheckout} />
    </footer>
  );
};

export default Footer;
