import {
  createApplePayMerchatSession,
  createApplePayPayment,
} from 'api/checkout';
import { useAuth } from 'contexts/auth';
import { useCart } from 'contexts/cart';
import { useCheckout } from 'hooks/checkout';
import { EffectiveCart } from 'interfaces/cart';
import { ApplePaymentPaymentRequest } from 'interfaces/checkout';
import { customerLastIncompleteCheckoutStore } from 'local-storage';
import { useRouter } from 'next/router';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { showErrorToast } from 'utils/toasts';

export type ApplePayPaymentDataPayload = {
  checkoutId: string;
  items?: EffectiveCart['items'];
  discount: EffectiveCart['discount'];
  shippingMethod: string;
};

interface Output {
  request: ApplePayJS.ApplePayPaymentRequest | null;
  applePaySession: MutableRefObject<ApplePaySession | null>;
  handleApplePayEvents: (applePaySession: ApplePaySession) => void;
}

const useApplePayButton = (effectiveCart?: EffectiveCart): Output => {
  const { reFetchEffectiveCart, setIsApplePayModalOpen } = useCart();
  const { fetchCustomer } = useAuth();
  const applePaySession = useRef<ApplePaySession | null>(null);
  const [itemsAmount, setItemsAmount] = useState(0);
  const [currentTotalAmount, setCurrentTotalAmount] = useState(0);
  const { push } = useRouter();
  const { formatDate, formatMessage } = useIntl();
  const { customer } = useAuth();
  const lastIncompleteCheckoutId = customerLastIncompleteCheckoutStore.get();
  const { checkout } = useCheckout({
    customer,
    id: lastIncompleteCheckoutId,
  });

  const shippingMethods = useMemo(
    () =>
      effectiveCart?.shippingOptions.methods.map((method) => {
        return {
          label: method.type,
          amount: (method.price / 100).toFixed(2),
          detail: `${formatMessage({
            id: 'express-checkout:summary-form:shipping-and-payment:shipping',
          })}: ${formatDate(method.eta, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}`,
          identifier:
            method.type === 'Digital'
              ? 'digital'
              : method.carrierCode.replace(/\s+/g, '-').toLowerCase(),
        };
      }),
    [effectiveCart, formatDate, formatMessage]
  );

  const payload = useRef<ApplePayPaymentDataPayload>({
    checkoutId: checkout.id,
    items: effectiveCart?.items,
    discount: effectiveCart?.discount,
    shippingMethod: '',
  });

  useEffect(() => {
    if (shippingMethods && effectiveCart) {
      payload.current.shippingMethod = shippingMethods[0].label;
      setItemsAmount(
        (effectiveCart?.itemsTotal - effectiveCart.itemsTotalReduction) / 100
      );
      setCurrentTotalAmount(
        (effectiveCart?.itemsTotal - effectiveCart.itemsTotalReduction) / 100 +
          Number(shippingMethods[0].amount)
      );
    }
    if (checkout.id) {
      payload.current.checkoutId = checkout.id;
    }
    if (effectiveCart?.items) {
      payload.current.items = effectiveCart?.items;
    }
    if (effectiveCart?.discount) {
      payload.current.discount = effectiveCart?.discount;
    }
  }, [checkout.id, effectiveCart, shippingMethods]);

  const request: ApplePayJS.ApplePayPaymentRequest | null = useMemo(() => {
    return {
      countryCode: process.env.SHOP_ID,
      currencyCode: process.env.CURRENCY_CODE,
      supportedNetworks: ['amex', 'maestro', 'masterCard', 'visa', 'vPay'],
      merchantCapabilities: ['supports3DS'],
      shippingType: 'shipping',
      requiredBillingContactFields: ['postalAddress', 'name', 'phone', 'email'],
      requiredShippingContactFields: [
        'postalAddress',
        'name',
        'phone',
        'email',
      ],
      total: {
        label: 'Pets Deli Tonius GmbH',
        amount: currentTotalAmount.toFixed(2),
        type: 'final',
      },
      shippingMethods,
    };
  }, [currentTotalAmount, shippingMethods]);

  const generateApplePayErrors = useCallback(
    (errors: ApplePayError[]): ApplePayError[] => {
      const applePayErrors: ApplePayError[] = [];

      for (const error of errors) {
        const applePayError = new ApplePayError(
          error.code,
          error.contactField,
          formatMessage({
            id: `express-checkout:apple-pay:errors:${error.code}`,
          })
        );
        applePayErrors.push(applePayError);
      }

      return applePayErrors;
    },
    [formatMessage]
  );

  // ...

  const generateApplePayPaymentData = useCallback(
    ({
      payment,
    }: {
      payment: ApplePayJS.ApplePayPayment;
    }): ApplePaymentPaymentRequest => {
      return {
        method: 'applepay',
        checkoutId: payload.current.checkoutId,
        listParams: {
          summaryUrl: `${location.protocol}//${location.host}/checkout/confirm`,
          cancelUrl: `${location.protocol}//${location.host}/cart`,
          returnUrl: `${location.protocol}//${location.host}/checkout/confirm`,
        },
        cart: {
          items: payload.current.items,
          discount: {
            code: payload.current.discount?.code,
            type: payload.current.discount?.type,
          },
        },
        payment: {
          billingContact: payment.billingContact,
          shippingContact: payment.shippingContact,
          token: payment.token,
          shippingMethod: payload.current.shippingMethod,
        },
      };
    },
    [payload]
  );

  const handleValidateMerchant = useCallback(
    async (event: ApplePayJS.ApplePayValidateMerchantEvent): Promise<void> => {
      try {
        const merchantSession = await createApplePayMerchatSession(
          event.validationURL,
          window.location.hostname
        );
        if (applePaySession.current) {
          applePaySession.current.completeMerchantValidation(merchantSession);
        }
      } catch (error) {
        console.error(error);
        showErrorToast({
          error: 'error:unknown',
          caller: 'ApplePayButton - State',
        });
      }
    },
    []
  );

  const handleShippingContactSelected = useCallback(
    (event: ApplePayJS.ApplePayShippingContactSelectedEvent): void => {
      const { shippingContact } = event;
      const update = {
        newTotal: {
          label: 'Pets Deli Tonius GmbH',
          amount: currentTotalAmount.toFixed(2),
          type: 'final' as const,
        },
      };

      if (
        !shippingContact.countryCode ||
        !(process.env.SHOP_ID === 'DE' ? ['DE', 'AT'] : ['CH']).includes(
          shippingContact.countryCode
        )
      ) {
        const errors: ApplePayError[] = [
          {
            code: 'addressUnserviceable',
            contactField: 'countryCode',
            message: formatMessage({
              id: 'express-checkout:apple-pay:errors:addressUnserviceable',
            }),
          },
        ];
        const applePayErrors = generateApplePayErrors(errors);
        update['errors'] = applePayErrors;
      }

      if (applePaySession.current) {
        applePaySession.current.completeShippingContactSelection(update);
      }
    },
    [currentTotalAmount, formatMessage, generateApplePayErrors]
  );

  const handlePaymentAuthorized = useCallback(
    async (event: ApplePayJS.ApplePayPaymentAuthorizedEvent) => {
      const { payment } = event;
      const paymentData = generateApplePayPaymentData({ payment });

      try {
        const { redirectUrl, errors } = await createApplePayPayment({
          payment: paymentData,
        });
        if (errors) {
          if (applePaySession.current) {
            applePaySession.current.abort();
            await fetchCustomer();
            await reFetchEffectiveCart();
            setIsApplePayModalOpen(true);
          }
        }

        const result = {
          status: ApplePaySession.STATUS_SUCCESS,
        };

        if (applePaySession.current) {
          applePaySession.current.completePayment(result);
        }

        if (result.status === ApplePaySession.STATUS_SUCCESS && redirectUrl) {
          push(redirectUrl);
        }
      } catch (error) {
        console.error(error);
        // If the request fails, return an error with a generic message.
        const result = {
          status: ApplePaySession.STATUS_FAILURE,
          errors: generateApplePayErrors([
            {
              code: 'unknown',
              contactField: undefined,
              message: formatMessage({
                id: 'express-checkout:apple-pay:errors:unknown',
              }),
            },
          ]),
        };

        if (applePaySession.current) {
          applePaySession.current.completePayment(result);
        }
      }
    },
    [
      push,
      fetchCustomer,
      formatMessage,
      generateApplePayErrors,
      generateApplePayPaymentData,
      reFetchEffectiveCart,
      setIsApplePayModalOpen,
    ]
  );

  const handleShippingMethodSelected = useCallback(
    (event: ApplePayJS.ApplePayShippingMethodSelectedEvent): void => {
      const { amount, label } = event.shippingMethod;
      const totalAmount = itemsAmount + Number(amount);
      const update = {
        newTotal: {
          label: 'Pets Deli Tonius GmbH',
          amount: totalAmount.toFixed(2),
          type: 'final' as const,
        },
        newLineItems: [
          {
            label: 'Subtotal',
            amount: itemsAmount.toFixed(2),
          },
          {
            label,
            amount: amount,
          },
        ],
      };
      if (request) {
        const selectedShippingMethod = request.shippingMethods?.find(
          (method) => method.label === label
        );

        if (selectedShippingMethod && applePaySession.current) {
          payload.current.shippingMethod = selectedShippingMethod.label;
          setCurrentTotalAmount(totalAmount);
          applePaySession.current.completeShippingMethodSelection(update);
        }
      }
    },
    [applePaySession, itemsAmount, request]
  );

  const handleApplePayEvents = useCallback(
    (applePaySession: ApplePaySession) => {
      applePaySession.onvalidatemerchant = handleValidateMerchant;
      applePaySession.onshippingcontactselected = handleShippingContactSelected;
      applePaySession.onshippingmethodselected = handleShippingMethodSelected;
      applePaySession.onpaymentauthorized = handlePaymentAuthorized;
    },
    [
      handlePaymentAuthorized,
      handleShippingContactSelected,
      handleShippingMethodSelected,
      handleValidateMerchant,
    ]
  );

  return {
    applePaySession,
    request,
    handleApplePayEvents,
  };
};

export default useApplePayButton;
