import axios, { AxiosResponse } from 'axios';
import { LoginResponse } from 'models/auth';
import { encodePassword } from 'utils/auth';
import { axios as axiosPD } from 'utils/axios';

type LoginInput = {
  email: string;
  password: string;
};

/**
 * Authenticate user and sets cookies related to authentication: pd_cat, pd_ca and pd_cid
 * @see https://petsdeli.atlassian.net/wiki/spaces/DOKUMENTAT/pages/1103331353
 * @param LoginInput
 * @returns LoginResponse
 */
export const login = ({
  email,
  password,
}: LoginInput): Promise<AxiosResponse<LoginResponse>> =>
  axios.post<LoginResponse>(
    `${process.env.API_ORIGIN_ACCOUNT}/login`,
    { email, password: encodePassword(password) },
    {
      withCredentials: true,
      headers: {
        shopid: process.env.SHOP_ID,
      },
    }
  );

/**
 * Logout user and remove cookies related to authentication: pd_cat, pd_ca and pd_cid
 */
export const logout = (): Promise<void> =>
  axiosPD.get(`${process.env.API_ORIGIN_ACCOUNT}/logout`);

type AuthBaseResponse =
  | {
      error: null;
      data: {
        customer: {
          id: number;
          email: string;
          ordersCount?: number;
          tags: string;
        };
        referalToken: string;
      };
    }
  | {
      error: { type: string };
      data: null;
    };

type RegisterResponse = AuthBaseResponse;

/**
 * Register User
 */
export const register = ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<RegisterResponse> =>
  axios
    .post<RegisterResponse>(
      `${process.env.API_ORIGIN_ACCOUNT}/customers`,
      { email, password: encodePassword(password) },
      {
        withCredentials: true,
        headers: {
          shopid: process.env.SHOP_ID,
        },
      }
    )
    .then(({ data }) => data);

type GuestAuthResponse = AuthBaseResponse;

/**
 * Register User as guest customer. Tags in response contains "guest_customer"
 */
export function createShopifyGuestCustomer(params: {
  email: string;
  checkoutAsGuest: boolean;
}): Promise<GuestAuthResponse> {
  return axios
    .post<GuestAuthResponse>(
      `${process.env.API_ORIGIN_ACCOUNT}/customers`,
      params,
      {
        withCredentials: true,
        headers: {
          shopid: process.env.SHOP_ID,
        },
      }
    )
    .then(({ data }) => data);
}

/**
 * Authenticate user via Google social login
 * If the account does not exist, a new Shopify account will be created with a password set by the backend and stored in our DB for future logins
 * If the account already exists, the available data will be used
 * @param token {String}- Google or Facebook OAuth token
 * @param source {String}- From which social network the user is logging in
 * @returns LoginResponse
 * The response has the same structure as the normal login
 * Once authenticated, the related cookies are set: pd_cat, pd_ca and pd_cid
 * @see https://petsdeli.atlassian.net/wiki/spaces/DOKUMENTAT/pages/1103331353
 */
export const socialLogin = (
  token: string,
  source: 'google' | 'facebook'
): Promise<LoginResponse> =>
  axios
    .get(`${process.env.API_ORIGIN_ACCOUNT}/social-login/customers`, {
      headers: {
        shopid: process.env.SHOP_ID,
        Authorization: `Bearer ${token}`,
      },
      params: {
        source,
      },
      withCredentials: true,
    })
    .then(({ data }) => data);
