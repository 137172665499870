import React, {
  createContext,
  MutableRefObject,
  useContext,
  useRef,
} from 'react';

interface DomContextType {
  headerCartIconRef: MutableRefObject<any>;
  headerAccountIconRef: MutableRefObject<HTMLAnchorElement | null>;
  modalWindowMountPointRef: MutableRefObject<any>;
  headerNavigationRef: MutableRefObject<HTMLDivElement | null>;
}

const DomContext = createContext<DomContextType>({} as any);

export const DomContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const headerCartIconRef = useRef();
  const headerAccountIconRef = useRef(null);
  const modalWindowMountPointRef = useRef<any>();

  const headerNavigationRef = useRef<HTMLDivElement | null>(null);

  const value = {
    headerCartIconRef,
    modalWindowMountPointRef,
    headerNavigationRef,
    headerAccountIconRef,
  };

  return (
    <DomContext.Provider value={value}>
      <div ref={modalWindowMountPointRef} />
      {children}
    </DomContext.Provider>
  );
};

export const useDom = () => useContext(DomContext);
