import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

/**
 * Check if the current device is mobile or not
 * @TODO : check performance,
 * it's needed to move away from use media hook because it doesn't get trigger unless window size is changed
 * and for initial render it was not useful
 */
const useMobile = (): boolean => {
  const { width } = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);

  // The useEffect hook runs after the component has mounted on the client, so it doesn't affect the server render.
  useEffect(() => setIsMobile(width <= 976), [width]);

  return isMobile;
};

export default useMobile;
