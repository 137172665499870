import {
  CollectionResponse,
  fetchCollectionProducts,
  fetchProductDetails,
} from 'api/product';
import {
  ContentfulClientApi,
  ContentfulCollection,
  Entry,
  EntryCollection,
  createClient,
} from 'contentful';
import { StaticPdp } from 'modules/product-details/types';
import { retrieveShopifyDataMap } from 'utils/contentful';
import { ShopId } from '../constants/shop';
import {
  IProductPageFields,
  IProductPagesFields,
  ISegmentationDataContainer,
} from '../global';

export const contentfulClient = createClient({
  space: 's8qothrtqrf9',
  accessToken:
    'db857604e8d1eac420e74525ec9395d6ecf464bf54a2b10750ceb814aaca0a8d',
  removeUnresolved: true,
  environment: process.env.CONTENTFUL_ENV,
});

export type SegmentationDataResponse = {
  fields: { segmentationData: ISegmentationDataContainer };
};

export const fetchContentfulSegmentationData = async (
  handle
): Promise<ContentfulCollection<SegmentationDataResponse>> => {
  let result = await contentfulClient.getEntries<SegmentationDataResponse>({
    select: 'fields.segmentationData',
    content_type: 'productPage',
    'fields.handle': handle,
    include: 5,
  });

  if (result.items.length === 0) {
    result = await contentfulClient.getEntries<SegmentationDataResponse>({
      select: 'fields.segmentationData',
      content_type: 'productPages',
      'fields.handle': handle,
      include: 5,
    });
  }

  return result as unknown as ContentfulCollection<SegmentationDataResponse>;
};

export const fetchContentfulProductPage = async (
  handle: string
): Promise<Entry<IProductPageFields> | Entry<IProductPagesFields> | null> => {
  const contentful = await fetchContentfulByHandle(handle, {
    content_type: 'productPage',
  });

  if (contentful === null) {
    const contentfulLegacy = await fetchContentfulByHandle(handle);

    if (contentfulLegacy === null) {
      return null;
    }

    return contentfulLegacy;
  } else {
    return contentful;
  }
};

export const defaultQuery = {
  // when published is not defined in the model, we need to specifically override with 'undefined'
  'fields.published': true,
  include: 10,
  locale: process.env.SHOP_ID === ShopId.CH ? 'de-CH' : 'de',
};

export const fetchContentfulEntry = async <T>(
  id: Parameters<ContentfulClientApi['getEntry']>[0],
  query?: Parameters<ContentfulClientApi['getEntry']>[1]
): Promise<Entry<T>> => {
  const entry = await contentfulClient.getEntry<T>(id, {
    ...defaultQuery,
    ...query,
    'fields.published': undefined,
  });

  return entry;
};

export const fetchContentfulEntries = async <T>(
  query?: Parameters<ContentfulClientApi['getEntries']>[0]
): Promise<EntryCollection<T>> => {
  return await contentfulClient.getEntries<T>({
    ...defaultQuery,
    ...query,
  });
};

/**
 * Fetch entry from contentful API. if 'content_type' is not assigned, productPages is assigned.
 * For Type parameter, Fileds type is expected, such as IProductPagesFields.
 * @param handle : handle to identify entry
 * @param query : Object with search parameters.Check urls for more details
 * @returns
 * @see https://contentful.github.io/contentful.js/contentful/9.1.4/index.html
 */
export const fetchContentfulByHandle = async <T = IProductPagesFields>(
  handle: string,
  // I'm hoping that someday they would start typing query...
  query?: Parameters<ContentfulClientApi['getEntries']>[0]
): Promise<Entry<T> | null> => {
  try {
    const entries = await contentfulClient.getEntries<T>({
      // Attention: "productPages" is the legacy page type, "productPage" is the new one that is only for product pages
      content_type: 'productPages',
      'fields.handle': handle,
      ...defaultQuery,
      // we need to assign locale : undefined because contentful entry might not be assigned with a correct locate.
      locale: undefined,
      ...query,
    });

    if (entries.errors && typeof window === 'undefined') {
      // entries.errors are noncritical errors in most cases, such as 'notResolvable' error.
      // For now we just log it to make them visible as alerts only on build phase.
      // Hot fix to deal with
      // console.log(
      //   `Noncritical error while fetching : ${handle}.
      //   Please contact content team to see the problem.`,
      //   entries.errors
      // );
    }

    // When no entries are found, they returns empty array.
    if (entries.items[0]) {
      // Due to the limitations on the contentful getEntries api where response doesn't contain children fields,
      // We need to fetch the specified entry using getEntry with all the children fields.
      const entry = await fetchContentfulEntry<T>(entries.items[0].sys.id);
      return entry;
    }

    return null;
  } catch (error) {
    console.error(`Error while fetching handle : ${handle} `, error);
    return null;
  }
};

export type FetchContentRes = {
  collectionMap: {
    [key: string]: CollectionResponse;
  };
  productDetailMap: {
    [key: string]: StaticPdp;
  };
};

/**
 * Retrieve Shopify data based on specified handles in contentfulData and returns data map.
 * Maps has {[handle] : [data]} structure
 * E.g {
 *  'fur-hunde-welpen-und-junghunde' : CollectionData
 * }
 * @param contentfulData
 * @returns dataMap
 */
export const fetchContentfulShopifyData = async (
  contentfulData: any
): Promise<FetchContentRes> => {
  const handleMap = retrieveShopifyDataMap(contentfulData);

  const collectionHandles = handleMap['collectionHandle'];
  const productHandles = handleMap['productHandle'];

  const collectionData = {};
  const productData = {};

  await Promise.all([
    ...collectionHandles.map(async (handle) => {
      try {
        const cdp = await fetchCollectionProducts(handle);
        collectionData[handle] = cdp;
      } catch (e) {
        console.log(
          'There was an issue loading a collection in a contentful page:',
          handle,
          process.env.SHOP_ID
        );
      }
    }),
    ...productHandles.map(async (handle) => {
      try {
        const productDetail = await fetchProductDetails(handle);
        productData[handle] = productDetail;
      } catch (e) {
        console.log(
          'There was an issue loading a product in a contentful page:',
          handle,
          process.env.SHOP_ID
        );
      }
    }),
  ]);

  return {
    collectionMap: collectionData,
    productDetailMap: productData,
  };
};
