import React from 'react';
import Clone from '../../../utils/clone';

const SmallCaps: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Clone className="text-sm font-medium text-gray-800">{children}</Clone>
  );
};

export default SmallCaps;
