import useExperiment from 'hooks/common/use-experiment';
import { useEffect, useState } from 'react';
import { TestData, assertIsTestData } from './testData';

type Input = {
  baseEntryId: string;
};

/**
 * Hooks for test pt-5830
 * @see : https://petsdeli.atlassian.net/browsse/PT-5830
 */
const useTest5830 = ({
  baseEntryId,
}: Input): {
  experienceId?: string;
  testData?: TestData;
  variant?: number;
} => {
  const { variant, testData, experienceId } = useExperiment({
    baseEntryId,
  });

  const [_testData, setTestData] = useState<undefined | TestData>();

  useEffect(() => {
    if (testData) {
      try {
        assertIsTestData(testData);
        setTestData(testData);
      } catch (error) {
        console.warn('error in testData', error);
      }
    }
  }, [testData]);

  return {
    experienceId,
    testData: _testData,
    variant,
  };
};

export default useTest5830;
