import { Entry } from 'contentful';
import {
  IProductPageFields,
  IProductPagesFields,
  ISectionIngredients,
  ISectionslider,
  ISlide,
  ISlideRedesign,
} from 'global';

const PRODUCT_HANDLE = 'productHandle';
const COLLECTION_HANDLE = 'collectionHandle';
const CONTENTFUL_PATTERN = `(\\b${COLLECTION_HANDLE}\\b|\\b${PRODUCT_HANDLE}\\b)":"[a-zA-Z0-9-]*"`;

const REPLACE_REGEX = new RegExp(
  `(\\b${COLLECTION_HANDLE}\\b|\\b${PRODUCT_HANDLE}\\b)":"`
);

const HANDLES = [PRODUCT_HANDLE, COLLECTION_HANDLE];

/**
 * Retrieve Shopify data handle map from contentfulData.
 * The map contains unique collection handles and product handles
 */
export const retrieveShopifyDataMap = (
  contentfulData: any
): {
  [PRODUCT_HANDLE]: string[];
  [COLLECTION_HANDLE]: string[];
} => {
  // Since we specify g flg, regex is a stateful object and we need to initialize it on every call.
  const regex = new RegExp(CONTENTFUL_PATTERN, 'gim');

  let m: ReturnType<RegExp['exec']>;

  const handleMap = {
    [PRODUCT_HANDLE]: [],
    [COLLECTION_HANDLE]: [],
  };
  const stringified = JSON.stringify(contentfulData);

  while ((m = regex.exec(stringified)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    // The result can be accessed through the `m`-variable.
    // The first index is the full string of characters matched
    const convert = m[0].replace(REPLACE_REGEX, '').replace('"', '');
    // parenthesized substring which is productTile or COLLECTION_HANDLE
    handleMap[m[1]] = [...handleMap[m[1]], convert];
  }

  // return only unique keys
  HANDLES.forEach((handle) => {
    handleMap[handle] = Array.from(new Set(handleMap[handle]));
  });

  return handleMap;
};

/**
 * Assert function for legacy content model :IProductPagesFields, and new content model IProductPageFields
 */
export function isLegacyProductPageModel(
  arg: Entry<IProductPagesFields>
): arg is Entry<IProductPagesFields> {
  return (
    (arg as Entry<IProductPagesFields>).fields.collectionsAdsTiles !==
      undefined ||
    (arg as Entry<IProductPagesFields>).fields.breadcrumbsCollectionTitle !==
      undefined
  );
}

/**
 * Assert function for legacy content model :IProductPagesFields, and new content model IProductPageFields
 */
export function isNewProductPageModel(
  arg: Entry<IProductPageFields>
): arg is Entry<IProductPageFields> {
  return !!arg.fields.youTubeVideoId;
}

export const hasH1TitleInCdp = ({
  contentful,
}: {
  contentful: Entry<IProductPagesFields>;
}): boolean => {
  try {
    return !!contentful.fields.header
      ?.filter((header) => {
        return (
          (header as unknown as ISectionslider).sys.contentType.sys.id ===
          'sectionslider'
        );
      })
      .map((slider) => {
        return (slider as unknown as ISectionslider).fields.slides;
      })
      .flat()
      .filter((slide) => {
        return (
          (slide as unknown as ISectionIngredients | ISlide | ISlideRedesign)
            .sys.contentType.sys.id === 'slide'
        );
      })
      .find((slide) => {
        return typeof (slide as unknown as ISlide).fields.h1Title === 'string';
      });
  } catch (error) {
    return false;
  }
};
