import { Address } from './address';
import { PdProductItem } from './line-item';

export type FinancialStatus = 'paid' | '...';

export const DiscountType = {
  CREDIT: 'Credit',
  COUPON: 'Coupon',
  REFERAL: 'Referal',
} as const;

export type DiscountType = (typeof DiscountType)[keyof typeof DiscountType];
export interface Discount {
  type: DiscountType;
  code?: string;
  fixedValue: number;
  totalDiscount: number;
  definition: {
    minimumBasketValue: number;
    /** Percentage in 0-1, e.g 0.2 -> 20% */
    percentage: number;
    appliesToSubscriptions: true;
    appliesToOneOff: true;
  };
}

export interface OrderShipping {
  trackingNumber?: string;
  method: string;
  price: number;
  currencyCode: string;
}

export interface OrderShortDetails {
  id: number;
  number: string;
  createdAt: number;
  financialStatus: FinancialStatus;
  fulfillmentStatus: any;
  totalPrice: number;
  shippingMethod: string;
}

export interface OrderDetails extends OrderShortDetails {
  items: Array<PdProductItem>;
  totalShippingPrice: number;
  totalPrice: number;
  totalTax: number;
  discount: Discount;
  shippingAddress: Address;
  billingAddress: Address;
  shipping: OrderShipping;
  fulfillmentsUpdatedAt: string;
}
