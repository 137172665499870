export const getAllRedirectionTestIdsQuery = /* GraphQL */ `
  query getAllRedirectionTestIds {
    experimentConfigCollection(
      # fetch redirection test base entry only redirection test is true and redirect url is empty
      where: { redirectionTest: true, redirectUrl_exists: false }
    ) {
      items {
        sys {
          id
        }
      }
    }
  }
`;
