import cn from 'classnames';
import PaymentMethodIcons from 'components/paymen-method-icons';
import useNavigationData from 'hooks/navigation/use-navigation-data';
import Link from 'next/link';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import IconCircleMinus from '../../../assets/icons/circle-minus.svg';
import IconCirclePlus from '../../../assets/icons/circle-plus.svg';
import IconEmail from '../../../assets/icons/email.svg';
import IconPhone from '../../../assets/icons/phone.svg';
import IconWhatsapp from '../../../assets/icons/whatsapp.svg';
import SmallCaps from '../../../components/typography/small-caps';
import DeliveryLogos from './delivery-logos';
import TrustedShops from './trusted-shops';

const LinkSection: React.FC<{ isCheckout: boolean }> = ({ isCheckout }) => {
  const [activeSection, setSection] = React.useState<null | string>(null);
  const { footer } = useNavigationData();

  // Split the usefulInformation section in half to display in two columns
  const splitLinks = Math.floor(footer.usefulInformation.length / 2);
  const linksFirstHalf = footer.usefulInformation.slice(0, splitLinks);
  const linksSecondHalf = footer.usefulInformation.slice(
    splitLinks,
    footer.usefulInformation.length
  );

  const footerLink = ({
    title,
    url,
    as,
    target,
    attrs = {},
  }: {
    title: string;
    url: string;
    as?: string;
    target?: string;
    attrs?: { [key: string]: string };
  }): React.ReactNode => {
    if (target) {
      return (
        <a
          href={url}
          key={as || url}
          target={target}
          {...attrs}
          className="block text-sm text-brand-copy lg:text-base lg:hover:text-gray-900"
        >
          {title}
        </a>
      );
    }
    return (
      <Link href={url} as={as} passHref key={as || url}>
        <a className="block text-sm text-brand-copy lg:text-base lg:hover:text-gray-900">
          {title}
        </a>
      </Link>
    );
  };

  const linkToggle = (
    translationId: string,
    navigationId: string
  ): React.ReactNode => {
    return (
      <button
        onClick={() => {
          setSection(navigationId === activeSection ? null : navigationId);
        }}
        className="mt-2 flex w-full justify-between bg-gray-100 px-6 pb-2 pt-3 text-left focus:outline-none md:pointer-events-none md:mt-0 md:w-auto md:bg-transparent md:px-0 md:pb-0 md:pt-0 lg:cursor-default"
      >
        <SmallCaps>
          <div className="text-brand-primary">
            <FormattedMessage id={translationId} />
          </div>
        </SmallCaps>

        {navigationId === activeSection ? (
          <IconCircleMinus className="h-3 w-3 text-gray-400 md:hidden" />
        ) : (
          <IconCirclePlus className="h-3 w-3 text-gray-400 md:hidden" />
        )}
      </button>
    );
  };

  return (
    <>
      {!isCheckout && (
        <div className="container w-screen overflow-hidden md:mb-8 md:pt-10">
          <div className="-mx-4 flex flex-col md:flex-row lg:-mx-8">
            <div className="order-last flex-1 md:order-first md:px-4 lg:px-8">
              {linkToggle('footer:title-valuable', 'valuable')}

              <div
                className={cn(
                  activeSection !== 'valuable' && 'hidden md:block',
                  'p-6 md:p-0 md:pt-2 lg:-mx-4 lg:flex'
                )}
              >
                <div className="lg:px-4">
                  {linksFirstHalf.map((link) => footerLink(link))}
                </div>
                <div className="lg:px-4">
                  {linksSecondHalf.map((link) => footerLink(link))}
                </div>
              </div>
            </div>

            <div className="order-first -mx-4 flex flex-col md:order-last lg:-mx-8 xl:flex-row">
              <div className="border-gray-150 order-last border-l px-4 md:order-first lg:px-8">
                {linkToggle('footer:title-help', 'help')}

                <div
                  className={cn(
                    activeSection !== 'help' && 'hidden md:block',
                    'flex flex-col flex-wrap p-6 md:flex-row md:p-0 md:pt-2 xl:block'
                  )}
                >
                  {footer.help.map((link) => (
                    <div
                      key={link.as || link.url}
                      className="md:w-1/2 xl:w-auto"
                    >
                      {footerLink(link)}
                    </div>
                  ))}
                </div>
              </div>

              <div className="border-gray-150 order-first border-l px-8 pb-4 pt-6 md:order-last md:max-w-sm md:px-4 md:pb-0 lg:max-w-md lg:px-8 xl:max-w-sm xl:pt-0">
                <div className="text-center font-black leading-tight text-brand-primary md:text-left">
                  <FormattedMessage id="footer:help-title" />
                </div>
                <div className="text-center text-brand-copy md:text-left">
                  <FormattedMessage
                    id="footer:help-subtitle"
                    values={{ strong: (chunks) => <strong>{chunks}</strong> }}
                  />
                </div>

                <div className="flex flex-col items-center pt-2 text-brand-copy md:block">
                  <div className="flex">
                    <IconPhone className="mr-4 mt-1 h-4 w-4" />
                    <div>
                      <a
                        href={`tel:${process.env.CUSTOMER_SUPPORT_PHONE_NUMBER}`}
                      >
                        <FormattedMessage id="footer:link-section:customer-support-phone-number" />
                      </a>
                      <br />
                      <small className="-mt-1 block text-xs text-gray-300">
                        <FormattedMessage id="footer:link-section:opening-hours" />
                      </small>
                    </div>
                  </div>
                  <div className="mb-3 flex items-center">
                    <IconEmail className="mr-4 h-4 w-4" />
                    <div className="flex flex-col">
                      <a
                        href={process.env.HELP_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FormattedMessage id="footer:link-section:link-help" />
                      </a>
                    </div>
                  </div>
                  <div className="flex">
                    <IconWhatsapp className="mr-4 mt-1 h-4 w-4" />
                    <div>
                      <a
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        href={`https://wa.me/${process.env.WHATSAPP_NUMBER}`}
                      >
                        <FormattedMessage id="footer:link-section:whatsapp-number" />
                      </a>
                      <br />
                      <small className="-mt-1 block text-xs text-gray-300">
                        <FormattedMessage id="footer:link-section:opening-hours" />
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="pb-32 md:bg-gray-100 lg:py-6">
        <div className="container">
          <div className="-mx-4 flex flex-col md:flex-row lg:-mx-8">
            <DeliveryLogos />

            <div className="hidden flex-col justify-center px-4 pt-6 text-center md:justify-start md:pt-0 md:text-left lg:block lg:px-8">
              <SmallCaps>
                <div className="text-brand-primary">
                  <FormattedMessage id="footer:title-payment" />
                </div>
              </SmallCaps>

              <PaymentMethodIcons className="mt-2" />
            </div>

            {!isCheckout && <TrustedShops />}

            {!isCheckout && (
              <div className="order-first md:order-last md:ml-auto md:px-4 lg:px-8">
                {linkToggle('footer:title-legal', 'legal')}

                <div
                  className={cn(
                    activeSection !== 'legal' && 'hidden md:flex',
                    'flex flex-col flex-wrap p-6 md:flex-row md:p-0 md:pt-2'
                  )}
                >
                  {footer.legal.map((link) => (
                    <div className="pr-2" key={link.as || link.url}>
                      {footerLink(link)}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkSection;
