export default {
  'questionnaire:check-order': `Bestellung überprüfen`,
  'questionnaire:go-to-overview': `Zur Bestellübersicht`,
  'questionnaire:next': `Weiter`,

  'questionnaire:header:in-process-message': `Deine Bestellung wird bearbeitet.`,
  'questionnaire:header:in-processed-message': `Deine bestellung ist fertig bearbeitet.`,
  'questionnaire:header:error-message': `Es gab ein problem bei deiner bestellung!`,

  'questionnaire:introduction:opening-message': `Wir möchten dich und deine Fellnase gerne etwas besser kennenlernen.
  Weil du Familie bist! Daher würden wir uns riesig darüber freuen,
  wenn du dir kurz die Zeit nimmst, uns zwei Fragen zu beantworten.
  Vielen Dank und viel Freude mit deiner Bestellung, dein Pets Deli
  Team`,
  'questionnaire:introduction:closing-message': `Vielen Dank für Dein Feedback. Wir wünschen Dir und Deiner
  Fellnase viel Freude mit unseren Produkten.`,
  'questionnaire:introduction:no': 'Nein Danke',
  'questionnaire:introduction:yes': 'Gerne',

  'questionnaire:breed:loading': 'Laden',
  'questionnaire:breed:no-options-found': 'Keine Optionen gefunden',

  'questionnaire:input-placeholder': 'Hier eingeben',
};
