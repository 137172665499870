import { CheckoutErrors, GeneralErrors } from 'interfaces/errors';

export default {
  'checkout:addresses:page-title': 'Bestellung bezahlen',
  'checkout:addresses:title': 'Lieferdaten',
  'checkout:addresses:shipping-address': 'Versandadresse',
  'checkout:addresses:deliver-address': 'Lieferadresse',
  'checkout:addresses:billing-address': 'Rechnungsadresse',
  'checkout:addresses:shipping-address:edit': 'Bearbeiten',
  'checkout:addresses:shipping-address:choose-another-address':
    'Wähle eine andere Adresse',
  'checkout:addresses:shipping-address:different-invoice-address':
    'Abweichende Rechnungsadresse hinzufügen',
  'checkout:addresses:shipping-address:different-invoice-address:choose-saved-address':
    'Gespeicherte Adresse wählen',
  'checkout:addresses:picker:choose-another-address': 'Andere Adresse wählen',
  'checkout:addresses:picker:choose-address': 'Adresse wählen',
  'checkout:addresses:picker:add-new-address': 'Neue Adresse hinzufügen',
  'checkout:addresses:picker:to shipping': 'Weiter zum Versand',
  'checkout:addresses:picker:to free shipping':
    'Weiter zum kostenlosen Versand',
  'checkout:addresses:cross-sell:title': "Darf's noch etwas extra sein?",
  'checkout:addresses:cross-sell:cta': 'Mitbestellen',
  'checkout:addresses:cross-sell:item-added': 'Hinzugefügt',
  'checkout:addresses:validation-sheet:wrong-address:title':
    'Ist deine Lieferadresse korrekt?',
  'checkout:addresses:validation-sheet:missing-address:title':
    'Oops, wir konnten deine Adresse nicht finden',
  'checkout:addresses:validation-sheet:wrong-address:subtitle':
    'Wir haben deine Adresseingabe überprüft und eine Alternative gefunden. Bitte wähle die Adresse aus, an die deine Bestellung gesendet werden soll.',
  'checkout:addresses:validation-sheet:missing-address:subtitle':
    'Die angegebene Adresse konnte nicht gefunden werden. Bitte überprüfe deine Eingabe, um sicherzustellen, dass deine Lieferung problemlos ankommt.',
  'checkout:addresses:validation-sheet:current-address':
    'Deine eingegebene Adresse:',
  'checkout:addresses:validation-sheet:alternative-address':
    'Unsere Alternative:',
  'checkout:addresses:validation-sheet:cta-keep': 'Behalten',
  'checkout:addresses:validation-sheet:cta-use-alternative': 'Übernehmen',
  'checkout:addresses:validation-sheet:cta-edit': 'Adresse bearbeiten',
  'checkout:addresses:validation-sheet:cta-continue-with-address':
    'Mit dieser Adresse fortfahren',

  'checkout:auth:continue-as-guest': 'Als Gast fortfahren',
  'checkout:auth:back-to-login': 'Zurück zur Anmeldung',
  'checkout:auth:signup:title': 'Neu bei Pets Deli?',
  'checkout:auth:signup:title-2': 'Willkommen in der Pets Deli Familie!',
  'checkout:auth:signup:sub-title':
    'Erstelle jetzt einen Account, um deinen Einkauf abzuschließen.',
  'checkout:auth:signup:already-have-account': 'Du hast bereits ein Konto?',
  'checkout:auth:signup:button:to-login': 'Zum Login',
  'checkout:auth:signup:button:to-login-2':
    'Hast du bereits ein Konto? <span>Anmelden</span>',
  'checkout:auth:signup:button:to-signup':
    'Noch kein Konto? <span>Jetzt erstellen</span>',
  'checkout:auth:signup:success-message':
    'Dein Account wurde registriert. Willkommen in der Pets Deli Family!',
  'checkout:auth:login:title': 'Willkommen zurück',
  'checkout:auth:login:title-2': 'Willkommen zurück!',
  'checkout:auth:login:sub-title':
    'Melde dich mit deinem Pets Deli Account an, um deine Bestellung zu bezahlen.',
  'checkout:auth:login:forgot-password': 'Kennwort vergessen?',
  'checkout:auth:login:button-login': 'Einloggen',
  'checkout:auth:login:button-login-2': 'Anmelden',
  'checkout:auth:guest:title': 'Als Gast einkaufen',
  'checkout:auth:guest:title-2': 'Weiter als Gast',
  'checkout:auth:button-create-account': 'Account erstellen',
  'checkout:auth:button-go-to-shipping': 'Zur Lieferadresse',
  'checkout:shipping-and-payment:shipping:title': 'Versandart',
  'checkout:shipping-and-payment:payment:title': 'Bezahlmethode',
  'checkout:shipping-and-payment:shipping:transparency-note':
    'Aufgrund von bestehenden Regularien ist es für uns nicht möglich, tiefgekühlte Ware per DHL zu versenden. Bitte wähle eine der nachfolgenden Optionen für deinen Versand.',

  'checkout:summary:title': 'Kauf abschließen',
  'checkout:summary:submit-cta': 'Jetzt kaufen',
  'checkout:summary:invoice-address': 'Rechnungsadresse',
  'checkout:summary:cart-items:title': 'Artikel in dieser Bestellung',
  'checkout:summary:info-modal:price-calculation': 'Bestellübersicht',
  'checkout:summary:info-modal:first-order': 'Erste Bestellung',
  'checkout:summary:info-modal:original-price': 'Originaler Preis inkl. MwSt.',
  'checkout:summary:info-modal:your-price': 'Dein Preis inkl. MwSt.',
  'checkout:summary:cart-items:every-further-delivery':
    'Jede weitere Bestellung',
  'checkout:summary:terms-and-conditions':
    'Mit Deiner Bestellung erklärst Du Dich mit unseren <a>AGB und Widerrufsbelehrung</a>, sowie unseren <ad>Datenschutzbestimmungen</ad> einverstanden.',
  'checkout:summary:terms-and-conditions-checkbox':
    'Ja, ich möchte über Aktionen, Gutscheine und Produktneuheiten informiert werden.<span>   Abmeldung jederzeit möglich</span>',
  'checkout:summary:terms-and-conditions-whatsapp-checkbox':
    'Ich möchte Aktionen und Neuheiten per WhatsApp erhalten.<span>Abmeldung jederzeit möglich</span>',

  'checkout:summary:guest:logout': 'Abmelden',

  'checkout:step:cart': 'Cart',
  'checkout:step:address': 'Adresse',
  'checkout:step:shipping': 'Versand',
  'checkout:step:shipping-selection': 'Versandoptionen',
  'checkout:step:payment': 'Bezahlung',
  'checkout:step:confirm': 'Bestätigung',
  'checkout:step:continue-payment': 'Weiter Zur Bezahlung',
  'checkout:CO2-information-title': 'Wir liefern CO₂-neutral.',
  'checkout:CO2-information-title-modal': 'Wir liefern CO₂ neutral',
  'checkout:CO2-information-text-modal':
    'Wusstest du, dass dein Paket von uns immer zu 100% klimaneutral versendet wird? Egal ob DHL oder DPD, die CO ² Emissionen die durch den Versand deiner Bestellung entstehen, werden berechnet und durch die Investition in zertifizierte Klimaschutzprojekte ausgeglichen. Sprich, das CO² was entsteht wird an anderer Stelle eingespart.',
  'checkout:shipping:error-message': 'Bitte wähle eine Versandmethode aus.',
  'checkout:shipping:error-message:unavailable_postcodes':
    'Aktuell können wir deine Bestellung leider nicht an deine Adresse versenden.',
  'checkout:shipping:error-message:frozen_packstation_error':
    'Aufgrund von bestehenden Regularien ist es für uns nicht möglich, tiefgekühlte Ware per DHL an eine Packstation zu liefern. Bitte ändere deine Adresse im vorherigen Schritt.',
  'checkout:payment:payment': 'Bezahlung',
  'checkout:payment:loading':
    'Bitte warte und aktualisiere oder verlasse die Seite nicht.',
  'checkout:payment:almost there': 'Fast geschafft! Wie möchtest du bezahlen?',
  'checkout:payment:Your payment methods': 'Deine Zahlungsmethoden',
  'checkout:payment:More payment options': 'Weitere Zahlungsmöglichkeiten',
  'checkout:payment:confirm order': 'BESTELLUNG BESTÄTIGEN',

  'checkout:coupon:have-coupon': 'Gutschein hinzufügen',
  'checkout:coupon:have-coupon-test': 'Ich habe einen Gutschein',
  'checkout:coupon:added-successfully': 'Gutschein erfolgreich hinzugefügt',
  'checkout:coupon:remove-coupon': 'Entfernen',

  'checkout:coupon:coupon-code': 'Rabatt-Code',
  'checkout:coupon:coupon-code-placeholder': 'Gutscheincode hier eingeben',
  'checkout:coupon:coupon-code-placeholder-test': 'Gutschein Code eingeben',
  'checkout:coupon:error:code_not_found': 'Dieser Gutschein-Code ist ungültig.',
  'checkout:coupon:error:code_internal_usage_not_found': 'Ungültiger Code',
  'checkout:coupon:error:code_expired': 'ist abgelaufen',
  'checkout:coupon:error:code_exhausted_total': 'nicht mehr einlösbar',
  'checkout:coupon:error:code_exhausted_customer': 'nicht mehr einlösbar',
  'checkout:coupon:error:code_not_applicable_to_customer':
    'Du kannst diesen Code aktuell nicht einlösen. Bitte wende dich an den Kundenservice.',
  'checkout:coupon:error:customer_fidelity_only_new_orders':
    'Dieser Couponcode ist nur für Neukunden gültig.',
  'checkout:coupon:error:customer_fidelity_only_old_customers':
    'Dieser Couponcode ist nur für Bestandskunden gültig.',
  'checkout:coupon:error:basket_value_too_low':
    "Fast geschafft, Dir fehlen noch {amount}, um deinen Gutschein einzulösen. Stöber' einfach noch ein bisschen in unserem Shop.",
  'checkout:coupon:error:No Items Applicable':
    'Gutschein gilt nicht für ausgewählte Produkte oder Abonnements.',
  'checkout:coupon:error:shopid_mismatch': 'Nicht in diesem Land gültig',

  'checkout:payment:error:ABORT:default':
    'Die Zahlung war nicht erfolgreich. Bitte versuche es zu einem späteren Zeitpunkt nochmal oder kontaktiere unseren Kundenservice',
  'checkout:payment:error:TRY_OTHER_NETWORK:default':
    'Die Zahlung war nicht erfolgreich. Bitte nutze eine andere Zahlungsmethode',
  'checkout:payment:error:TRY_OTHER_ACCOUNT:default':
    'Die Zahlung war nicht erfolgreich. Bitte nutze ein anderes Zahlungskonto',
  'checkout:payment:error:TRY_OTHER_ACCOUNT:EXCEEDS_LIMIT':
    'Dieses Zahlungskonto verfügt nicht über ausreichendes Guthaben. Bitte nutze ein anderes Konto',
  'checkout:payment:error:TRY_OTHER_ACCOUNT:EXPIRED_ACCOUNT':
    'Dieses Zahlungskonto ist abgelaufen. Bitte nutze ein anderes Konto',
  'checkout:payment:error:RETRY:default':
    'Es ist ein Fehler aufgetreten. Bitte versuche es erneut',

  'checkout:bonus-program:title': 'Pets Deli Bonusprogramm',
  'checkout:bonus-program:redemption-product-points': '{value} PUNKTE',
  'checkout:bonus-program:confirm-page':
    'Mit dem Kauf hast du <span>{value} Punkte</span> erhalten',
  'checkout:bonus-program:confirm-page-extra-bonus':
    'Mit dem Kauf hast du <span>{value} + {extraBonusPoints} Punkte</span> erhalten',

  'checkout:bonus-program:confirm-page:create-account':
    'Erstelle jetzt einen Account bei Pets Deli, um auf alle Vorteile des Bonusprogramms Zugriff zu haben.',
  'checkout:payment:error:authentication':
    'Es ist ein Fehler aufgetreten! Bitte logge dich erneut ein und starte einen erneuten Bezahlvorgang auf der Warenkorb Seite.',
  'checkout:express:bonus-program:confirm-page:total-points':
    'Deine Gesamtpunktzahl',
  'checkout:express:bonus-program:confirm-page:points-recap': '{value} Punkte',
  'checkout:payment:optile-hints:paypal':
    'Du wirst zum Abschluss des Bestellvorgangs zu PayPal weitergeleitet',
  'checkout:bonus-program:confirm-page-extra-bonus-plus-newcustomer':
    'Mit dem Kauf hast du <span>{value} + {firstCustomerBonus} + {extraBonusPoints} Punkte</span> erhalten',
  'checkout:bonus-program:confirm-page-newcustomer':
    'Mit dem Kauf hast du <span>{value} + {firstCustomerBonus} Punkte</span> erhalten',

  'checkout:modal:abort-checkout:header':
    'Möchtest du deinen Einkauf wirklich abbrechen und zurück zum Warenkorb?',
  'checkout:modal:abort-checkout:button:continue': 'Einkauf fortsetzen',
  'checkout:modal:abort-checkout:button:back-to-cart': 'Zum Warenkorb',

  'checkout:funnel:header': 'Sicher bezahlen',

  'checkout:confirm:header': 'Danke für deine Bestellung',
  'checkout:confirm:sub-header':
    'Wir benachrichtigen dich, sobald sich deine Sendung auf den Weg zu dir macht.',
  'checkout:confirm:button:continue-shopping': 'Weiter stöbern',
  'checkout:confirm:payment-method': 'Zahlungsmethode',
  'checkout:confirm:payment-method:paypal': 'PayPal',
  'checkout:confirm:payment-method:pay_later': 'Klarna Rechnung',
  'checkout:confirm:payment-method:pay_over_time': 'Klarna Ratenkauf',
  'checkout:confirm:payment-method:pay_now': 'Klarna Sofort bezahlen',
  'checkout:confirm:payment-method:VISA': 'Visa',
  'checkout:confirm:payment-method:MASTERCARD': 'Mastercard',
  'checkout:confirm:payment-method:AMERICAN_EXPRESS': 'American Express',
  'checkout:confirm:payment-method:DINERS': 'Diners',
  'checkout:confirm:payment-method:DINERS_CLUB': 'JCB',
  'checkout:confirm:payment-method:DISCOVER': 'Discover',
  'checkout:confirm:payment-method:creditcard': 'Kreditkarte',
  'checkout:confirm:payment-method:twint': 'TWINT',
  [`checkout:general:error:${CheckoutErrors.CHECKOUT_EXPIRED}`]:
    'Deine Sitzung ist abgelaufen. Bitte versuche es erneut, indem du auf "Zur Kasse" klickst.',
  [`checkout:general:error:${CheckoutErrors.CHECKOUT_NOT_FOUND}`]:
    'Etwas ist schiefgelaufen. Bitte versuche es erneut, indem du auf "Zur Kasse" klickst.',
  [`checkout:general:error:${GeneralErrors.INTERNAL_SERVER_ERROR}`]:
    'Etwas ist schiefgelaufen. Bitte versuche es erneut, indem du auf "Zur Kasse" klickst.',
};
