import { SegmentationIds } from 'constants/segmentation';

type ProductId = string;

type Review = {
  name: string;
  text: string;
};

type Attribute = {
  key: string;
  value: number;
};

export type ReviewData = {
  attributes: Attribute[];
  reviews: Review[];
};

type DefaultReviewData = {
  default: ReviewData;
};

type SegmentationReviewData = {
  [key in SegmentationIds]: ReviewData;
};

type ProductData = {
  [key in ProductId]: DefaultReviewData & SegmentationReviewData;
};

export type TestDataConfig5971 = {
  globalReviewCount: ReviewCount;
  productData: ProductData;
};

export type ReviewCount = {
  ratingCount: string;
  reviewsCount: string;
  fiveStarCount: string;
  fourStarCount: string;
  threeStarCount: string;
  twoStarCount: string;
  oneStarCount: string;
};

export type TestDataConfig5970 = {
  [key: ProductId]: ReviewCount;
};

export function assertTestDataConfig(
  val: unknown
): asserts val is TestDataConfig5970 {
  if (typeof val !== 'object' || val === null) {
    throw new Error('val is not an object');
  }

  for (const key in val) {
    if (typeof key !== 'string') {
      throw new Error('Key is not a string');
    }

    const productData = val[key];
    if (typeof productData !== 'object' || productData === null) {
      throw new Error('Product data is not an object');
    }

    if (
      typeof productData.ratingCount !== 'string' ||
      typeof productData.reviewsCount !== 'string'
    ) {
      throw new Error('Rating count or reviews count is not a string');
    }
  }
}

export function assertIsTestDataConfig(
  value: unknown
): asserts value is TestDataConfig5971 {
  if (typeof value !== 'object' || value === null) {
    throw new Error('Value is not an object');
  }

  const config = value as TestDataConfig5971;

  // Check globalReviewCount
  const globalReviewCount = config.globalReviewCount;
  if (typeof globalReviewCount !== 'object' || globalReviewCount === null) {
    throw new Error('globalReviewCount is not an object');
  }

  [
    'ratingCount',
    'reviewsCount',
    'fiveStarCount',
    'fourStarCount',
    'threeStarCount',
    'twoStarCount',
    'oneStarCount',
  ].forEach((key) => {
    if (typeof globalReviewCount[key] !== 'string') {
      throw new Error(`globalReviewCount.${key} is not a string`);
    }
  });

  // Check productData
  const productData = config.productData;
  if (typeof productData !== 'object' || productData === null) {
    throw new Error('productData is not an object');
  }

  for (const productId in productData) {
    // eslint-disable-next-line no-prototype-builtins
    if (!productData.hasOwnProperty(productId)) continue;

    for (const segmentationId in productData[productId]) {
      // eslint-disable-next-line no-prototype-builtins
      if (!productData[productId].hasOwnProperty(segmentationId)) continue;

      const reviewData = productData[productId][segmentationId];

      if (
        !Array.isArray(reviewData.attributes) ||
        !Array.isArray(reviewData.reviews)
      ) {
        throw new Error('Invalid ReviewData structure');
      }

      reviewData.attributes.forEach((attr) => {
        if (typeof attr.key !== 'string' || typeof attr.value !== 'number') {
          throw new Error('Invalid Attribute structure');
        }
      });

      reviewData.reviews.forEach((review) => {
        if (
          typeof review.name !== 'string' ||
          typeof review.text !== 'string'
        ) {
          throw new Error('Invalid Review structure');
        }
      });
    }
  }
}
