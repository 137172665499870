import { useNinetailed } from '@ninetailed/experience.js-next';
import FunnelHeader from 'components/funnel/header';
import { useAuth } from 'contexts/auth';
import { usePetContext } from 'contexts/pet';
import useIsClient from 'hooks/common/use-is-client';
import { useIsExistingCustomer } from 'hooks/common/use-is-existing-customer';
import useMobile from 'hooks/common/use-mobile';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Footer from '../footer';
import Header from '../header';

const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const isMobile = useMobile();
  const { isEc } = useIsExistingCustomer();
  const isHome = router.pathname === '/';
  const isCheckout = router.pathname.indexOf('checkout') > -1;
  const isFunnel = router.pathname.indexOf('futterabo') > -1;
  const isFube = router.pathname.indexOf('futterberater') > -1;
  const isRefundPage = router.pathname.indexOf('payment-refund') > -1;
  const isSpecialOffer = router.pathname.indexOf('special-offer') > -1;

  const isCartMobile = router.pathname.indexOf('/cart') > -1 && isMobile;
  const isCheckoutMobile = router.pathname.indexOf('checkout') > -1 && isMobile;
  const isResetPasswordMobile =
    router.pathname.indexOf('reset-password') > -1 &&
    !!router.query.callbackUrl &&
    isMobile;
  const { isClient } = useIsClient();
  const { identify } = useNinetailed();
  const { customer } = useAuth();
  const { current: petType } = usePetContext();

  const isLandingPage =
    router.asPath.includes('pages/hunde') ||
    router.asPath.includes('pages/katzen');

  const ecExperience = isLandingPage && isEc;

  useEffect(() => {
    petType && identify(customer ? customer.customerId + '' : '', { petType });
  }, [customer, petType, identify]);

  if (router.pathname.indexOf('og-image') > -1) {
    return <>{children}</>;
  }

  return (
    <div className="flex min-h-screen flex-col">
      {isClient &&
        !isCheckout &&
        !isFunnel &&
        !isRefundPage &&
        !isResetPasswordMobile &&
        !isSpecialOffer && <Header disableScrollHide={isHome} />}
      {isResetPasswordMobile && (
        <FunnelHeader
          onLogoClick={() => {
            router.push('/');
          }}
        />
      )}
      {children}
      {isClient && !isFunnel && !isRefundPage && !isFube && !isSpecialOffer && (
        <Footer
          isCheckout={isCheckout}
          hideFooter={
            isCartMobile ||
            isCheckoutMobile ||
            isResetPasswordMobile ||
            ecExperience
          }
        />
      )}
    </div>
  );
};
export default Layout;
