import {
  OrderDetails,
  OrderShipping,
  OrderShortDetails,
} from '../../interfaces/order';
import * as AddressModel from '../address';

const orderShortDetailsFromServerFormat = (order: any): OrderShortDetails => ({
  id: order.id,
  number: order.number,
  createdAt: order.createdAt * 1000,
  financialStatus: order.financialStatus,
  fulfillmentStatus: order.fulfillmentStatus,
  totalPrice: order.totalPrice,
  shippingMethod: order.shippingMethod,
});

export const ordersFromServerFormat = (
  orders: Array<any>
): Array<OrderShortDetails> => orders.map(orderShortDetailsFromServerFormat);

export const shippingFromServerFormat = (shipping: any): OrderShipping => ({
  trackingNumber: shipping.trackingNumber,
  currencyCode: shipping.currencyCode,
  method: shipping.method,
  price: shipping.price,
});

export const orderDetailsFromServerFormat = (
  orderDetails: any
): OrderDetails => ({
  ...orderShortDetailsFromServerFormat(orderDetails),
  shippingAddress: AddressModel.fromServerFormat(orderDetails.shippingAddress),
  billingAddress:
    orderDetails.billingAddress &&
    AddressModel.fromServerFormat(orderDetails.billingAddress),
  items: orderDetails.items,
  totalPrice: orderDetails.totalPrice,
  totalTax: orderDetails.totalTax,
  discount: orderDetails.discount,
  totalShippingPrice: orderDetails.shipping.price,
  shipping: shippingFromServerFormat(orderDetails.shipping),
  fulfillmentsUpdatedAt: orderDetails.fulfillmentsUpdatedAt,
});
