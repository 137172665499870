import * as ProductModel from '../product';

const giftsFromServerFormat = (gifts: any) => {
  if (!gifts) {
    return undefined;
  }

  return {
    ...gifts,
    items: gifts.items?.map((giftItem: any) => ({
      ...giftItem,
      variant: {
        ...giftItem.variant,
        isGift: true,
      },
    })),
  };
};

export const fromServerFormat = (effectiveCart: any) => {
  return {
    ...effectiveCart,
    gifts: giftsFromServerFormat(effectiveCart.gifts),
    items: effectiveCart.items.map(ProductModel.fromPdServerFormat),
  };
};
