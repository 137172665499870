import React, { useEffect } from 'react';
import { useIntersection } from 'react-use';

const TrustedShops: React.FC = () => {
  const element = React.useRef<HTMLDivElement>(null);
  const initiated = React.useRef(false);
  const intersection = useIntersection(element, {});

  useEffect(() => {
    if (intersection?.isIntersecting && initiated.current === false) {
      const addScript = document.createElement('script');
      addScript.setAttribute('async', 'true');
      addScript.setAttribute(
        'src',
        `https://widgets.trustedshops.com/js/XF6F29E67F210CC76FD76E9BD556839EB.js`
      );
      addScript.setAttribute('data-desktop-enable-custom', 'true');
      addScript.setAttribute('data-desktop-y-offset', '0');
      addScript.setAttribute('data-disable-mobile', 'false');
      addScript.setAttribute('data-mobile-enable-custom', 'true');
      addScript.setAttribute('data-disable-trustbadge', 'false');
      addScript.setAttribute('data-desktop-custom-width', '100');
      addScript.setAttribute('data-mobile-custom-width', '100');
      addScript.setAttribute('data-mobile-y-offset', '0');
      element.current?.appendChild(addScript);
      initiated.current = true;
    }
  }, [intersection?.isIntersecting]);

  return (
    <div ref={element}>
      <div
        id="trustbadgeCustomContainer"
        className="mx-auto mt-8 hidden md:mt-0 lg:block"
        style={{ width: '100px', height: '50px' }}
      />
      <div
        id="trustbadgeCustomMobileContainer"
        className="mx-auto mt-8 md:mt-0 lg:hidden"
        style={{ width: '100px', height: '50px' }}
      />
    </div>
  );
};

export default TrustedShops;
