import { useEffectiveCartPayload } from 'hooks/effective-cart/useEffectiveCart';
import { EffectiveCart } from 'interfaces/cart';
import { axios } from 'utils/axios';

type Input = Partial<ReturnType<typeof useEffectiveCartPayload>> & {
  /** if true, request is mede without any personalization headers */
  withoutPersonalization?: boolean;
};

export const fetchEffectiveCart = ({
  items,
  shipping,
  recurring,
  gifts,
  customerId,
  discount,
  withoutPersonalization,
}: Input): Promise<EffectiveCart> =>
  axios
    .post<EffectiveCart>(
      `${process.env.API_ORIGIN_CHECKOUT}/cart/api`,
      {
        effectiveCart: {
          items,
          shipping,
          gifts,
          recurring,
          customerId,
          discount,
        },
      },
      {
        ...(withoutPersonalization
          ? { withoutAudience: true, withoutNinetailed: true }
          : {}),
      }
    )
    .then(({ data }) => data);
