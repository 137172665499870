export default {
  'auth:common:label-email': 'E-Mail',
  'auth:common:label-email:focus': 'Deine E-Mail Adresse',
  'auth:common:label-password': 'Passwort',
  'auth:common:label-new-password': 'Neues Passwort',
  'auth:common:label-repeat-new-password': 'Neues Passwort wiederholen',
  'auth:common:button:to-login': 'Zum Login',

  'auth:activate-account:header': 'Account aktivieren',
  'auth:activate-account:sub-header':
    'Bitte vergib ein Passwort für deinen Account',
  'auth:activate-account:account-activated': 'Dein Account wurde aktiviert.',
  'auth:activate-account:button:activate': 'Account aktivieren',
  'auth:activate-account:error-message':
    'Es ist ein fehler aufgetreten. Probiere es später noch einmal!',

  'auth:login:header': 'Anmelden',
  'auth:login:sheet:header': 'Willkommen bei Pets Deli',
  'auth:login:label:password': 'Kennwort',
  'auth:login:button:login': 'Anmelden',
  'auth:login:button:recover-password': 'Passwort vergessen?',

  'auth:recover-password:header': 'Passwort vergessen',
  'auth:recover-password:sub-header':
    'Wir haben dir eine E-Mail mit einem Link zum Zurücksetzen deines Kennworts gesendet!',
  'auth:recover-password:button:reset-confirm': 'Passwort Zurücksetzen',
  'auth:recover-password:button:back-to-login': ' Zurück zum Login',

  'auth:reset-password:header': 'Neues Passwort vergeben',
  'auth:reset-password:button:change': 'Passwort verändern',
  'auth:reset-password:confirm': 'Dein Passwort wurde erfolgreich geändert.',
  'auth:reset-password:button:continue-purchase': 'Einkauf fortsetzen',
};
