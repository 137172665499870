export default {
  'funnel:cart-box:questions': 'Hast du Fragen zu Deiner Bestellung?',
  'funnel:cart-box:call': 'Ruf uns einfach an!',
  'funnel:cart-box:phone-number': '+49 30-22 05 6 44 05',
  'funnel:cart-box:opening-hours': 'Mo-Fr 9-18 Uhr',
  'funnel:configurator:modal-title':
    'Konfiguriere das Produkt so wie es dir am besten passt',
  'funnel:configurator:most-popular': 'Am beliebtesten',
  'funnel:configurator:add-to-cart': 'Zum Warenkorb hinzufügen',

  'funnel:configurator:confirm-selection': 'Auswahl bestätigen',
  'funnel:configurator:one-off-price': 'Preis für einmalige Lieferung',
  'funnel:configurator:subscription-price': 'Preis für regelmäßige Lieferung',
};
