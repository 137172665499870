export default {
  'footer:social-title': 'Keine News & Aktionen mehr verpassen!',
  'footer:newsletter-title':
    'Melde dich für unseren Newsletter an und sicher dir einen 15% Gutschein.',
  'footer:newsletter-input-placeholder': 'Deine E-Mail-Adresse',
  'footer:newsletter-button-cat': 'Katzenfan',
  'footer:newsletter-button-dog': 'Hundefan',
  'footer:newsletter-unsubscribe-notice':
    'Gutschein nur für deine erste Bestellung gültig. {br} Mit deiner Anmeldung stimmst du unseren <a>Datenschutzbedingungen</a> zu. {br} <strong>Du kannst dich jederzeit wieder abmelden.</strong>',
  'footer:newsletter-main-title': 'Newsletter',
  'footer:newsletter-petsdeli-hashtag': '#petsdelifamily',
  'footer:social-facebook': 'Facebook',
  'footer:social-instagram': 'Instagram',
  'footer:social-ambassador-program': 'Markenbotschafter werden',

  'footer:title-valuable': 'Wissenswertes',
  'footer:title-help': 'Hilfe',
  'footer:title-shipping': 'Zuverlässige Lieferung mit',
  'footer:title-payment': 'Bezahle mit',
  'footer:title-legal': 'Pets Deli',
  'footer:help-title':
    'Brauchst du Hilfe bei der Auswahl des richtigen Produkts für deinen Liebling?',
  'footer:help-subtitle': 'Lass dich <strong>kostenlos</strong> beraten.',
  'footer:newsletter-thank-you':
    'Danke für deine Anmeldung! {br} Wir haben dir eine Bestätigungsmail gesendet.',
  'footer:refer-friend:title':
    '{amount} für dich, {amountFriend} für deine*n Freund*in',
  'footer:refer-friend:subtitle': 'so funktioniert es',

  'footer:refer-friend:steps:copy-link':
    'Teil Pets Deli mit deinen Freund*innen',
  'footer:refer-friend:steps:share-with-friend':
    'Dein*e Freund*in erhält automatisch {amountFriend} Rabatt beim ersten Einkauf',
  'footer:refer-friend:steps:result':
    'Du erhältst dein {amount}-Guthaben, sobald dein*e Freund*in bestellt hat.',

  'footer:link-section:opening-hours': 'Mo-Fr, 9-18 Uhr',
  'footer:link-section:link-help': 'Du benötigst Hilfe?',
  'footer:link-section:customer-support-phone-number': '+49 30-22 05 6 44 05',
  'footer:link-section:whatsapp-number': '+49 30 33083819',
};
