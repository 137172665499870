import React from 'react';
import GlobalPromoBar from './global-promo-bar';

/**
 * Parent component to describe conditions to render which promo bar.
 * This should be the only component to be injected in `<Header />` component
 */
const PromoBar: React.FC = () => {
  return <GlobalPromoBar />;
};

export default PromoBar;
