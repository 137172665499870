import cn from 'classnames';
import ClickImpressionTracker from 'components/click-impression-tracker';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import IconInstagram from '../../..//assets/icons/instagram.svg';
import IconFacebook from '../../../assets/icons/facebook.svg';
import IconPaw from '../../../assets/icons/newsletter-paw.svg';

/**
 * Social media section for the footer
 */
const SocialMediaSection: React.FC = () => {
  const router = useRouter();
  const isBlackFriday = router.asPath.includes('pages/black');

  const socialLinkClassName =
    'text-brand-primary text-center flex flex-col justify-center items-center lg:text-2xl';
  return (
    <>
      <div
        className={cn(
          'bg-softs-soft-blue pb-6 pt-6 lg:w-1/2 lg:pt-4 ',
          !isBlackFriday && 'lg:pl-20'
        )}
      >
        <h3 className="text-center text-2xl text-brand-primary md:text-3xl lg:-mt-16 lg:text-left lg:text-5xl">
          <FormattedMessage id="footer:newsletter-petsdeli-hashtag" />
        </h3>

        <p
          data-test="footer:social-title"
          className="text-center font-medium leading-tight text-brand-copy lg:pb-4 lg:text-left"
        >
          <FormattedMessage id="footer:social-title" />
        </p>

        <div className="flex justify-between pt-6 md:justify-around lg:px-0">
          <a
            href="https://facebook.com/petsdeli"
            className={socialLinkClassName}
            rel="noopener noreferrer"
            title="Pets Deli auf Facebook"
            target="_blank"
          >
            <IconFacebook className="mb-1 h-8 w-8 lg:h-10 lg:w-10" />
            <span className="text-sm lg:text-lg">
              <FormattedMessage id="footer:social-facebook" />
            </span>
          </a>
          <a
            href="https://instagram.com/petsdeli"
            className={socialLinkClassName}
            rel="noopener noreferrer"
            title="Pets Deli auf Instagram"
            target="_blank"
          >
            <IconInstagram className="mb-1 h-8 w-8 lg:h-10 lg:w-10" />
            <span className="text-sm lg:text-lg">
              <FormattedMessage id="footer:social-instagram" />
            </span>
          </a>
          <ClickImpressionTracker
            trackingGroupName="AmassadorProgrammNewsletterSection"
            trackingLabel="AmassadorProgramm-NewsletterSection"
          >
            <a
              href="https://www.petsdeli.de/pages/kooperationsprogramme"
              className={cn(socialLinkClassName, 'pt-3 md:pt-2 lg:pt-3')}
              rel="noopener noreferrer"
              title="Markenbotschafter werden"
              target="_blank"
            >
              <IconPaw className="mb-2 h-8 w-8 md:mb-2 lg:mb-3 lg:h-10 lg:w-10" />
              <span className="w-min text-sm leading-none md:leading-none lg:text-lg lg:leading-5">
                <FormattedMessage id="footer:social-ambassador-program" />
              </span>
            </a>
          </ClickImpressionTracker>
        </div>
      </div>
    </>
  );
};

export default SocialMediaSection;
