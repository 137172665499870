import cn from 'classnames';
import Price from 'components/price';
import useProduct from 'hooks/product/use-product';
import { CollectionProduct } from 'models/collection/collection';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const PriceDisplayType = {
  /** Shows one time purchase price without strike price display */
  ONE_OFF: 'onetime',
  /** Shows subscription price with strike price display */
  SUBSCRIPTION: 'subscription',
  /** Shows subscription price and one time purchase without strike price display */
  BOTH: 'both',
} as const;

export type PriceDisplayType =
  (typeof PriceDisplayType)[keyof typeof PriceDisplayType];

interface Props {
  product: CollectionProduct;
  variantId: number;
  setVariant: (value: React.SetStateAction<number | null>) => void;
  setVariantPrice: (value: React.SetStateAction<number | null>) => void;
  active?: boolean;
  priceDisplayType: PriceDisplayType;
}

const SubscriptionConfiguratorProduct: React.FC<Props> = ({
  product,
  variantId,
  setVariant,
  setVariantPrice,
  active = false,
  priceDisplayType,
}) => {
  const {
    price,
    comparePrice,
    selectedVariant: variant,
  } = useProduct(product, {
    selectedVariantId: variantId,
    isSubscriptionSelected: true,
  });

  const { oneOffPrice } = useProduct(product, {
    selectedVariantId: variantId,
    isSubscriptionSelected: false,
  });

  const productWeight =
    variant &&
    (variant.weightUnit === 'GRAMS' ? variant.weight / 1000 : variant.weight);
  const pricePerKilo = price / (productWeight || 1);

  if (!variant.availableForSale) {
    return <React.Fragment key={variant.id} />;
  }

  return (
    <button
      key={variant.id}
      onClick={() => {
        setVariant(variant.id);
        setVariantPrice(price);
      }}
      className={cn(
        'focus-default mt-2 flex w-full items-center justify-between rounded-lg border px-3 py-1 text-left leading-snug text-brand-primary',
        active
          ? 'border-brand-primary ring-1 ring-brand-primary'
          : 'border-gray-110'
      )}
    >
      <div>
        {variant.title}
        <div className="text-xxs italic">
          <Price value={pricePerKilo} />
          /kg
        </div>
      </div>
      {priceDisplayType === PriceDisplayType.BOTH && (
        <div className="text-right font-medium ">
          <div className="flex items-center justify-end">
            <p className="mr-2 text-xxs text-black">
              <FormattedMessage id="funnel:configurator:one-off-price" />
            </p>
            <Price value={oneOffPrice} />
          </div>
          <div className="flex items-center ">
            <p className="mr-2 text-xxs text-black">
              <FormattedMessage id="funnel:configurator:subscription-price" />
            </p>
            <Price value={price} />
          </div>
        </div>
      )}
      {priceDisplayType === PriceDisplayType.SUBSCRIPTION && (
        <div className="text-right font-medium">
          <div className="text-xxs font-normal text-red-base line-through">
            {comparePrice ? <Price value={comparePrice} /> : null}
          </div>
          <Price value={price} />
        </div>
      )}
      {priceDisplayType === PriceDisplayType.ONE_OFF && (
        <div className="text-right font-medium">
          <Price value={oneOffPrice} />
        </div>
      )}
    </button>
  );
};

export default SubscriptionConfiguratorProduct;
