import { useEffect } from 'react';

/**
 * Interact with Cookiebot event listener.
 * This should be inject in _app.tsx level so that we can capture the events from Cookiebot
 *    const listener = (): void => {
 *    if (window && window['Cookiebot']) {
 *        // Details of the given consent c.f Properties section in  https://www.cookiebot.com/en/developer/
 *      console.log({
 *          'window[Cookiebot]': window['Cookiebot']['consent'],
 *       });
 *     }
 *   };
 * @see _document.tsx for Cookiebot script
 * */
export const useCookieBotConsent = (listener: () => void): void => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Listeners for cookie bot event @see https://www.cookiebot.com/en/developer/
      window.addEventListener('CookiebotOnConsentReady', listener);
      window.addEventListener('CookiebotOnDialogDisplay', listener);
      window.addEventListener('CookiebotOnAccept', listener);
    }

    return () => {
      window.removeEventListener('CookiebotOnConsentReady', listener);
      window.removeEventListener('CookiebotOnDialogDisplay', listener);
      window.removeEventListener('CookiebotOnAccept', listener);
    };
  }, [listener]);
};

/**
 * Hook to add overflow-hidden class to the html element when Cookiebot is active.
 * This is useful to prevent scrolling when Cookiebot is active
 * and it is not applied in the case Cookiebot is not available or blocked.
 * */
export const useLockScrollWhenCookieBotActive = (): void => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window['Cookiebot']) {
      const cookiebotBanner = document.getElementById('cookiebanner');
      const { consented } = window['Cookiebot'];

      if (consented === true || !cookiebotBanner) return;

      if (consented !== undefined) {
        document.documentElement.classList.add('overflow-hidden');

        window['CookiebotCallback_OnAccept'] = () => {
          document.documentElement.classList.remove('overflow-hidden');
        };
      }
    }
    return () => {
      document.documentElement.classList.remove('overflow-hidden');
    };
  }, []);
};
