import axios from 'axios';
import {
  SHOPIFY_ACTIVATE_ACCOUNT,
  SHOPIFY_RECOVER_CUSTOMER,
  SHOPIFY_RESET_CUSTOMER,
} from 'queries/auth';
import {
  CREATE_CHECKOUT,
  FETCH_CHECKOUT,
  UPDATE_CHECKOUT_LINE_ITEMS,
} from 'queries/checkout';
import {
  CheckoutCreateMutation,
  CheckoutCreateMutationVariables,
  CheckoutLineItemsReplaceMutation,
  CheckoutLineItemsReplaceMutationVariables,
  CustomerActivateByUrlMutation,
  CustomerActivateByUrlMutationVariables,
  CustomerRecoverMutation,
  CustomerRecoverMutationVariables,
  CustomerResetByUrlMutation,
  CustomerResetByUrlMutationVariables,
  FetchCheckoutQuery,
  FetchCheckoutQueryVariables,
} from 'shopifyTypes';

/** Fetch a existing checkout data. */
export const fetchShopifyCheckout = async ({
  id,
}: FetchCheckoutQueryVariables): Promise<FetchCheckoutQuery> =>
  queryGraphql<FetchCheckoutQuery, FetchCheckoutQueryVariables>(
    FETCH_CHECKOUT,
    { id }
  ).then((data) => {
    if (data.node?.__typename !== 'Checkout') {
      throw new Error(`Unexpected type : ${data.node?.__typename}`);
    }
    return data;
  });

/** Creates a new checkout. */
export const createShopifyCheckout = async ({
  input,
}: CheckoutCreateMutationVariables): Promise<CheckoutCreateMutation> =>
  queryGraphql<CheckoutCreateMutation, CheckoutCreateMutationVariables>(
    CREATE_CHECKOUT,
    { input }
  );

/** Sets a list of line items to a checkout. */
export const updateShopifyCheckoutItems = async (
  args: CheckoutLineItemsReplaceMutationVariables
): Promise<CheckoutLineItemsReplaceMutation> =>
  queryGraphql<
    CheckoutLineItemsReplaceMutation,
    CheckoutLineItemsReplaceMutationVariables
  >(UPDATE_CHECKOUT_LINE_ITEMS, args);

/** Resets a customer’s password with the reset password URL received from a reset password email.  */
export const resetShopifyCustomer = async (
  args: CustomerResetByUrlMutationVariables
): Promise<CustomerResetByUrlMutation> =>
  queryGraphql<CustomerResetByUrlMutation, CustomerResetByUrlMutationVariables>(
    SHOPIFY_RESET_CUSTOMER,
    args
  );

/** Sends a reset password email to the customer. The reset password email contains a reset password URL and token that you can pass to the customerResetByUrl or customerReset mutation to reset the customer password. */
export const recoverShopifyCustomer = async (
  args: CustomerRecoverMutationVariables
): Promise<CustomerRecoverMutation> =>
  queryGraphql<CustomerRecoverMutation, CustomerRecoverMutationVariables>(
    SHOPIFY_RECOVER_CUSTOMER,
    args
  );
/** Activates a customer with the activation url received from customerCreate. */
export const activateShopifyCustomer = async (
  args: CustomerActivateByUrlMutationVariables
): Promise<CustomerActivateByUrlMutation> =>
  queryGraphql<
    CustomerActivateByUrlMutation,
    CustomerActivateByUrlMutationVariables
  >(SHOPIFY_ACTIVATE_ACCOUNT, args);

const queryGraphql = <Type = any, Variables = Record<string, any>>(
  query: string,
  variables?: Variables
): Promise<Type> =>
  axios
    .post(
      process.env.SHOPIFY_GRAPHQL_URL as string,
      { query, variables },
      {
        headers: {
          'X-Shopify-Storefront-Access-Token':
            process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN,
        },
      }
    )
    /**
     * @TODO : should return data not data.data
     * because some errors are returned on data.errors
     * */
    .then(({ data }) => data?.data);
