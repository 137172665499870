import axios from 'axios';

const endPoint = 'https://api64.ipify.org';

/**
 * Get the public IPv6 or IPv4 address of the client.
 * The IP address is checked against a regular expression to determine if it is an IPv6 address.
 * If the IP address is not an IPv6 address, undefined is returned.
 * @see https://www.ipify.org
 */
export const getIpAddress = async (): Promise<string | undefined> => {
  try {
    const ipAddress: string = await axios
      .get(endPoint)
      .then((response) => response.data);

    // Regular expression to check if the IP address is IPv6
    const ipv6Pattern = /([a-fA-F0-9:]+:+)+[a-fA-F0-9]+/;

    // Return the IP address if it matches the IPv6 pattern, otherwise return undefined
    return ipv6Pattern.test(ipAddress) ? ipAddress : undefined;
  } catch (error) {
    return undefined;
  }
};
