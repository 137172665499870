import { CollectionProductResponse } from 'api/product';
import isString from 'lodash/isString';
import { StaticPDPProduct } from 'modules/product-details/types';

const getResizedImageUrl = (url: string, size: number) => {
  const lastDot = url.lastIndexOf('.');
  const resizedUrl = `${url.substr(0, lastDot)}_${String(size)}x${url.substr(
    lastDot
  )}`;
  return resizedUrl;
};

const sortVariants = (a: any, b: any) => +a.priceV2.amount - +b.priceV2.amount;

type CollectionProductInput = Omit<
  CollectionProductResponse,
  '_updatedAt' | '_updated' | 'sk' | 'pk'
>;

function assertIsStaticPDPProduct(data: unknown): data is StaticPDPProduct {
  if (typeof data !== 'object' || data === null) {
    return false;
  }
  if (
    typeof data['pk'] !== 'undefined' ||
    typeof data['objectID'] !== 'undefined'
  ) {
    return false;
  }
  return true;
}

export type Variant = Omit<
  CollectionProductInput['variants'][number],
  'compareAtPrice'
> & {
  compareAtPrice?: string;
};

enum MetafieldNameSpaces {
  Petsdeli = 'petsdeli',
  Subscription = 'subscription',
  Accentuate = 'accentuate',
  Rating = 'rating',
}

export type Metadata = {
  [x in MetafieldNameSpaces]: {
    [key in string]?: any;
  };
};

type SearchProductInput = Omit<
  CollectionProductInput,
  'variants' | 'metadata' | 'createdAt'
> & {
  variants: Array<Variant>;
  metadata: Metadata;
};

export type CollectionVariant = Omit<
  CollectionProductResponse['variants'][number],
  'compareAtPrice'
> & {
  /**
   * Price to be compared with discount price.
   * decimal is omitted from the value e.g 4999 meaning 49€ 99cent"
   * */
  compareAtPrice?: number;
};

/** Common Product interface */
export class CollectionProduct {
  public id: number;
  public productType: string;
  public productId: string;
  public title: string;
  public tags: string[];
  public handle: string;
  public metadata: Metadata = {
    [MetafieldNameSpaces.Accentuate]: {},
    [MetafieldNameSpaces.Subscription]: {},
    [MetafieldNameSpaces.Rating]: {},
    [MetafieldNameSpaces.Petsdeli]: {},
  };
  public availableForSale: boolean;
  public variants: Array<CollectionVariant>;
  public image: Partial<CollectionProductResponse['image']> = {};

  /** This property is assigned when a variant is assigned as product to promote a specific variant on dashboard */
  public promoteVariantId?: number;

  public createdAt: string;

  public getProductImages = () => {
    if (!this.image.src) {
      return null;
    }
    return {
      src: getResizedImageUrl(this.image.src, 800),
      altText: this.image.alt,
    };
  };

  /**
   * Retrieve weight string from variant title.
   * Variant title are conventionally named in the form of "[weight per one product] / [packing unit]"
   * e.g "200g / Einzeldose" , "Huhn / 12 x 400g"
   */
  public getWeights = (): string[] =>
    this.variants
      .map((variant) => variant.title.split('/')[0].trim())
      .filter(
        (weight: string, index: number) =>
          index ===
          this.variants.findIndex((variant) => variant.title.includes(weight))
      );

  /** Returns the cheapest variant */
  public getCheapestVariant = () => {
    const _variants = [...this.variants];
    return _variants.sort(sortVariants)[0];
  };

  /** Returns the first cheapest subscribable variant (if available) */
  public getFirstCheapestAvailableSubsVariant =
    (): CollectionVariant | null => {
      const _tags = [...this.tags];
      const _variants = [...this.variants];
      const variant = _variants
        .filter((variant) => variant.availableForSale)
        .sort((a, b) => +a.priceV2.amount - +b.priceV2.amount)[0];
      return _tags.includes('SubscriptionEnabled') ? variant : null;
    };

  constructor(
    product: CollectionProductInput | SearchProductInput | StaticPDPProduct
  ) {
    if (assertIsStaticPDPProduct(product)) {
      this.id = product.id;
      this.productType = product.productType;
      this.title = product.title || product.metadata.accentuate.mainTitle;
      this.tags = product.tags;
      this.handle = product.handle;
      this.productId = product.id + '';
      this.metadata = product.metadata as Metadata;
      this.availableForSale = product.variants.some(
        (variant) => variant.availableForSale
      );
      /**
       * Due to the different implementation on BE, compareAtPrice could be number e.g 4999 or string e.g "49.99"
       * Therefore we align it to be number type without decimal.
       */
      this.variants = product.variants.map((variant) => {
        return {
          ...variant,
          image: {
            src: variant.image.originalSrc,
            alt: variant.image.altText || '',
          },
          weightUnit: variant.weightUnit as 'GRAMS',
          compareAtPrice: variant.compareAtPrice ?? undefined,
          // compareAtPrice,
        };
      });
      this.image.src = product.variants[0].image.originalSrc;
      this.image.alt = product.variants[0].image.altText || '';
      this.createdAt = '0';
      this.promoteVariantId = product.id;
    } else {
      this.id = product.masterProductId ? product.masterProductId : product.id;
      this.productType = product.productType;
      this.title = product.title || product.metadata.accentuate?.mainTitle;
      this.tags = product.tags;
      this.handle = product.handle;
      this.productId = product.productId;
      this.metadata = product.metadata;
      this.availableForSale = product.variants.some(
        (variant) => variant.availableForSale
      );
      /**
       * Due to the different implementation on BE, compareAtPrice could be number e.g 4999 or string e.g "49.99"
       * Therefore we align it to be number type without decimal.
       */
      this.variants = product.variants.map(
        (variant: Variant | CollectionProductInput['variants'][number]) => {
          const compareAtPrice = variant.compareAtPrice
            ? isString(variant.compareAtPrice)
              ? +variant.compareAtPrice * 100
              : variant.compareAtPrice
            : undefined;
          return {
            ...variant,
            compareAtPrice,
          };
        }
      );
      this.image.src = product.image.src;
      this.image.alt = product.image.alt;
      this.createdAt = product['createdAt'] ?? '0';
      this.promoteVariantId = product.masterProductId ? product.id : undefined;
    }
  }
}
