import { ReactNodeArray } from 'react';
import { IntlFormatters, IntlShape } from 'react-intl';

/** Resolve a first translation from passed translation key*/
export const resolveTranslation = (
  intl: IntlShape,
  translationKeys: Array<any>,
  values?: Parameters<IntlFormatters['formatMessage']>[1]
): string | undefined | ReactNodeArray => {
  for (const translationKey of translationKeys.filter(
    Boolean
  ) as Array<string>) {
    const translation = intl.formatMessage(
      {
        id: translationKey,
        defaultMessage: 'no-message',
      },
      values as any
    );

    if (translation !== 'no-message') {
      return translation;
    }
  }
};
