import { VariantWithProductFragment } from './variant';

const LineItemFragment = /* GraphQL */ `
  fragment LineItemFragment on CheckoutLineItem {
    id
    title
    variant {
      ...VariantWithProductFragment
    }
    quantity
    customAttributes {
      key
      value
    }
  }
  ${VariantWithProductFragment}
`;

export const CheckoutFragment = /* GraphQL */ `
  fragment CheckoutFragment on Checkout {
    id
    webUrl
    lineItems(first: 250) {
      edges {
        node {
          ...LineItemFragment
        }
      }
    }
  }
  ${LineItemFragment}
`;
