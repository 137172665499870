import { ShopId } from 'constants/shop';
import { useIsExistingCustomer } from 'hooks/common/use-is-existing-customer';
import { INavigationData } from 'interfaces/navigation-data';
import { useMemo } from 'react';
import useNavigationDataChEC from './ch/existing-customer';
import useNavigationDataChNC from './ch/new-customer';
import useNavigationDataDeEC from './de/existing-customer';
import useNavigationDataDeNC from './de/new-customer';

/**
 * This hook returns the navigation data for the current shop based on the type of customer (NC or EC).
 * TO DO: Add other shops.
 */
const useNavigatioNData = (): INavigationData => {
  const { isEc: isExistingCustomer } = useIsExistingCustomer();

  const navigationData = useMemo(() => {
    let _navigationData: () => INavigationData;

    switch (process.env.SHOP_ID) {
      case ShopId.DE:
        _navigationData = isExistingCustomer
          ? useNavigationDataDeEC
          : useNavigationDataDeNC;
        break;
      case ShopId.CH:
        _navigationData = isExistingCustomer
          ? useNavigationDataChEC
          : useNavigationDataChNC;
        break;
      default:
        _navigationData = useNavigationDataDeNC;
    }

    return _navigationData();
  }, [isExistingCustomer]);

  return navigationData;
};

export default useNavigatioNData;
