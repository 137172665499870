import { ShopId } from 'constants/shop';
import { Entry } from 'contentful';
import { IExperimentConfig, IGlobalData, IGlobalDataFields } from 'global';
import reject from 'lodash/reject';
import uniqBy from 'lodash/uniqBy';
import { contentfulClient } from '../connectors/contentful';

const LIMIT = 100;

const contentfulQueryAll = async (query: any) => {
  const firstPage = await contentfulClient.getEntries({
    limit: LIMIT,
    ...query,
  });

  const pagesCount = Math.ceil(firstPage.total / LIMIT);

  const restPages = await Promise.all(
    Array.from({ length: pagesCount - 1 }, (value, index) => {
      return contentfulClient.getEntries({
        ...query,
        limit: LIMIT,
        skip: (index + 1) * LIMIT,
      });
    })
  );

  const entries = [
    ...firstPage.includes.Entry,
    ...restPages.map(({ includes }) => includes.Entry).flat(1),
  ];

  const assets = [
    ...firstPage.includes.Asset,
    ...restPages.map(({ includes }) => includes.Asset).flat(1),
  ];

  return {
    ...firstPage,
    items: [...firstPage.items, ...restPages.map(({ items }) => items).flat(1)],
    includes: {
      Entry: uniqBy(entries, 'sys.id'),
      Asset: uniqBy(assets, 'sys.id'),
    },
  };
};

export const getAllProductHandles = async () => {
  const locale = process.env.SHOP_ID.toLowerCase();
  const { items } = await contentfulQueryAll({
    content_type: 'productPages',
    'fields.handle[match]': 'products/',
    'fields.published': true,
  });
  return items
    .filter(({ sys }) => sys.locale === 'de' || sys.locale === locale)
    .map(({ fields }: any) => fields.handle.replace('products/', ''));
};

export const getAllCollectionHandles = async () => {
  const locale = process.env.SHOP_ID.toLowerCase();
  const { items } = await contentfulQueryAll({
    content_type: 'productPages',
    'fields.handle[match]': 'collections/',
    'fields.published': true,
  });
  return items
    .filter(({ sys }) => sys.locale === 'de' || sys.locale === locale)
    .map(({ fields }: any) => fields.handle.replace('collections/', ''));
};

export const getAllPageHandles = async () => {
  const locale = process.env.SHOP_ID.toLowerCase();
  const { items } = await contentfulQueryAll({
    content_type: 'productPages',
    'fields.handle[match]': 'pages/',
    'fields.published': true,
  });
  return (
    items
      .filter(({ sys }) => sys.locale === 'de' || sys.locale === locale)
      // we need to filter pages that have their own template
      .filter(
        ({ fields }: any) =>
          fields.handle !== 'pages/jobs' &&
          fields.handle !== 'pages/hunde' &&
          fields.handle !== 'pages/katzen' &&
          fields.handle !== 'pages/subscription-confirmation'
      )
      .map(({ fields }: any) => fields.handle.replace('pages/', ''))
  );
};

export const getGlobalContentfulData = async (): Promise<IGlobalData> => {
  return new Promise((resolve) => {
    contentfulClient
      .getEntry<IGlobalDataFields>(process.env.GLOBAL_DATA_ENTRY_ID, {
        content_type: 'globalData',
        include: 10,
        locale: process.env.SHOP_ID === ShopId.CH ? 'de-CH' : 'de',
      })
      .then((entry) => {
        resolve(entry as IGlobalData);
      })
      .catch((error) => reject(error));
  });
};

/**
 * Batch request for A/B test data by IDs
 */
export const getAbTestsByIds = async ({
  baseEntryIds,
}: {
  baseEntryIds: string[];
}): Promise<Entry<IExperimentConfig>[]> => {
  const result = await contentfulClient.getEntries<IExperimentConfig>({
    'sys.id[in]': baseEntryIds.join(','),
    content_type: 'experimentConfig',
    include: 10,
  });

  return result.items;
};
