import laggy from 'api/middleware/laggy';
import { useAuth } from 'contexts/auth';
import { useReferalToken } from 'contexts/referal';
import { getShopifyItemType, ShopifyItemType } from 'hooks/checkout/utils';
import {
  FormattedShopifyItem,
  KnownCustomAttributes,
} from 'interfaces/checkout';
import { useEffect, useMemo } from 'react';
import useSWR from 'swr';
import { fetchEffectiveCart } from '../../api/cart';
import { CountryCode } from '../../constants/country';
import { SubscriptionInterval } from '../../constants/order';
import { Address } from '../../interfaces/address';
import { EffectiveCart } from '../../interfaces/cart';
import { ShippingMethod } from '../../interfaces/shipping';
import * as AddressModel from '../../models/address';

type EffectiveCartDiscountPayload =
  | { type: 'Coupon'; code: string }
  | { type: 'Referal'; code: string }
  | { type: 'Credit' };

interface EffectiveCartShippingInput {
  address?: any;
  country?: CountryCode; // should be removed
  price?: number;
  priceReduced?: number;
  userChoice?: ShippingMethod;
  title?: string;
}

type RedemptionCartItem = {
  quantity: number;
  variantId: string;
  /** Points for redemption gift  */
  points: number;
  /** True if item should be treated as redemption gift */
  redeemItem: true;
};

export interface CreateEffectiveCartPayloadInput {
  items: Array<
    | RedemptionCartItem
    | {
        price?: number;
        variantId: number;
        quantity: number;
        interval?: SubscriptionInterval;
      }
  >;
  useCredits: boolean;
  shippingMethod: ShippingMethod | null;
  shippingAddress: Address | null;
  couponCode?: string | null;
  recurring?: boolean | null;
  expressCheckoutCustomerId?: number | null;
}

/**
 * Format checkout items into effectiveCartPayloadItems for useEffectiveCartPayload hook
 */
export const useEffectiveCartPayloadItems = ({
  items,
}: {
  items: FormattedShopifyItem[];
}): CreateEffectiveCartPayloadInput['items'] => {
  const effectiveCartPayloadItems = useMemo(() => {
    return items.map((item) => {
      if (getShopifyItemType(item) === ShopifyItemType.RedemptionGift) {
        return {
          variantId: item.variant.id,
          points: Number(
            item.customAttributes[KnownCustomAttributes.RedemptionPoints]
          ),
          quantity: item.quantity,
          redeemItem: true,
        };
      }
      return {
        variantId: item.variant.id,
        quantity: item.quantity,
        interval:
          item.customAttributes &&
          (item.customAttributes.interval as SubscriptionInterval),
      };
    });
  }, [items]);

  return effectiveCartPayloadItems;
};

interface useEffectiveCartState {
  effectiveCart: EffectiveCart | undefined;
  loading: boolean;
  refetch: () => Promise<EffectiveCart | undefined>;
  error: any;
}

export interface EffectiveCartPayload {
  items: Array<
    | RedemptionCartItem
    | {
        price?: number;
        variantId: number;
        quantity: number;
        interval?: SubscriptionInterval;
      }
  >;
  gifts?: {
    donated?: boolean;
    selected?: string | null;
  };
  shipping?: EffectiveCartShippingInput | undefined;
  recurring?: boolean | null;
  discount?: EffectiveCartDiscountPayload | undefined;
  customerId?: number | null;
}

export const useEffectiveCartPayload = ({
  items,
  shippingAddress,
  shippingMethod,
  recurring = null,
  couponCode,
  useCredits,
  expressCheckoutCustomerId = null,
}: CreateEffectiveCartPayloadInput): EffectiveCartPayload => {
  const { customer } = useAuth();
  const { referalToken } = useReferalToken();
  const discount: EffectiveCartDiscountPayload | undefined = useMemo(() => {
    if (typeof referalToken === 'string') {
      return { type: 'Referal', code: referalToken };
    }

    if (couponCode) {
      return { type: 'Coupon', code: couponCode };
    }

    if (!customer) {
      return undefined;
    }

    if (useCredits) {
      return { type: 'Credit', customerId: customer.id };
    }

    return undefined;
  }, [referalToken, couponCode, customer, useCredits]);

  const shipping = useMemo(
    () =>
      !shippingAddress && !shippingMethod
        ? undefined
        : {
            address: shippingAddress
              ? AddressModel.toPdServerFormat(shippingAddress)
              : undefined,
            type: shippingMethod ?? undefined,
          },
    [shippingMethod, shippingAddress]
  );

  return useMemo(
    () => ({
      items,
      shipping: shipping as EffectiveCartShippingInput | undefined,
      recurring: recurring as boolean | null,
      discount,
      customerId: customer?.customerId || expressCheckoutCustomerId,
    }),
    [
      customer?.customerId,
      discount,
      shipping,
      expressCheckoutCustomerId,
      items,
      recurring,
    ]
  );
};

/**
 * Hooks for EffectiveCart.
 * Laggy is used to maintain data to show while new response is being fetching
 */
export const useEffectiveCart = (
  payload?: Partial<ReturnType<typeof useEffectiveCartPayload>>
): useEffectiveCartState => {
  const { data, mutate, error, isValidating } = useSWR<
    EffectiveCart | undefined
  >([payload], fetchEffectiveCart, {
    errorRetryCount: 1,
    use: [laggy],
  });

  useEffect(() => {
    if (error) {
      console.error('effective cart error', error);
    }
  }, [error]);

  return {
    effectiveCart: data,
    loading: isValidating,
    refetch: mutate,
    error,
  };
};
