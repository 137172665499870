/** Names for identifying error */
export const CheckoutErrors = {
  CHECKOUT_NOT_FOUND: 'CheckoutNotFound',
  CHECKOUT_EXPIRED: 'CheckoutExpired',
} as const;

export type CheckoutErrorNames =
  (typeof CheckoutErrors)[keyof typeof CheckoutErrors];

export interface CheckoutNotFound extends Error {
  name: 'CheckoutNotFound';
}

export interface CheckoutExpired extends Error {
  name: 'CheckoutExpired';
}

export const GeneralErrors = {
  INTERNAL_SERVER_ERROR: 'Internal Server Error',
};

export type GeneralErrors = (typeof GeneralErrors)[keyof typeof GeneralErrors];

/** General BE error interface  */
export interface GeneralError<
  InfoType = unknown,
  NameType = unknown,
  MessageId = unknown,
> {
  message: MessageId;
  info: InfoType;
  name: NameType;
}

/** Names for identifying subscription error */
export const SubscriptionErrors = {
  UPDATE_ERROR: 'SubscriptionUpdateError',
} as const;

export type SubscriptionErrors =
  (typeof SubscriptionErrors)[keyof typeof SubscriptionErrors];
