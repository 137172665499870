import IconAmex from 'assets/icons/amex.svg';
import IconApplePay from 'assets/icons/apple-pay.svg';
import IconKlarna from 'assets/icons/klarna.svg';
import IconMastercard from 'assets/icons/mastercard.svg';
import IconPaypal from 'assets/icons/paypal.svg';
import IconTwint from 'assets/icons/twint.svg';
import IconVisa from 'assets/icons/visa.svg';
import cn from 'classnames';
import { ShopId } from 'constants/shop';

interface PaymentMethodIconsProps {
  className?: string;
}

const PaymentMethodIcons: React.FC<PaymentMethodIconsProps> = ({
  className,
}) => (
  <div className={cn('flex items-center justify-center gap-2', className)}>
    <IconPaypal className="w-8 lg:w-10" />
    <IconVisa className="w-8 lg:w-10" />
    <IconMastercard className="w-8 lg:w-10" />
    <IconAmex className="w-8 lg:w-10" />
    {window.ApplePaySession && <IconApplePay className="w-8 lg:w-10" />}
    {process.env.SHOP_ID === ShopId.DE && (
      <IconKlarna className="w-8 lg:w-10" />
    )}
    {process.env.SHOP_ID === ShopId.CH && <IconTwint className="w-8 lg:w-10" />}
  </div>
);

export default PaymentMethodIcons;
