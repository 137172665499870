import { Entry } from 'contentful';
import { IProductPageFields, IProductPagesFields } from 'global';
import { ProductType } from '../interfaces/product';

export const isCollectionPage = (
  page: Entry<IProductPageFields | IProductPagesFields>
): boolean => {
  return !!(
    page.fields.handle && page.fields.handle?.indexOf('collections') > -1
  );
};

/**
 * creates collection handles by using product types
 * @param petType
 * @param productType
 * @returns
 */
export const getCollectionHandleByProductDetails = (
  petType: 'dog' | 'cat',
  productType: ProductType
): string => {
  const suffix = petType === 'dog' ? 'fur-hunde-' : 'fur-katzen-';
  switch (productType) {
    case process.env.WET_FOOD:
      return suffix + 'nassfutter';
    case process.env.DRY_FOOD:
      return suffix + 'trockenfutter';
    case process.env.BARF:
      return suffix + 'barf-frische-menus';
    case process.env.SNACKS:
      return suffix + 'snacks';
    case process.env.SUPPLEMENTS:
      return suffix + 'nahrungserganzung';
    case process.env.HEALTH:
      return suffix + 'gesundheit';
    case process.env.ACCESSORIES:
      return suffix + 'zubehor';
    case process.env.CARE:
      return suffix + 'pflege';
    default:
      return suffix + 'nassfutter';
  }
};
