import { CheckoutFragment } from './fragments/checkout';

export const FETCH_CHECKOUT = /* GraphQL */ `
  query fetchCheckout($id: ID!) {
    node(id: $id) {
      id
      __typename
      ...CheckoutFragment
    }
  }
  ${CheckoutFragment}
`;

export const CREATE_CHECKOUT = /* GraphQL */ `
  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        id
        __typename
        ...CheckoutFragment
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
  ${CheckoutFragment}
`;

export const UPDATE_CHECKOUT_LINE_ITEMS = /* GraphQL */ `
  mutation checkoutLineItemsReplace(
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemInput!]!
  ) {
    checkoutLineItemsReplace(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkout {
        __typename
        id
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;
