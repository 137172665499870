import PromoBarComponent from 'components/promo-bar';
import { useCart } from 'contexts/cart';
import { IPromoBarFields } from 'global';
import React from 'react';
import { showSuccessToast } from 'utils/toasts';

const PromoBar: React.FC<IPromoBarFields> = ({
  enabled,
  link,
  identifier,
  text,
  cta,
  backgroundColor,
  couponCode,
  toastText,
  discountRate,
  hideCloseButton,
  modalId,
}) => {
  const [couponApplied, setCouponApplied] = React.useState(false);

  const { setCouponCode } = useCart();

  React.useEffect(() => {
    if (couponApplied && toastText) {
      showSuccessToast({
        message: toastText,
        autoClose: false,
      });
    }
  }, [couponApplied, toastText]);

  const callBack = (): Promise<boolean> => {
    if (couponCode) {
      setCouponCode(couponCode);
      setCouponApplied(true);
    }
    return Promise.resolve(true);
  };

  return (
    <PromoBarComponent
      promoBarConfiguration={{
        enabled,
        href: link,
        identifier,
        text,
        cta,
        backgroundColor,
        couponCode,
        toastText,
        discountRate,
        hideCloseButton,
        modalId,
        callBack,
      }}
    />
  );
};

export default PromoBar;
