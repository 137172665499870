export default {
  'discount-form:error:code-internal-usage-not-found': 'Ungültiger Couponcode',
  'discount-form:error:code-expired': 'Ungültiger Couponcode',
  'discount-form:error:code-exhausted-customer':
    'Dieser Couponcode ist nicht mehr einlösbar',
  'discount-form:error:code-not-applicable-to-customer':
    'Du kannst diesen Code aktuell nicht einlösen. Bitte wende dich an den Kundenservice.',
  'discount-form:error:customer-fidelity-only-new-orders':
    'Dieser Couponcode ist nur für Neukunden gültig.',
  'discount-form:error:customer-fidelity-only-old-customers':
    'Dieser Couponcode ist nur für Bestandskunden gültig.',
  'discount-form:error:basket-value-too-low':
    'Dein Warenkorb ist kleiner als der Mindestbestellwert für diesen Gutschein',
};
