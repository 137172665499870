import get from 'lodash/get';
import {
  ExpressCheckout,
  FormattedShopifyCheckModel,
} from '../../interfaces/checkout';
import * as EffectiveCartModel from '../effective-cart';
import * as LineItemModel from './line-item';

export const fromShopifyFormat = (cart: any): FormattedShopifyCheckModel => {
  const lineItems = Array.from(get(cart, 'lineItems', []))
    .filter((lineItem: any) => lineItem.variant)
    .filter((lineItem: any) => lineItem.variant.availableForSale);
  const totalItemsQuantity = lineItems.reduce(
    (quantity: number, item: any) => quantity + item.quantity,
    0
  );

  return {
    totalItemsQuantity,
    id: cart.id,
    items: lineItems.map(LineItemModel.fromShopifyFormat),
  };
};

export const expressCheckoutFromAmazonFormat = (
  checkout: ExpressCheckout
): ExpressCheckout => ({
  ...checkout,
  effectiveCart: EffectiveCartModel.fromServerFormat(checkout.effectiveCart),
});
export const expressCheckoutSummaryFromServerFormat = (summary: any) => ({
  ...summary,
  effectiveCart: EffectiveCartModel.fromServerFormat(summary.effectiveCart),
});
