export const getSegmentationContainerIdsQuery = /* GraphQL */ `
  query getSegmentationContainerIds($productHandles: [String!] = []) {
    productPageCollection(limit: 30, where: { handle_in: $productHandles }) {
      items {
        handle
        sys {
          id
        }
        segmentationData {
          sys {
            id
          }
        }
      }
    }
    productPagesCollection(limit: 30, where: { handle_in: $productHandles }) {
      items {
        handle
        sys {
          id
        }
        segmentationData {
          sys {
            id
          }
        }
      }
    }
  }
`;
