import IconAnimatedSpinner from 'assets/icons/animated-spinner.svg';
import cn from 'classnames';
import useIsClient from 'hooks/common/use-is-client';
import useNavigationData from 'hooks/navigation/use-navigation-data';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import NextLink from 'next/link';
import React from 'react';
import { gtm, selectElement } from 'tracking';
import { usePetContext } from '../../../contexts/pet';
import Search from '../search';
import Dropdown from './dropdown';
import Link from './link';
import Mobile from './mobile';
import styles from './nav.module.css';
import PrimaryLink from './primary-link';
import Separator from './separator';

const CustomerLinksAsync = dynamic(() => import('./customer-links'), {
  loading: () => <IconAnimatedSpinner className="h-6 w-6 text-white" />,
  ssr: false,
});

const Nav: React.FC = () => {
  const { current, homeLink } = usePetContext();
  const { desktop } = useNavigationData();

  const [dropdownShadow, setDropdownShadow] = React.useState(false);
  const { isClient } = useIsClient();

  return (
    <>
      <div className="h-18 bg-white lg:h-16 lg:bg-brand-primary">
        <div
          className={cn(
            'relative mx-auto flex h-18 items-center justify-between px-4 lg:container lg:h-16 lg:w-full lg:px-8 ',
            styles.container
          )}
        >
          <div className="relative flex cursor-pointer items-center justify-center lg:hidden">
            <div className="flex h-6 w-8 items-center lg:hidden">
              <Mobile />
            </div>
            {isClient && (
              <NextLink {...homeLink} passHref>
                <a aria-label="Pets Deli">
                  <img
                    src="/images/petsdeli-logo-mobile.svg"
                    className="h-6 w-auto lg:hidden"
                    alt="Pets Deli"
                    role="presentation"
                  />
                </a>
              </NextLink>
            )}
          </div>

          <div className="absolute left-0 top-5 z-nav-logo mx-5 self-center">
            {isClient && (
              <NextLink {...homeLink} passHref>
                <a className="block" aria-label="Pets Deli">
                  <img
                    src="/images/petsdeli-logo.svg"
                    className="hidden h-main-logo w-auto lg:block"
                    alt="Pets Deli"
                    role="presentation"
                  />
                </a>
              </NextLink>
            )}
          </div>
          <div className="ml-24 hidden h-full lg:flex">
            {desktop.map((item) => {
              return (
                <PrimaryLink
                  key={item.title}
                  petContextAware={item.context}
                  href={item.url}
                  onClick={() =>
                    gtm(
                      selectElement({
                        element: ['Navigation', item.title].map(String),
                      })
                    )
                  }
                >
                  {item.title}
                </PrimaryLink>
              );
            })}

            <div className="relative ml-4 mr-6 flex h-12 w-105 items-center self-center">
              <Search />
            </div>

            <div className="w-6 self-center">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.trustedshops.de/bewertung/info_XF6F29E67F210CC76FD76E9BD556839EB.html"
              >
                <div className="h-auto w-8">
                  <Image
                    src="/images/trust/trusted-shops-seal.png"
                    alt="Trusted Shops"
                    layout="responsive"
                    width="144"
                    height="144"
                    role="presentation"
                  />
                </div>
              </a>
            </div>
          </div>

          <div className="flex items-center">
            <CustomerLinksAsync />
            {/* <Suspense
              fallback={<IconAnimatedSpinner className="w-6 h-6 text-white" />}
            > */}
            {/* </Suspense> */}
          </div>
        </div>
      </div>
      <div
        className={cn(
          'hidden items-center justify-between lg:relative lg:block lg:h-14 lg:w-full'
        )}
      >
        <div className={cn(styles.container, 'mx-auto px-8 lg:container')}>
          {desktop.map((item, topLevelIndex) => {
            if (item.context) {
              return (
                <div
                  key={topLevelIndex}
                  className={cn(
                    'relative ml-24 hidden h-14 items-center justify-start lg:flex ',
                    item.context === 'cats' && 'bg-white',
                    item.context === 'dogs' && 'bg-white',
                    current !== item.context && 'lg:hidden'
                  )}
                >
                  <div className="z-50 flex items-center ">
                    {item.items?.map((subNav, index) => {
                      return (
                        <div className="flex text-sm " key={index}>
                          {(subNav.itemType === 'nested-card' ||
                            subNav.itemType === 'nested-entry') &&
                            subNav.items.map((subNavItem, subNavIndex) => {
                              return (
                                <div className="inline-flex " key={subNavIndex}>
                                  {(subNavItem.itemType === 'entry' ||
                                    subNavItem.itemType === 'card') && (
                                    <Link
                                      item={subNavItem}
                                      onClick={() =>
                                        gtm(
                                          selectElement({
                                            element: [
                                              'Navigation',
                                              item.title,
                                              subNavItem.title,
                                            ].map(String),
                                          })
                                        )
                                      }
                                    >
                                      {subNavItem.title}
                                    </Link>
                                  )}
                                  {(subNavItem.itemType === 'nested-card' ||
                                    subNavItem.itemType === 'nested-entry') && (
                                    <Dropdown
                                      {...subNavItem}
                                      onClick={() =>
                                        gtm(
                                          selectElement({
                                            element: [
                                              'Navigation',
                                              item.title,
                                              subNavItem.title,
                                            ].map(String),
                                          })
                                        )
                                      }
                                      onItemClick={(title) =>
                                        gtm(
                                          selectElement({
                                            element: [
                                              'Navigation',
                                              item.title,
                                              subNavItem.title,
                                              title,
                                            ].map(String),
                                          })
                                        )
                                      }
                                      setDropShadow={setDropdownShadow}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          {item.items && item.items.length - 1 !== index && (
                            <Separator layout="line" />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            }
            return <React.Fragment key={topLevelIndex} />;
          })}
        </div>
        {dropdownShadow && (
          <div
            className={cn(
              'absolute left-0  top-full z-10 h-screen w-screen bg-brand-black-20 '
            )}
          />
        )}
      </div>
    </>
  );
};

export default Nav;
