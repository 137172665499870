import { useSpecialAbTestContext } from 'contexts/special-ab-test-provider';
import useTest5830 from 'hooks/test/use-test-5830';
import { deepMerge } from 'hooks/test/use-test-5830/helper';
import React, { memo, useEffect } from 'react';

interface SpecialTestWrapperProps {
  id: string;
}

const SpecialTestWrapper: React.FC<SpecialTestWrapperProps> = ({ id }) => {
  const { setTestData, setExperienceIds } = useSpecialAbTestContext();
  // Maybe
  const { testData, experienceId } = useTest5830({
    baseEntryId: id,
  });

  useEffect(() => {
    if (testData) {
      setTestData((prev) => {
        if (!testData) {
          return prev;
        }
        const mergedData = deepMerge(prev, testData);
        return mergedData;
      });
    }
  }, [setTestData, testData]);

  useEffect(() => {
    if (experienceId) {
      setExperienceIds((prev) => {
        return Array.from(new Set([...prev, experienceId]));
      });
    }
  }, [experienceId, setExperienceIds]);

  return <></>;
};

export default memo(SpecialTestWrapper);
