import { shopifyGidToId } from '../../utils/shopify';
import * as VariantModel from '../variant';
import {
  customAttributesFromShopifyFormat,
  customAttributesToShopifyFormat,
} from '../shopify-common';

export const toShopifyFormat = (lineItem: any) => ({
  ...lineItem,
  customAttributes:
    lineItem.customAttributes &&
    customAttributesToShopifyFormat(lineItem.customAttributes),
  id: lineItem.gid,
  variant: lineItem.variant && VariantModel.toShopifyFormat(lineItem.variant),
});

export const fromShopifyFormat = (lineItem: any) => ({
  ...lineItem,
  customAttributes:
    lineItem.customAttributes.length > 0
      ? customAttributesFromShopifyFormat(lineItem.customAttributes)
      : undefined,
  variant: VariantModel.fromShopifyFormat(lineItem.variant),
  id: shopifyGidToId(lineItem.id),
  gid: lineItem.id,
});
