export default {
  'address-form:first-name-label': 'Vorname',
  'address-form:last-name-label': 'Nachname',
  'address-form:street-label': 'Straße + Nr',
  'address-form:additional-details-label':
    'Adresszusatz / Firma / Co / Postnummer',
  'address-form:zip-label': 'PLZ',
  'address-form:town-label': 'Stadt',
  'address-form:country-label': 'Land',
  'address-form:phone-label': 'Telefonnummer',
};
