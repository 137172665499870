import { Gtm } from '.';
import { Event } from '../events';
import { Session } from '../types';
import * as Petsdeli from './petsdeli';
import { Queue } from './queue';
const dl =
  typeof window !== 'undefined'
    ? (window.dataLayer = window.dataLayer || [])
    : [];

type Push = Event<unknown> | Gtm.Events | Gtm.State;

//Mediate between Events and Grouped Events
export interface NormalizedPush extends Partial<Session> {
  event: string;
  eventSource: 'petsdeli';
  eventName?: string;
  eventGroup?: string;
  eventData?: unknown;
  eventNonInteractive?: boolean;
}

function isGetSessionPush(x: NormalizedPush) {
  return 'clientIp' in x;
}

const queue = new Queue<NormalizedPush>();

let defer = true;
export async function gtm(...pushes: Push[]): Promise<void> {
  const normalized = pushes.reduce(normalizeEvents, []);

  if (defer) {
    const getSessionPush = normalized.find(isGetSessionPush);
    if (getSessionPush) {
      defer = false;
      queue.unshift(getSessionPush);
      normalized.splice(normalized.indexOf(getSessionPush), 1);
    } else {
      queue.push(...normalized);
      return queue.promise();
    }
  }
  queue.push(...normalized);

  return queue.run(
    (x, i, ls) =>
      Promise.all([
        Petsdeli.track(x),
        new Promise<void>((r) => {
          const timeOut = setTimeout(() => r(), 3000); //max timeout in case gtm doesn't respond on eventCallback
          dl.push({
            ...x,
            eventCallback: () => {
              clearTimeout(timeOut);
              r();
            },
          });
          if (i === ls.length - 1) {
            dl.push({
              eventData: undefined,
              eventName: undefined,
              eventGroup: undefined,
              eventSource: undefined,
            });
          }
        }),
      ]).then(() => {}) //function spec expects void to be returned
  );
}

function normalizeEvents(
  r: NormalizedPush[],
  x: Event<unknown> | Gtm.Events | Gtm.State
): NormalizedPush[] {
  const needsSessionEvent =
    'session' in x && (!('name' in x) || x.name !== 'Set Session');

  if (needsSessionEvent) {
    const { session } = x;
    r.push({
      event: 'Set Session',
      eventSource: 'petsdeli',
      ...session,
    });
  }

  if ('name' in x) {
    if ('group' in x) {
      r.push({
        event: `${x.group} ${x.name}`,
        eventSource: 'petsdeli',
        eventGroup: x.group,
        eventName: x.name,
        ...('data' in x ? { eventData: x.data } : {}),
        ...('nonInteractive' in x
          ? { eventNonInteractive: x.nonInteractive }
          : {}),
      });
    } else {
      r.push({
        event: x.name,
        eventSource: 'petsdeli',
        ...('properties' in x ? { eventData: x.properties } : {}),
      });
    }
  }

  return r;
}
