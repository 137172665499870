import * as Sentry from '@sentry/browser';
import { captureConsoleIntegration } from '@sentry/integrations';

// Ignore list based off: https://gist.github.com/1878283
// Will cause a deprecation warning, but the demise of `ignoreErrors` is still under discussion.
// See: https://github.com/getsentry/raven-js/issues/73
const ignoreErrors = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // Generic error code from errors outside the security sandbox
  // You can delete this if using raven.js > 1.0, which ignores these automatically.
  'Script error.',
];
const denyUrls = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];

if (
  process.env.SENTRY_URL &&
  process.env.DISABLE_SENTRY !== 'true' &&
  process.env.NODE_ENV !== 'development'
) {
  Sentry.init({
    dsn: process.env.SENTRY_URL,
    release: process.env.COMMIT_HASH,
    environment: process.env.PD_ENV,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      captureConsoleIntegration({
        levels: ['error', 'debug'],
      }),
    ],
    ignoreErrors: [
      // ignore Hydration error on production
      /Minified React error #418/gi,
      /Hydration failed because the initial UI/gi,
      ...ignoreErrors,
    ],
    denyUrls,
  });
}
