import Router from 'next/router';
import NProgress from 'nprogress';
import React from 'react';
import { usePetContext } from '../../contexts/pet';
import getPetContextFromUrl from '../../utils/get-pet-context-from-url';

const PageProgress: React.FC = () => {
  const { current, switchContext } = usePetContext();
  const routeChangeStart = (): void => {
    NProgress.start();
  };
  const routeChangeEnd = React.useCallback(() => {
    NProgress.done();

    const newContext = getPetContextFromUrl(window.location.href);

    if (newContext !== current && newContext !== false) {
      switchContext(newContext);
    } else if (current === false && newContext === false) {
      if (
        window.location.href.indexOf('login') > -1 ||
        window.location.href.indexOf('account') > -1
      ) {
        switchContext('dogs');
      }
    }
  }, [current, switchContext]);

  React.useEffect(() => {
    NProgress.configure({});

    Router.events.on('routeChangeStart', routeChangeStart);
    Router.events.on('routeChangeComplete', routeChangeEnd);
    Router.events.on('routeChangeError', routeChangeEnd);

    return () => {
      Router.events.on('routeChangeStart', routeChangeStart);
      Router.events.off('routeChangeComplete', routeChangeEnd);
      Router.events.off('routeChangeError', routeChangeEnd);
    };
  }, [routeChangeEnd]);

  return null;
};

export default PageProgress;
