import {
  EntryNavigationItem,
  INavigationData,
  NavigationItem,
  NestedEntryNavigationItem,
} from '../../../interfaces/navigation-data';
import {
  catsCommonFoodType,
  catsCommonMixed,
  catsCommonNeeds,
  commonMobileFooter,
  commonTrustbar,
  dogsCommonBreeds,
  dogsCommonFoodType,
  dogsCommonMixed,
  dogsCommonNeeds,
  footer,
} from './common';

/**
 * Product Types
 */
const productTypesDogs = (): Array<EntryNavigationItem> => [
  {
    url: '/collections/fur-hunde-probierpakete',
    title: 'Probierpakete',
    src: '/images/category-slider/hunde-probierpakete.jpg',
    itemType: 'entry',
  },
  {
    url: '/collections/fur-hunde-nassfutter',
    title: 'Nassfutter',
    src: '/images/category-slider/hunde-nassfutter.jpg',
    itemType: 'entry',
  },
  {
    url: '/collections/fur-hunde-trockenfutter',
    title: 'Trockenfutter',
    src: '/images/category-slider/hunde-trockenfutter.jpg',
    itemType: 'entry',
  },
  {
    url: '/collections/fur-hunde-barf-frische-menus',
    title: 'BARF',
    src: '/images/category-slider/hunde-barf.jpg',
    itemType: 'entry',
  },
  {
    url: '/collections/fur-hunde-snacks',
    title: 'Snacks',
    src: '/images/category-slider/hunde-snacks.jpg',
    itemType: 'entry',
  },
];

const productTypesDogsForNC = (): Array<EntryNavigationItem> => [
  {
    url: '/collections/fur-hunde-nassfutter',
    title: 'Nassfutter',
    src: '/images/category-slider/hunde-nassfutter.jpg',
    itemType: 'entry',
  },
  {
    url: '/collections/fur-hunde-trockenfutter',
    title: 'Trockenfutter',
    src: '/images/category-slider/hunde-trockenfutter.jpg',
    itemType: 'entry',
  },
  {
    url: '/collections/fur-hunde-barf-frische-menus',
    title: 'BARF',
    src: '/images/category-slider/hunde-barf.jpg',
    itemType: 'entry',
  },
  {
    url: '/collections/fur-hunde-snacks',
    title: 'Snacks',
    src: '/images/category-slider/hunde-snacks.jpg',
    itemType: 'entry',
  },
  {
    url: '/collections/fur-hunde-zubehor',
    title: 'Zubehör',
    src: '/images/category-slider/hunde-zubehor-lp.jpg',
    itemType: 'entry',
  },
  {
    url: '/collections/fur-hunde-gesundheit',
    title: 'Gesundheit',
    src: '/images/category-slider/hunde-gesundheit-lp.jpg',
    itemType: 'entry',
  },
  {
    url: '/collections/fur-hunde-pflege',
    title: 'Pflege',
    src: '/images/category-slider/hunde-pflege-lp.jpg',
    itemType: 'entry',
  },
  {
    url: '/collections/fur-hunde-nahrungserganzung',
    title: 'Nahrungsergänzung',
    src: '/images/category-slider/hunde-vitamin-minarel-lp.jpg',
    itemType: 'entry',
  },
];

/**
 * Mobile menu tree for Dogs
 */
const mobileMenuTreeDogs = (): Array<
  NestedEntryNavigationItem | EntryNavigationItem
> => [
  {
    url: '/pages/hunde',
    title: 'Home',
    itemType: 'entry',
    dividerBottom: true,
  },
  ...dogsCommonNeeds,
  ...dogsCommonBreeds,
  {
    title: 'Influencer-Favoriten',
    itemType: 'nested-entry',
    dividerBottom: true,
    items: [
      {
        url: '/collections/influencer-kooperation-dog-ridgbackdiaries',
        title: 'ridgback_diaries',
        itemType: 'entry',
      },
      {
        url: '/collections/influencer-kooperation-dog-fabianniessl',
        title: 'fabian.niessl',
        itemType: 'entry',
      },
      {
        url: '/collections/influencer-kooperation-dog-nicolette-vlogt',
        title: 'nicolette.vlog',
        itemType: 'entry',
      },
    ],
  },
  ...dogsCommonFoodType,
  ...dogsCommonMixed,
  ...commonMobileFooter,
];

/**
 * Desktop menu tree for Dogs
 */
const desktopMenuTreeDogs = (): Array<NavigationItem> => [
  {
    items: [...dogsCommonNeeds, ...dogsCommonBreeds],
    itemType: 'nested-entry',
  },
  {
    items: [...dogsCommonFoodType],
    itemType: 'nested-entry',
  },
  {
    items: [...dogsCommonMixed],
    itemType: 'nested-entry',
  },
];

/**
 * Mobile menu tree for Cats
 */
const mobileMenuTreeCats = (): Array<
  NestedEntryNavigationItem | EntryNavigationItem
> => [
  {
    url: '/pages/katzen',
    title: 'Home',
    itemType: 'entry',
    dividerBottom: true,
  },
  ...catsCommonNeeds,
  {
    title: 'Influencer Favoriten',
    itemType: 'nested-entry',
    items: [
      {
        title: 'amy_simba',
        url: '/collections/influencer-kooperation-cat-amysimba',
        itemType: 'entry',
      },
      {
        title: 'marleenscatdiary',
        url: '/collections/influencer-kooperation-cat-marleenscatdiary',
        itemType: 'entry',
      },
      {
        title: 'saradesideria',
        url: '/collections/influencer-kooperation-cat-saradesideria',
        itemType: 'entry',
      },
    ],
  },
  ...catsCommonFoodType,
  {
    url: '/collections/probierpakete-fur-katzen',
    title: 'Probierpakete',
    itemType: 'entry',
  },
  ...catsCommonMixed,
  ...commonMobileFooter,
];

/**
 * Desktop menu tree for Cats
 */
const desktopMenuTreeCats = (): Array<
  NestedEntryNavigationItem | EntryNavigationItem
> => [
  {
    items: [...catsCommonNeeds, ...catsCommonFoodType],
    itemType: 'nested-entry',
  },
  {
    itemType: 'nested-entry',
    items: [
      {
        url: '/collections/probierpakete-fur-katzen',
        title: 'Probierpakete',
        itemType: 'entry',
      },
    ],
  },
  {
    itemType: 'nested-entry',
    items: [...catsCommonMixed],
  },
];

const productTypesCatsForNC = (): Array<EntryNavigationItem> => [
  {
    title: 'Nassfutter',
    url: '/collections/fur-katzen-nassfutter',
    src: '/images/category-slider/katzen-nassfutter.jpg',
    itemType: 'entry',
  },
  {
    title: 'Trockenfutter',
    url: '/collections/fur-katzen-trockenfutter',
    src: '/images/category-slider/katzen-trockenfutter.jpg',
    itemType: 'entry',
  },
  {
    title: 'Snacks',
    url: '/collections/fur-katzen-snacks',
    src: '/images/category-slider/katzen-snacks.jpg',
    itemType: 'entry',
  },
  {
    title: 'Zubehör',
    url: '/collections/fur-katzen-zubehor',
    src: '/images/category-slider/katzen-zubehor-lp.jpg',
    itemType: 'entry',
  },
];

const useNavigationDataDe = (): INavigationData => {
  return {
    mobile: {
      dogs: [[...mobileMenuTreeDogs()]],
      cats: [[...mobileMenuTreeCats()]],
    },
    category: {
      dogs: [...productTypesDogs()],
      dogsForEc: [...productTypesDogsForNC()],
      catsForEc: [...productTypesCatsForNC()],
      cats: [
        {
          title: 'Katzenstreu',
          url: '/collections/katzenstreu-natur-fur-katzen',
          src: '/images/category-slider/katzenstreu-new.jpg',
          itemType: 'card',
        },
      ],
    },
    footer: footer(),
    trustbar: [...commonTrustbar],
    desktop: [
      {
        title: 'Hunde',
        context: 'dogs',
        url: '/pages/hunde',
        items: [...desktopMenuTreeDogs()],
      },
      {
        title: 'Katzen',
        context: 'cats',
        url: '/pages/katzen',
        items: [...desktopMenuTreeCats()],
      },
    ],
  };
};
export default useNavigationDataDe;
