export const SourceKey = {
  ExpressCheckout: 'express-checkout',
} as const;

export type SourceKey = (typeof SourceKey)[keyof typeof SourceKey];

export const ErrorKey = {
  /** Error code for when payment failed with less amount or limit in the card */
  InstrumentDeclined: 'INSTRUMENT_DECLINED',
  /** Error code for when address is invalid */
  InvalidShippingAddress: 'InvalidShippingAddress',
  /** Error code for unknown error */
  Unknown: 'unknown',
  /** Error code for undefined */
  Undefined: 'undefined',
} as const;

export type ErrorKey = (typeof ErrorKey)[keyof typeof ErrorKey];
