import { useNinetailed, useProfile } from '@ninetailed/experience.js-next';
import { pushSegmentation } from 'api/customer';
import { FeatureFlag, getFeatureFlag } from 'constants/feature-flag';
import {
  SegmentationData,
  SegmentationIds,
  isNutritionSegmentation,
} from 'constants/segmentation';
import useIsAdultRelated from 'hooks/common/use-is-adult-related';
import { useRouter } from 'next/router';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocalStorage } from 'react-use';
import { gtm } from 'tracking/gtm';
import { isTraits } from '../utils/ninetailed';
import { useAuth } from './auth';
import { PetContextValues, usePetContext } from './pet';

/**
 * Represents the location segmentation is selected
 */
export const SegmentationLocation = {
  /** Home FTE */
  HomeFte: 'home-fte',
  /** Home Dog */
  HomeDog: 'home-dog',
  /** Home Cat */
  HomeCat: 'home-cat',
  /** Pdp link at the end of slider */
  PDPLink: 'pdp-link',
  /** Navigation */
  Navi: 'navi',
  /** Fube */
  Fube: 'fube',
} as const;

type FilterLocation = {
  /** It should be specified segmentation is updated on CDP filter value */
  collectionHandle: string;
};
/** Assertion function for FilterLocation */
export function isFilterLocation(obj: any): obj is FilterLocation {
  return obj['collectionHandle'] !== undefined;
}

export type SegmentationLocation =
  (typeof SegmentationLocation)[keyof typeof SegmentationLocation];

interface SegmentationContextType {
  segmentationData: typeof SegmentationData;
  /** Factory method to track segmentation push for gtm */
  pushSegmentationFactory: (arg: {
    location: SegmentationLocation | FilterLocation;
    /** if true omit component click event and only session event. */
    omitComponentEvent?: boolean;
  }) => (arg: {
    segmentation: string;
    /**
     * pet type, if not assigned the value is retrieved from cookie value.
     * This is optional because pet type context logic is not secure and there is an occasion when we want to overeide intentionally
     */
    petType?: PetContextValues;
  }) => ReturnType<typeof pushSegmentation>;
  /** Current selected segmentation. it's stored in local storage to bridge sessions */
  segmentation?: string;
  /** Get segmentation data from associated URL */
  getSegmentationFromUrl: (arg: {
    url: string;
  }) => (typeof SegmentationData)['segmentation'][0] | undefined;
  /** true if segmentation data is being validated  */
  isSegmentationReady: boolean;
  /** true if personalization should be omitted e.g welpe personalization on adult page */
  shouldOmitPersonalization: boolean;
}

const SegmentationContext = createContext<SegmentationContextType>({} as any);

type SegmentationStore = {
  breedSegmentation?: string;
  needSegmentation?: string;
};

/**
 * Context to share pet needs segmentation through app and take care of updating it
 */
export const SegmentationProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const featureFlg = getFeatureFlag(FeatureFlag.segmentation);
  const { asPath, isReady } = useRouter();
  const [localSegmentation, setLocalSegmentation] = useLocalStorage<string>(
    'pd:segmentation',
    ''
  );

  const [localSegmentationStore, setLocalSegmentationStore] =
    useLocalStorage<SegmentationStore>('pd:segmentationStore', {});
  const [segmentationState, setSegmentationState] = useState<
    string | undefined
  >(localSegmentation);

  const [segmentationStoreState, setSegmentationStoreState] =
    useState<SegmentationStore>(localSegmentationStore || {});

  const { identify } = useNinetailed();
  const { profile, loading } = useProfile();
  const { customer } = useAuth();
  const { current: cookiePetValue } = usePetContext();

  const setSegmentation = useCallback(
    ({ segmentation }: { segmentation: string }) => {
      if (!featureFlg) {
        return;
      }
      setLocalSegmentation(segmentation);
      setSegmentationState(segmentation);
      const update = isNutritionSegmentation(segmentation as SegmentationIds)
        ? { needSegmentation: segmentation }
        : { breedSegmentation: segmentation };
      const newVal = {
        ...segmentationStoreState,
        ...update,
      };
      setLocalSegmentationStore(newVal);
      setSegmentationStoreState(newVal);
    },
    [
      featureFlg,
      segmentationStoreState,
      setLocalSegmentation,
      setLocalSegmentationStore,
    ]
  );

  /**
   * Sync segmentation from Ninetaied if it's not stored on local.
   */
  const segmentationSyncRef = useRef(false);
  useEffect(() => {
    if (
      !loading &&
      profile &&
      isTraits(profile.traits) &&
      profile.traits.segmentation &&
      !segmentationState &&
      !localSegmentation &&
      !segmentationSyncRef.current
    ) {
      if (process.env.SHOP_ID === 'CH') {
        return;
      }
      segmentationSyncRef.current = true;

      const newVal = {
        breedSegmentation: profile.traits.breedSegmentation,
        needSegmentation: profile.traits.needSegmentation,
      };
      setLocalSegmentationStore(newVal);
      setSegmentationStoreState(newVal);
      setSegmentation({ segmentation: profile.traits.segmentation });
    }
  }, [
    loading,
    localSegmentation,
    profile,
    segmentationState,
    setLocalSegmentationStore,
    setSegmentation,
  ]);

  /**
   * Sync segmentation to Ninetailed
   */
  useEffect(() => {
    if (segmentationState) {
      identify(`${customer ? customer.customerId : ''}`, {
        segmentation: segmentationState,
        ...segmentationStoreState,
      });
    }
  }, [customer, identify, segmentationState, segmentationStoreState]);

  const getSegmentationFromUrl: SegmentationContextType['getSegmentationFromUrl'] =
    useCallback(({ url }) => {
      // trim 1. url prams and filter value ([handle]/huhn) 2.empty space
      const trimmedUrl = url.replace(/\?.*$(?![^/]*\/)/, '').replace(/\s/g, '');
      return SegmentationData.segmentation.find(
        // It needs to remove the filters in the url, in case these are present
        (seg) =>
          seg.redirectPages.some((redirectPage) => redirectPage === trimmedUrl)
      );
    }, []);

  /**
   * @TODO : infinite update is caused by either segmentationStoreState or setSegmentation
   */
  const pushSegmentationFactory: SegmentationContextType['pushSegmentationFactory'] =
    useCallback(
      () =>
        ({ segmentation: _segmentation, petType }) => {
          const _petType = petType || cookiePetValue;

          // Map segmentation data to GA segmentation properties name
          const update = isNutritionSegmentation(
            _segmentation as SegmentationIds
          )
            ? { nutritionalNeeds: _segmentation }
            : { breed: _segmentation };

          const newVal = {
            breed: segmentationStoreState.breedSegmentation,
            nutritionalNeeds: segmentationStoreState.needSegmentation,
            lastSegment: _segmentation,
            ...update,
          };

          gtm({
            session: {
              ...newVal,
              ...(typeof _petType === 'boolean' ? {} : { petType: _petType }),
            },
          });
          setSegmentation({
            segmentation: _segmentation,
          });
          return pushSegmentation({ segmentation: _segmentation });
        },

      [cookiePetValue, segmentationStoreState, setSegmentation]
    );

  /**
   * Set segmentation on gtm session from local storage in the case user comes back
   * or url when user directly comes to associated landing pages.
   */
  const triggerInitialPush = useRef(false);

  useEffect(() => {
    if (!isReady) return;

    if (!triggerInitialPush.current) {
      triggerInitialPush.current = true;
      const cookieSession =
        typeof cookiePetValue === 'boolean' ? {} : { petType: cookiePetValue };
      const _segmentation = getSegmentationFromUrl({ url: asPath });

      const updateGTMSession = (sessionData: {
        lastSegment: string;
        update: {
          breed?: string;
          nutritionalNeeds?: string;
        };
      }): void => {
        gtm({
          session: {
            lastSegment: sessionData.lastSegment,
            ...(cookiePetValue && { petType: cookiePetValue }),
            ...sessionData.update,
            ...cookieSession,
          },
        });
      };

      const processSegmentation = (
        _segmentation: (typeof SegmentationData)['segmentation'][0],
        localSegmentationStore: SegmentationStore | undefined
      ): void => {
        const update = isNutritionSegmentation(
          _segmentation.id as SegmentationIds
        )
          ? {
              nutritionalNeeds: _segmentation.id,
              ...(localSegmentationStore?.breedSegmentation && {
                breed: localSegmentationStore?.breedSegmentation,
              }),
            }
          : {
              breed: _segmentation.id,
              ...(localSegmentationStore && {
                nutritionalNeeds: localSegmentationStore?.needSegmentation,
              }),
            };

        const sessionData = {
          lastSegment: _segmentation.id,
          update: update,
        };

        updateGTMSession(sessionData);

        setSegmentation({ segmentation: _segmentation.id });
      };

      if (localSegmentationStore && !_segmentation) {
        gtm({
          session: {
            nutritionalNeeds: localSegmentationStore?.needSegmentation,
            breed: localSegmentationStore?.breedSegmentation,
            lastSegment: localSegmentation,
            ...(cookiePetValue && { petType: cookiePetValue }),
            ...cookieSession,
          },
        });
      }

      if (_segmentation) {
        processSegmentation(_segmentation, localSegmentationStore);
      }
    }
  }, [
    asPath,
    cookiePetValue,
    isReady,
    localSegmentation,
    localSegmentationStore,
    getSegmentationFromUrl,
    setSegmentation,
  ]);

  const { shouldOmitPersonalization } = useIsAdultRelated({
    segmentation: segmentationState,
  });

  const value = React.useMemo(() => {
    return {
      segmentationData: SegmentationData,
      segmentation: segmentationState,
      pushSegmentationFactory,
      getSegmentationFromUrl,
      isSegmentationReady: true,
      shouldOmitPersonalization,
    };
  }, [
    segmentationState,
    pushSegmentationFactory,
    getSegmentationFromUrl,
    shouldOmitPersonalization,
  ]);

  return (
    <SegmentationContext.Provider value={value}>
      {children}
    </SegmentationContext.Provider>
  );
};

export const useSegmentationContext = () => useContext(SegmentationContext);
