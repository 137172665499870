import { ApiRequest, push } from 'api/tracking';
import { NormalizedPush } from './gtm-helper';

/**
 * This is Petsdelis own Miocroservice for tracking
 * currently only for debugging
 * simple forwarding of selected events
 */

let user: ApiRequest['user'] = {};

export async function track(rq: NormalizedPush): Promise<unknown> {
  if (rq.event === 'Set Session') {
    const userUpdate = {
      orders_count: rq.customerOrdersCount?.toString(),
      subscription_status: rq.customerSubscriptionStatus,
      breed: rq.breed,
      last_segment: rq.lastSegment,
      nutritional_needs: rq.nutritionalNeeds,
    };
    user = {
      ...user,
      ...Object.fromEntries(
        Object.entries(userUpdate).filter(
          ([_, v]) => v !== undefined && v !== null
        )
      ),
    };
  }
  if (!rq.eventName) return;

  const props =
    rq.eventData !== null && typeof rq.eventData === 'object'
      ? rq.eventData
      : undefined;

  if (/purchase/gi.test(rq.eventName)) {
    return push({
      url: window.location.href,
      event: 'purchase',
      props,
      user,
    });
  }
}
