import { useEffect, useState } from 'react';

type Output = {
  isClient: boolean;
};

/**
 * Check if component is rendered on client side or not.
 * Useful to avoid react hydration error
 * @see : https://beta.reactjs.org/reference/react-dom/client/hydrateRoot#suppressing-unavoidable-hydration-mismatch-errors
 */
const useIsClient = (): Output => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  return {
    isClient,
  };
};

export default useIsClient;
