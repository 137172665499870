import {
  EntryNavigationItem,
  INavigationData,
  NavigationItem,
  NestedEntryNavigationItem,
} from '../../../interfaces/navigation-data';
import {
  catsCommonNeeds,
  commonMobileFooter,
  commonTrustbar,
  dogsCommonBreeds,
  dogsCommonFoodType,
  dogsCommonNeeds,
  footer,
} from './common';

/**
 * Product Types
 */
const productTypesDogs = (): Array<EntryNavigationItem> => [
  {
    url: '/collections/fur-hunde-probierpakete',
    title: 'Probierpakete',
    src: '/images/category-slider/hunde-probierpakete.jpg',
    itemType: 'entry',
  },
  {
    url: '/collections/fur-hunde-nassfutter',
    title: 'Nassfutter',
    src: '/images/category-slider/hunde-nassfutter.jpg',
    itemType: 'entry',
  },
  {
    url: '/collections/fur-hunde-trockenfutter',
    title: 'Trockenfutter',
    src: '/images/category-slider/hunde-trockenfutter.jpg',
    itemType: 'entry',
  },
  {
    url: '/collections/fur-hunde-barf-frische-menus',
    title: 'BARF',
    src: '/images/category-slider/hunde-barf.jpg',
    itemType: 'entry',
  },
  {
    url: '/collections/fur-hunde-snacks',
    title: 'Snacks',
    src: '/images/category-slider/hunde-snacks.jpg',
    itemType: 'entry',
  },
];

/**
 * Mobile menu tree for Dogs
 */
const mobileMenuTreeDogs = (): Array<
  NestedEntryNavigationItem | EntryNavigationItem
> => [
  {
    url: '/pages/hunde',
    title: 'Home',
    itemType: 'entry',
    dividerBottom: true,
  },
  {
    title: 'Snacks',
    itemType: 'nested-entry',
    items: [
      {
        title: 'Alle Snacks',
        url: '/collections/fur-hunde-snacks',
        itemType: 'entry',
      },
      {
        title: 'Überraschungsbox',
        url: '/products/surprise-box-fur-hunde',
        itemType: 'entry',
      },
      {
        title: 'Zahnpflege',
        url: '/collections/fur-hunde-snacks/zahnpflege',
        itemType: 'entry',
      },
      {
        title: 'Functional Snacks',
        url: '/collections/fur-hunde-snacks/functional-snacks',
        itemType: 'entry',
      },
      {
        title: 'Knochen & Kaurollen',
        url: '/collections/fur-hunde-snacks/knochen-and-kaurollen',
        itemType: 'entry',
      },
      {
        title: 'Kaustreifen & Sticks',
        url: '/collections/fur-hunde-snacks/kaustreifen-and-sticks',
        itemType: 'entry',
      },
      {
        title: 'Trainingssnacks',
        url: '/collections/fur-hunde-snacks/trainingssnacks',
        itemType: 'entry',
      },
      {
        title: 'Pasten & Cremesnacks',
        url: '/collections/fur-hunde-snacks/pasten-and-cremesnacks',
        itemType: 'entry',
      },
      {
        title: 'Toppers',
        url: '/collections/fur-hunde-snacks/toppers',
        itemType: 'entry',
      },
    ],
  },
  {
    title: 'Zubehör',
    url: '/collections/fur-hunde-zubehor',
    itemType: 'entry',
  },
  {
    title: 'Pflege & Gesundheit',
    itemType: 'nested-entry',
    items: [
      {
        title: 'Gesundheit',
        url: '/collections/fur-hunde-gesundheit',
        itemType: 'entry',
      },
      {
        title: 'Pflege',
        url: '/collections/fur-hunde-pflege',
        itemType: 'entry',
      },
    ],
  },
  {
    title: 'Service',
    itemType: 'nested-entry',
    items: [
      {
        title: 'Dr. Sam',
        url: '/collections/dr-sam',
        itemType: 'entry',
      },
      {
        title: 'Doguniversity',
        url: '/collections/fur-hunde-service-doguniversity',
        itemType: 'entry',
      },
      {
        title: 'Lassie	',
        url: '/pages/lassie',
        itemType: 'entry',
      },
    ],
  },
  {
    title: 'Angebote',
    url: '/collections/fur-hunde-sale',
    itemType: 'entry',
  },
  {
    title: 'Nassfutter	',
    url: '/collections/fur-hunde-nassfutter',
    itemType: 'entry',

    dividerTop: true,
  },

  {
    title: 'Trockenfutter	',
    url: '/collections/fur-hunde-trockenfutter',
    itemType: 'entry',
  },
  {
    title: 'BARF	',
    url: '/collections/fur-hunde-barf-frische-menus',
    itemType: 'entry',
  },
  {
    title: 'Weihnachtsbasar',
    url: '/collections/weihnachtsbasar-fur-hunde',
    itemType: 'entry',
    dividerBottom: true,
  },
  {
    title: 'Nach Bedürfnis',
    itemType: 'nested-entry',
    items: [
      {
        title: 'Wählerische Esser',
        url: '/collections/fur-hunde-sehr-waehlerisch',
        itemType: 'entry',
      },
      {
        title: 'Verdauungsprobleme',
        url: '/collections/fur-hunde-verdauungsprobleme',
        itemType: 'entry',
      },
      {
        title: 'Haut- & Fellprobleme',
        url: '/collections/fur-hunde-haut-fellprobleme',
        itemType: 'entry',
      },
      {
        url: '/collections/fur-hunde-unvertraeglichkeiten-allergien',
        title: 'Unverträglichkeiten',
        itemType: 'entry',
      },
      {
        url: '/collections/fur-hunde-uebergewicht',
        title: 'Übergewicht',
        itemType: 'entry',
      },
    ],
  },
  {
    title: 'Nach Alter',
    itemType: 'nested-entry',
    items: [
      {
        title: 'Welpe (0-1 Jahre)',
        url: '/collections/fur-hunde-welpen-und-junghunde',
        itemType: 'entry',
      },
      {
        title: 'Adult (1-8 Jahre)',
        url: '/collections/fur-hunde-adult',
        itemType: 'entry',
      },
      {
        title: 'Senior (8+ Jahre)',
        url: '/collections/fur-hunde-senior',
        itemType: 'entry',
      },
    ],
    dividerBottom: true,
  },
  ...dogsCommonBreeds,
  ...commonMobileFooter,
];

const desktopMenuTreeDogs = (): Array<NavigationItem> => [
  {
    items: [
      

      ...dogsCommonNeeds,
      ...dogsCommonBreeds,
    ],
    itemType: 'nested-entry',
  },
  {
    itemType: 'nested-entry',
    items: [...dogsCommonFoodType, {
      title: 'Weihnachtsbasar',
      url: '/collections/weihnachtsbasar-fur-hunde',
      itemType: 'entry',
    },],
  },

  {
    itemType: 'nested-entry',
    items: [
      
      {
        title: 'Zubehör',
        url: '/collections/fur-hunde-zubehor',
        itemType: 'entry',
      },
      {
        title: 'Angebote',
        url: '/collections/fur-hunde-sale',
        itemType: 'entry',
      },
      {
        title: 'Pflege & Gesundheit',
        itemType: 'nested-entry',
        items: [
          {
            title: 'Gesundheit	',
            url: '/collections/fur-hunde-gesundheit',
            itemType: 'entry',
          },
          {
            title: 'Pflege	',
            url: '/collections/fur-hunde-pflege',
            itemType: 'entry',
          },
        ],
      },
      {
        title: 'Service',
        itemType: 'nested-entry',
        items: [
          {
            title: 'Dr. Sam',
            url: '/collections/dr-sam',
            itemType: 'entry',
          },
          {
            title: 'Doguniversity',
            url: '/collections/fur-hunde-service-doguniversity',
            itemType: 'entry',
          },
          {
            title: 'Lassie	',
            url: '/pages/lassie',
            itemType: 'entry',
          },
        ],
      },
    ],
  },
];

/**
 * Mobile menu tree for Cats
 */
const mobileMenuTreeCats = (): Array<
  NestedEntryNavigationItem | EntryNavigationItem
> => [
  {
    url: '/pages/katzen',
    title: 'Home',
    itemType: 'entry',
    dividerBottom: true,
  },
  {
    title: 'Snacks',
    itemType: 'nested-entry',
    items: [
      {
        title: 'Alle Snacks',
        url: '/collections/fur-katzen-snacks',
        itemType: 'entry',
      },
      {
        title: 'Überraschungsbox',
        url: '/products/surprise-box-fur-katzen',
        itemType: 'entry',
      },
      {
        title: 'Creme Snacks',
        url: '/collections/fur-katzen-snacks/creme-snack',
        itemType: 'entry',
      },
      {
        title: 'Soft Snacks',
        url: '/collections/fur-katzen-snacks/soft-snacks',
        itemType: 'entry',
      },
      {
        title: 'Filet Snacks',
        url: '/collections/fur-katzen-snacks/filet-snack',
        itemType: 'entry',
      },
      {
        title: 'Suppen',
        url: '/collections/fur-katzen-snacks/suppe',
        itemType: 'entry',
      },
      {
        title: 'Sensitiv',
        url: '/collections/fur-katzen-snacks/sensitiv',
        itemType: 'entry',
      },
      {
        title: 'Pasten',
        url: '/collections/fur-katzen-snacks/pasten',
        itemType: 'entry',
      },
    ],
  },
  {
    title: 'Nassfutter',
    url: '/collections/fur-katzen-nassfutter',
    itemType: 'entry',
  },
  {
    title: 'Trockenfutter',
    url: '/collections/fur-katzen-trockenfutter',
    itemType: 'entry',
  },
  {
    title: 'Weihnachtsbasar',
    url: '/collections/weihnachtsbasar-fur-katzen',
    itemType: 'entry',
    dividerBottom: true,
  },
  ...catsCommonNeeds,

  {
    title: 'Zubehör',
    url: '/collections/fur-katzen-zubehor',
    itemType: 'entry',
    dividerTop: true,
  },
  {
    title: 'Angebote',
    url: '/collections/fur-katzen-sale',
    itemType: 'entry',
  },
  {
    title: 'Gesundheit',
    url: '/collections/fur-katzen-nahrungserganzung',
    itemType: 'entry',
  },
  {
    title: 'Service',
    itemType: 'nested-entry',
    items: [
      {
        title: 'Dr. Sam',
        url: '/collections/dr-sam',
        itemType: 'entry',
      },
      {
        title: 'Lassie	',
        url: '/pages/lassie',
        itemType: 'entry',
      },
    ],
  },
  ...commonMobileFooter,
];

const productTypesCatsDesktop = (): Array<NavigationItem> => [
  {
    itemType: 'nested-entry',
    items: [
      {
        title: 'Snacks',
        itemType: 'nested-entry',
        items: [
          {
            title: 'Alle Snacks',
            url: '/collections/fur-katzen-snacks',
            itemType: 'entry',
          },
          {
            title: 'Überraschungsbox',
            url: '/products/surprise-box-fur-katzen',
            itemType: 'entry',
          },
          {
            title: 'Creme Snacks',
            url: '/collections/fur-katzen-snacks/creme-snack',
            itemType: 'entry',
          },
          {
            title: 'Soft Snacks',
            url: '/collections/fur-katzen-snacks/soft-snacks',
            itemType: 'entry',
          },
          {
            title: 'Filet Snacks',
            url: '/collections/fur-katzen-snacks/filet-snack',
            itemType: 'entry',
          },
          {
            title: 'Suppen',
            url: '/collections/fur-katzen-snacks/suppe',
            itemType: 'entry',
          },
          {
            title: 'Sensitiv',
            url: '/collections/fur-katzen-snacks/sensitiv',
            itemType: 'entry',
          },
          {
            title: 'Pasten',
            url: '/collections/fur-katzen-snacks/pasten',
            itemType: 'entry',
          },
        ],
      },
      {
        url: '/collections/fur-katzen-nassfutter',
        title: 'Nassfutter',
        itemType: 'entry',
      },
      {
        url: '/collections/fur-katzen-trockenfutter',
        title: 'Trockenfutter',
        itemType: 'entry',
      },
      {
        title: 'Weihnachtsbasar',
        url: '/collections/weihnachtsbasar-fur-katzen',
        itemType: 'entry',
      },
    ],
  },
  {
    itemType: 'nested-entry',
    items: [...catsCommonNeeds],
  },
  {
    itemType: 'nested-entry',
    items: [
      {
        title: 'Zubehör',
        url: '/collections/fur-katzen-zubehor',
        itemType: 'entry',
      },
      {
        title: 'Angebote',
        url: '/collections/fur-katzen-sale',
        itemType: 'entry',
      },
      {
        title: 'Gesundheit',
        url: '/collections/fur-katzen-nahrungserganzung',
        itemType: 'entry',
      },
      {
        title: 'Service',
        itemType: 'nested-entry',
        items: [
          {
            title: 'Dr. Sam',
            url: '/collections/dr-sam',
            itemType: 'entry',
          },
          {
            title: 'Lassie	',
            url: '/pages/lassie',
            itemType: 'entry',
          },
        ],
      },
    ],
  },
];

const useNavigationDataDe = (): INavigationData => {
  return {
    mobile: {
      dogs: [[...mobileMenuTreeDogs()]],
      cats: [[...mobileMenuTreeCats()]],
    },
    category: {
      dogs: [...productTypesDogs()],
      cats: [
        {
          title: 'Katzenstreu',
          url: '/collections/katzenstreu-natur-fur-katzen',
          src: '/images/category-slider/katzenstreu-new.jpg',
          itemType: 'card',
        },
      ],
    },
    footer: footer(),
    trustbar: [...commonTrustbar],
    desktop: [
      {
        title: 'Hunde',
        context: 'dogs',
        url: '/pages/hunde',
        items: [...desktopMenuTreeDogs()],
      },
      {
        title: 'Katzen',
        context: 'cats',
        url: '/pages/katzen',
        items: [...productTypesCatsDesktop()],
      },
    ],
  };
};
export default useNavigationDataDe;
