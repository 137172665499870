import React from 'react';

const Title = (title?: string): React.ReactNode => (
  <>
    {title ? <title>{`${title} - Pets Deli`}</title> : <title>Pets Deli</title>}
    <meta
      property="og:title"
      name="og:title"
      content={`${title ? title + ' - ' : '' + 'Pets Deli'}`}
    />
  </>
);

export default Title;
