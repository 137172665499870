import cn from 'classnames';
import React from 'react';

interface ISeparatorProps {
  layout?: 'dot' | 'line';
}

const Separator: React.FC<ISeparatorProps> = ({ layout = 'dot' }) => {
  return (
    <div
      className={cn(
        'my-auto bg-brand-primary opacity-25',
        layout === 'dot' && 'mx-1 h-1 rounded-full xl:mx-2 xl:w-1',
        layout === 'line' && 'mx-2 h-5 w-px xl:mx-3'
      )}
    />
  );
};

export default Separator;
