export const getAbTestByIdQuery = /* GraphQL */ `
  query getAbTestById($id: String!) {
    experimentConfig(id: $id) {
      sys {
        id
      }
      testData
      baseUrl
      redirectUrl
      ntExperiencesCollection {
        items {
          sys {
            id
          }
          ntType
          ntConfig
          ntName
          ntAudience {
            ntAudienceId
            ntName
            # ntDescription
          }
          # ntDescription
          # ntMetadata
          ntExperienceId
          ntVariantsCollection(limit: 10) {
            items {
              ... on ExperimentConfig {
                sys {
                  id
                }
                testData
                baseUrl
                redirectUrl
                __typename
              }
              # ... on NtExperience {
              #   sys {
              #     id
              #   }
              #   ntName
              #   ntType
              #   ntConfig
              #   ntAudience {
              #     ntAudienceId
              #     ntName
              #   }
              # }
            }
          }
        }
      }
    }
  }
`;
