import {
  FormattedShopifyItem,
  KnownCustomAttributes,
} from 'interfaces/checkout';
import { ShopifyNamespace } from 'interfaces/shopify';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { AttributeInput, CheckoutLineItemInput, Maybe } from 'shopifyTypes';
import { shopifyGid } from 'utils/shopify';
import { CheckoutHookProps, LineItem } from '..';

type AttributeInputObject = {
  [key in string]: string;
};

export const ShopifyItemType = {
  /** one time purchase */
  OneTime: 'oneTime',
  /** redemption gift item */
  RedemptionGift: 'redemption',
  /** subscription item*/
  Subscription: 'subscription',
} as const;

export type ShopifyItemType =
  (typeof ShopifyItemType)[keyof typeof ShopifyItemType];

/** Identify item type (e.g one time purchase or subscription and so on ) based on custom attributes  */
export const getShopifyItemType = (
  item: FormattedShopifyItem
): ShopifyItemType => {
  if (KnownCustomAttributes.Interval in item.customAttributes) {
    return ShopifyItemType.Subscription;
  } else if (
    KnownCustomAttributes.RedeemItem in item.customAttributes &&
    KnownCustomAttributes.RedemptionPoints in item.customAttributes
  ) {
    return ShopifyItemType.RedemptionGift;
  } else {
    return ShopifyItemType.OneTime;
  }
};

/** Format Object type CustomAttributes into AttributeInputObject  */
export const formatCustomAttributes = (
  atr?: AttributeInputObject
): Array<AttributeInput> => {
  if (!atr) return [];
  return Object.keys(atr).map((key) => {
    return {
      key: key,
      value: atr[key],
    };
  });
};

export const areLineItemsEqual = (
  lineItem1: LineItem | CheckoutLineItemInput,
  lineItem2: LineItem | CheckoutLineItemInput
): boolean =>
  lineItem1.variantId === lineItem2.variantId &&
  isEqual(
    lineItem1.customAttributes?.filter(
      (customAttribute) => customAttribute.key !== 'sort'
    ),
    lineItem2.customAttributes?.filter(
      (customAttribute) => customAttribute.key !== 'sort'
    )
  );

/** Format items object into shopify format */
export const formatItems = (
  items: Parameters<CheckoutHookProps['addItems']>[0]
): Array<CheckoutLineItemInput> =>
  items.map((item) => ({
    customAttributes: item.customAttributes,
    quantity: item.quantity || 1,
    variantId: shopifyGid(ShopifyNamespace.ProductVariant, item.variantId),
  }));

/** Merge items if it's the same item */
export const mergeItems = (
  items: Array<CheckoutLineItemInput>
): Array<CheckoutLineItemInput> =>
  items.reduce((result: Array<CheckoutLineItemInput>, lineItem) => {
    const item = result.find((item) => areLineItemsEqual(item, lineItem));
    if (item) {
      item.quantity += lineItem.quantity;
    } else {
      result.push(lineItem);
    }
    return result;
  }, []);

/** Create Error object for shopify api call */
export const createError = ({
  errorType = 'Unexpected',
  operationName,
  lineItems = [],
}: {
  errorType?: string | null;
  operationName: string;
  lineItems?: Array<CheckoutLineItemInput> | null;
}): Error => {
  const ids = lineItems?.map((v) => v.variantId);
  return new Error(
    `${errorType} Error while ${operationName}. Item : ${ids?.toString()}`
  );
};

export const mergeAttributes = (
  arr1: Array<AttributeInput>,
  arr2?: Maybe<Array<AttributeInput>>
): Array<AttributeInput> => {
  if (!arr2) return arr1;

  const attributeMap = new Map<string, string>();
  arr1.forEach((atr) => {
    attributeMap.set(atr.key, atr.value);
  });
  arr2.forEach((atr) => {
    if (isEmpty(atr.value)) {
      attributeMap.delete(atr.key);
      return;
    }
    attributeMap.set(atr.key, atr.value);
  });

  return Array.from(attributeMap.entries()).map((entry) => ({
    key: entry[0],
    value: entry[1],
  }));
};

// Js const
export const ItemMergeKeys = {
  /** Original variant */
  None: 'sort',
  /** Existing customer */
  RedemptionGift: 'redeemItem',
  /** New customer */
  Subscription: 'interval',
} as const;

// TS definitions
export type ItemMergeKeys = (typeof ItemMergeKeys)[keyof typeof ItemMergeKeys];
