import { PetContextValues } from 'contexts/pet';

/** Get PetContextValues from string */
const getPetContextByUrl = (url: string): PetContextValues => {
  switch (true) {
    case url.indexOf(process.env.DOG) > -1:
      return 'dogs';
    case url.indexOf(process.env.PUP) > -1:
      return 'dogs';
    case url.indexOf(process.env.PUPPY) > -1:
      return 'dogs';
    case url.indexOf(process.env.CAT_SHORT) > -1:
      return 'cats';
    case url.indexOf(process.env.KITTEN) > -1:
      return 'cats';
  }

  return false;
};

export default getPetContextByUrl;
