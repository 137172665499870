import isBoolean from 'lodash/isBoolean';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDebounce, useIntersection as _useIntersection } from 'react-use';

/**
 * This is an overloaded function type that can accept two different signatures:
 * 1. `(isInViewport: boolean) => void`: A function that takes a boolean value indicating
 *    whether the target element is currently in the viewport.
 * 2. `(isInViewport: boolean, intersection?: IntersectionObserverEntry | null) => void`:
 *    A function that takes a boolean value indicating whether the target element is currently
 *    in the viewport, and an optional IntersectionObserverEntry object describing the
 *    intersection of the target element with the viewport.
 *    Note that the `intersection` parameter must be explicitly marked as optional using the `?`
 *    operator if you want to omit it when invoking the function.
 */
interface UseIntersectionCallback {
  (isInViewport: boolean): void;
  (
    isInViewport: boolean,
    intersection?: IntersectionObserverEntry | null
  ): void;
}

export interface UseIntersectionProps {
  callBack: UseIntersectionCallback;
  ref: Parameters<typeof _useIntersection>[0];
  options?: Parameters<typeof _useIntersection>[1];
  dwellTime?: number;
  debounce?: number;
}

/**
 * Wrapped hook for useIntersection.
 * Set up hook that tracks the changes in the intersection of a target element with debounce automatically
 * and calls the callback function when the target element is visible in the viewport for a certain amount of time.
 */
const useIntersection = ({
  callBack,
  ref,
  options = {},
  dwellTime = 500, // 0.5 seconds
  debounce = 500,
}: UseIntersectionProps): void => {
  const intersection = _useIntersection(ref, options);
  const [isVisibleForDwellTime, setIsVisibleForDwellTime] = useState(false);
  const dwellTimeout = useRef<NodeJS.Timeout | null>(null);

  const clearDwellTimeout = useCallback(() => {
    if (dwellTimeout.current) {
      clearTimeout(dwellTimeout.current);
      dwellTimeout.current = null;
    }
  }, []);

  useDebounce(
    () => {
      const isInViewport = intersection && intersection?.isIntersecting;
      if (isBoolean(isInViewport)) {
        if (isInViewport && !isVisibleForDwellTime) {
          setIsVisibleForDwellTime(true);

          dwellTimeout.current = setTimeout(() => {
            callBack(true, intersection);
            dwellTimeout.current = null;
          }, dwellTime);

          return clearDwellTimeout;
        } else if (!isInViewport && isVisibleForDwellTime) {
          setIsVisibleForDwellTime(false);
          clearDwellTimeout();
        }
      }
    },
    debounce,
    [intersection, isVisibleForDwellTime]
  );

  useEffect(() => {
    return () => {
      clearDwellTimeout();
    };
  }, [clearDwellTimeout]);
};

export default useIntersection;
