type StorageType = 'localStorage' | 'sessionStorage';

class StorageManager<T> {
  private storage: Storage | null = null;

  constructor(
    private key: string,
    storageType: StorageType = 'localStorage'
  ) {
    if (typeof window === 'undefined') {
      return;
    }

    switch (storageType) {
      case 'sessionStorage':
        this.storage = sessionStorage;
        break;
      default:
        this.storage = localStorage;
    }
  }

  get(): T | undefined {
    if (this.storage === null) {
      return undefined;
    }

    const value = this.storage.getItem(this.key);

    if (value) {
      return JSON.parse(value);
    }

    return undefined;
  }

  set(value: T): T | undefined {
    if (!value || this.storage === null) {
      return;
    }

    this.storage.setItem(this.key, JSON.stringify(value));
  }

  remove(): void {
    if (this.storage !== null) {
      this.storage.removeItem(this.key);
    }
  }
}

export default StorageManager;
