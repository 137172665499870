import { SegmentationIds } from 'constants/segmentation';
import { IExperimentConfigFields } from 'global';

type CollectionHandle = string;
type ProductHandle = string;

/**
 * @example
 * {
 * 'hideVariants': ['123', '456'],
 *'fur-hunde-bulldogge': {
 * showProducts: ['pets-deli-socken'],
 * hideProducts: ['snack-soft-bites-belly-comfort-fur-hunde'],
 * bulldog: {
 *  ribbonTitle: 'Test title 12345 - segment level',
 *  'nassfutter-breed-fit-sensitiv-pferd-mit-susskartoffel-und-nachtkerzenol-fur-hunde':
 *    {
 *      ribbonTitle: 'Test title 12345 - product level',
 *    },
 *  },
 *  },
 * };
 */
export type TestData = {
  hideVariants?: Array<number>;
} & {
  [key in CollectionHandle]?: {
    showProducts?: Array<ProductHandle>;
    hideProducts?: Array<ProductHandle>;
  } & {
    [segment in keyof typeof SegmentationIds]?: {
      ribbonTitle?: string;
    } & {
      [key in ProductHandle]?: {
        ribbonTitle?: string;
      };
    };
  };
};

type SegmentationId = string;
type AlterativeRibbonText = string;
export type AbTestRibbonTextObj = {
  [key in SegmentationId]?: AlterativeRibbonText;
};

export function assertIsTestData(data: unknown): asserts data is TestData {
  if (typeof data !== 'object' || data === null) {
    throw new Error('Data is not an object');
  }

  const testData = data as TestData;

  // Check each key in the TestData type
  for (const key in testData) {
    const value = testData[key];

    if (value !== undefined) {
      // Check for showProducts and hideProducts arrays
      if (
        value.showProducts !== undefined &&
        !Array.isArray(value.showProducts)
      ) {
        throw new Error('showProducts must be an array');
      }
      if (
        value.hideProducts !== undefined &&
        !Array.isArray(value.hideProducts)
      ) {
        throw new Error('hideProducts must be an array');
      }

      // Check for segmentationIds and their properties
      for (const segment in value) {
        if (segment in SegmentationIds) {
          const segmentValue = value[segment];
          if (
            segmentValue !== undefined &&
            typeof segmentValue.ribbonTitle !== 'string'
          ) {
            throw new Error('ribbonTitle must be a string');
          }

          // Check for nested ProductHandle keys
          for (const productKey in segmentValue) {
            if (productKey in SegmentationIds) {
              const productValue = segmentValue[productKey];
              if (
                productValue !== undefined &&
                typeof productValue.ribbonTitle !== 'string'
              ) {
                throw new Error('Nested ribbonTitle must be a string');
              }
            }
          }
        }
      }
    }
  }
}

export function assertIExperimentConfigFields(
  obj: unknown
): asserts obj is IExperimentConfigFields {
  if (!obj || typeof obj !== 'object') {
    console.error('Invalid object:', obj);
    throw new Error('Object is not of type IExperimentConfigFields');
  }
}
