import { SegmentationIds } from 'constants/segmentation';
import { useRouteContext } from 'contexts/route';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

type Input = {
  segmentation?: string;
};

type OutPut = {
  shouldOmitPersonalization: boolean;
};

const ADULT_DOG_CDP = 'fur-hunde-adult';
const ADULT_CAT_CDP = 'fur-katzen-alle-fleischsorten';

const AGE_RELATED_SEGMENTATION_IDS_SET = new Set<SegmentationIds>([
  SegmentationIds.Welpe,
  SegmentationIds.Senior,
  SegmentationIds.Kitten,
  SegmentationIds.CatSenior,
]);

/**
 * Check if PDP and CDP are related to adult pet food based on current and previous url.
 * And check if we should omit personalization as combination might be confusing for user.
 * e.g Segmentation welpe and adult collection page.
 * @see : https://petsdeli.atlassian.net/browse/PT-5327
 */
const useIsAdultRelated = ({ segmentation }: Input): OutPut => {
  const { asPath } = useRouter();

  const { previousPath } = useRouteContext();

  const _segmentation = segmentation as unknown as SegmentationIds;

  // We assume that it's adult related when adult cdp and pdp pages afterwards
  const isAdultRelated = useMemo(() => {
    if (!AGE_RELATED_SEGMENTATION_IDS_SET.has(_segmentation)) {
      return false;
      // in age related cdp page
    } else if (
      asPath.indexOf(ADULT_DOG_CDP) > -1 ||
      asPath.indexOf(ADULT_CAT_CDP) > -1
    ) {
      return true;
      // in pdp page after age related pdp
    } else if (
      previousPath &&
      (previousPath.indexOf(ADULT_DOG_CDP) > -1 ||
        previousPath.indexOf(ADULT_CAT_CDP) > -1) &&
      asPath.indexOf('product') > -1
    ) {
      return true;
    }
    return false;
  }, [asPath, previousPath, _segmentation]);

  const shouldOmitPersonalization = useMemo(() => {
    return !!(
      AGE_RELATED_SEGMENTATION_IDS_SET.has(_segmentation) && isAdultRelated
    );
  }, [isAdultRelated, _segmentation]);

  return {
    shouldOmitPersonalization,
  };
};

export default useIsAdultRelated;
