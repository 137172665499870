export default {
  'common:and': 'und',
  'common:still': 'Noch',
  'common:add': 'Hinzufügen',
  'common:buttons:load-more': 'Mehr anzeigen',
  'common:buttons:learn-more': 'Mehr erfahren',
  'common:buttons:cancel': 'Abbrechen',
  'common:buttons:continue': 'Weiter',
  'common:add-to-cart': 'In den Warenkorb',
  'common:products': 'Produkte',
  'common:promotions': 'Aktionen',
  'common:price:shipping': 'Versand',
  'common:price:subtotal': 'Zwischensumme',
  'common:price:totalPrice': 'Gesamtpreis',
  'common:price:tax': 'inkl. MwSt.',
  'common:price:discount': 'Rabatt',
  'common:price:from': 'ab',
  'common:product:add': 'Hinzufügen',
  'common:product:variant': 'Variante',
  'common:product:quantity': 'Menge',
  'common:product:interval': 'Intervall',
  'common:product:size': 'Grösse',
  'common:product:to-the-porduct': 'Zum Produkt',
  'common:button:confrim': 'Bestätigen',
  'common:input:required': 'Pflichtfeld',
  'common:input:street:number-required':
    'Straße muss mindestens eine Nummer enthalten',
  'common:input:street:letter-required':
    'Straße muss mindestens einen Buchstaben enthalten',
  'common:input:street:min-length':
    'Straße muss mindestens 2 Zeichen lang sein',
  'common:product:out-of-stock': 'Ausverkauft',

  'common:address:shipping-address': 'Lieferadresse',
  'common:address:billing-address': 'Rechnungsadresse',
  'common:payment:payment-method': 'Zahlungsmethode',
  'common:unknown': 'Unbekannt',
  'common:loading': 'Lädt...',
  'common:country:de': 'Deutschland',
  'common:country:at': 'Österreich',
  'common:country:ch': 'Schweiz',
  'common:for-dogs': 'Für Hunde',
  'common:dogs': 'Hunde',
  'common:for-cats': 'Für Katzen',
  'common:cats': 'Katzen',
  'common:birthday': 'Geburtstag',
  'common:add-item': 'Hinzufügen',
  'common:remove-item': 'Entfernen',
  'common:understood': 'Verstanden',

  'common:shop-switcher:CH:button': 'Ja, zum deutschen Shop',
  'common:shop-switcher:CH:button-stay': 'Nein, im Schweizer Shop bleiben',
  'common:shop-switcher:CH:text':
    'Auf petsdeli.ch sind nur Lieferungen in die Schweiz möglich. Bist du Kunde in Deutschland?',

  'common:shop-switcher:DE:button': 'Ja, zum Schweizer Shop',
  'common:shop-switcher:DE:button-stay': 'Nein, im Deutschen Shop bleiben',
  'common:shop-switcher:DE:text':
    'Auf petsdeli.de sind nur Lieferungen nach Deutschland und Österreich möglich. Bist du Kunde in der Schweiz?',

  'common:shop-switcher:title': 'Bist du auf dem richtigen Weg?',

  'common:shop-switcher:PL:button':
    'Tak, przejdź do polskiego sklepu internetowego',
  'common:shop-switcher:PL:text':
    'Na petsdeli.ch możliwe są tylko dostawy do Szwajcarii. Jesteś klientem w Polsce?',

  'common:tabs-accordeon:product-info': 'Produktinformation',
  'common:tabs-accordeon:product-info:plural': 'Produktinformationen',
  'common:breed:bulldog': 'Bulldoggen',
  'common:breed:chihuahua': 'Chihuahuas',
  'common:breed:dachshund': 'Dackel',
  'common:breed:havanese': 'Malteser',
  'common:breed:poodle': 'Pudel',
  'common:breed:retriever': 'Retriever',
  'common:breed:shepherd-dog': 'Schäferhunde',
  'common:breed:spitz': 'Spitz',
  'common:breed:terrier': 'Terrier',
  'common:breed:herdingdog': 'Hütehunde',

  'common:hero-filter:nassfutter': 'Nassfutter',
  'common:hero-filter:trockenfutter': 'Trockenfutter',
  'common:hero-filter:barf': 'BARF',
  'common:hero-filter:snacks': 'Snacks',

  'common:one-time-purchase:title': 'Einmalige Lieferung',
};
