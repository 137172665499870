import { axios } from 'utils/axios';

export interface ApiRequest {
  url: string;
  event: string;
  props?: {};
  user?: Partial<{
    nutritional_needs: string;
    orders_count: string;
    subscription_status: string;
    release_version: string;
    breed: string;
    last_segment: string;
  }>;
}

export const push = async (rq: ApiRequest): Promise<void> => {
  try {
    const url = `https://api.dev.472684997712.petsdeli.de/tracking-dev-daphne/push`;
    if (navigator.sendBeacon) {
      const blob = new Blob([JSON.stringify(rq)], {
        type: 'application/json',
      });
      navigator.sendBeacon(url, blob);
    } else {
      await axios.post<void>(
        `https://api.dev.472684997712.petsdeli.de/tracking-dev-daphne/push`,
        rq
      );
    }
  } catch (error) {
    console.error(error);
    await axios.post<void>(
      `https://api.dev.472684997712.petsdeli.de/tracking-dev-daphne/push`,
      rq
    );
  }
};
