import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { isEmptyObject } from 'utils/type-helper';
import { ErrorKey, SourceKey } from './type';

type OutPut = {
  source: SourceKey;
  error: ErrorKey;
  'error-msg': string;
};

const outputKeys = ['source', 'error', 'error-msg'];

/**
 * Hook to check express checkout error from url query params
 */
const useExpressCheckoutError = (): OutPut | undefined => {
  const { isReady, query } = useRouter();

  const errorObject = useMemo(() => {
    if (isReady && query) {
      return outputKeys.reduce((prev, key) => {
        if (prev && query[key]) {
          prev[key] = Array.isArray(query[key]) ? query[key][0] : query[key];
        }
        return prev;
      }, {} as OutPut);
    }
    return {} as OutPut;
  }, [isReady, query]);

  return isEmptyObject(errorObject) ? undefined : errorObject;
};

export default useExpressCheckoutError;
