import { create } from 'zustand';

interface SearchStoreInterface {
  searchedTerm: string;
  searchedUrlTerm: string;
  setSearchedTerm: (value: string) => void;
  setSearchedUrlTerm: (value: string) => void;
}

/**
 * Zustand Store to manage search term state
 */
export const useSearchStore = create<SearchStoreInterface>((set) => ({
  searchedTerm: '',
  searchedUrlTerm: '',
  setSearchedTerm: (value) => set({ searchedTerm: value }),
  setSearchedUrlTerm: (value) => set({ searchedUrlTerm: value }),
}));
