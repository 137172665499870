import { shopifyGidToId } from '../../utils/shopify';

export const fromShopifyFormat = (variant: any) => ({
  ...variant,
  price: parseFloat(variant.priceV2.amount) * 100,
  id: shopifyGidToId(variant.id),
  gid: variant.id,
});

export const toShopifyFormat = (variant: any) => ({
  ...variant,
  id: variant.gid,
});
