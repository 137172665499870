import { PaymentMethodOption } from 'api/checkout';

export default {
  'account:main-page:title': 'Hallo {firstName}',

  'account:navigation:orders': 'Bestellungen',
  'account:navigation:subscriptions': 'Regelmäßige Lieferungen',
  'account:navigation:refer-friend': 'Freunde werben',
  'account:navigation:last-order': 'Letzte Bestellung erneut bestellen',
  'account:navigation:credits': 'Guthaben',
  'account:navigation:addresses': 'Adressen',
  'account:navigation:logout': 'Abmelden',
  'account:navigation:bonus-program': 'Bonusprogramm',

  'account:bonus-program:title': 'Bonus Programm',

  'account:bonus-program:sub-header': 'Dein aktueller Punktestand',
  'account:bonus-program:expire-information': '{amount} verfallen am {date}',
  'account:bonus-program:description':
    'Hast du schon gewusst, dass du mit jeder Bestellung bei Pets Deli punkten kannst? Jeder ausgegebene Euro bringt dir einen Punkt auf dein Konto und zu besonderen Anlässen verdienst du dir mit tollen Aktionen weitere Punkte dazu.',
  'account:bonus-program:points-amount':
    '{amount, plural, =0 {# Punkte} one {# Punkt} other {# Punkte}}',
  'account:bonus-program:quick-links': 'Schnelllinks',
  'account:bonus-program:redeem-points': 'Punkte in Guthaben umwandeln',
  'account:bonus-program:redeem-points-cta':
    '{pointAmount} Punkte <span>➡</span>    {conversion}',
  'account:bonus-program:redeem-points-fairvalue-cta':
    'Fairvalue {pointAmount} Punkte <span>➡</span> {conversion}',

  'account:bonus-program:donate:cta-info': '{amount} für das Berliner Tierheim',
  'account:bonus-program:donate:donate': 'spenden',

  'account:bonus-program:guide-description':
    'Die Punkte sind ab Erhalt ein Jahr gültig. Das durch Punkte erhaltene Guthaben kann nicht ausgezahlt werden.',

  'account:bonus-program:product-redemption': 'Prämium für Punkte',
  'account:bonus-program:product-redemption-description':
    'Deine Prämie wird mit deiner nächsten Bestellung verschickt.',
  'account:bonus-program:mov-check-message-head': 'Hoppla! ',
  'account:bonus-program:mov-check-message':
    'Dein Warenkorb muss mindestens 20€ betragen, damit du weiter zur Kasse gehen kannst.',
  'account:bonus-program:not-enough-bonus-point':
    'Du hast nicht genügend Bonuspunkte auf deinem Konto.',
  'account:bonus-program:gift-not-enough-point':
    '{pointAmount} Punkte benötigt',
  'account:bonus-program:bp-redemption-item-succesfully-add-to-cart':
    'Ihr Artikel wurde erfolgreich dem Warenkorb hinzugefügt.',

  'account:bonus-program:my-points:redeeem-title': 'Meine Punkte einlösen',
  'account:bonus-program:my-points': 'TRANSAKTIONSVERLAUF',
  'account:bonus-program:my-points-description':
    'Mit nur einem Klick löst du deine gesammelten Punkte in tatsächliches <Link>Guthaben</Link> ein, das bei deiner nächsten Bestellung automatisch angewendet wird.',
  'account:bonus-program:action:redeem-success':
    'Deine Punkte wurden erfolgreich in Guthaben umgewandelt.',

  'account:bonus-program:points': 'Punkt',
  'account:bonus-program:coins': '{transaction} Bonuspunkte',
  'account:bonus-program:action': 'Aktion',
  'account:bonus-program:acquire-date': 'Erhalten am',
  'account:bonus-program:expire-date': 'Gültig bis',
  'account:bonus-program:redeem': 'Einlösung',
  'account:bonus-program:refund': 'Stornierung',
  'account:bonus-program:expired': 'Punkteverfall',
  'account:bonus-program:reward-order': 'Bestellung',
  'account:bonus-program:reward-bonus': 'ExtraPunkte',

  'account:bonus-program:guide-title': 'So funktioniert unser Bonusprogramm',
  'account:bonus-program:guide-subtitle':
    'Sammle mit jedem Einkauf wertvolle Punkte und spare somit in Zukunft bares Geld!',

  'account:bonus-program:guide-2':
    'In deinem Kundenaccount kannst du im Reiter jederzeit deinen aktuellen Punktestatus einsehen.',
  'account:bonus-program:guide-3':
    'Löse die Punkte mit nur einem Klick gegen Rabatte für deinen nächsten Einkauf ein. Worauf wartest du noch?',

  'account:orders:title': 'Bestellungen',
  'account:orders:title-single': 'Bestellung',
  'account:orders:reorder': 'Nachbestellen',
  'account:orders:order-again': 'Erneut bestellen',
  'account:orders:invoice-download': 'Rechnung herunterladen',
  'account:orders:invoice-download-not-ready':
    'Wir müssen deine Lieferung noch bearbeiten, bevor du die Rechnung herunterladen kannst.',
  'account:orders:details': 'Details',
  'account:orders:order-number': 'Bestell-Nr.',
  'account:orders:date': 'Bestelldatum',
  'account:orders:price': 'Preis',
  'account:orders:point': '{points} Punkte',
  'account:orders:payment-status': 'Zahlungsstatus',
  'account:orders:payment-pending': 'Ausstehend',
  'account:orders:payment-authorized': 'Autorisiert',
  'account:orders:payment-paid': 'Bezahlt',
  'account:orders:payment-partially_paid': 'Teilweise bezahlt',
  'account:orders:payment-partially_refunded': 'Teilweise erstattet',
  'account:orders:payment-refunded': 'Erstattet',
  'account:orders:payment-voided': 'Ungültig',
  'account:orders:fulfillment-status': 'Lieferstatus',
  'account:orders:fulfillment-fulfilled': 'Versendet',
  'account:orders:fulfillment-null': 'Ausstehend',
  'account:orders:fulfillment-restocked': 'Zurückgeschickt',
  'account:orders:fulfillment-partial': 'Teilweise versendet',
  'account:orders:fulfillment-track-delivery': 'Lieferung verfolgen',

  'account:refer-friend:title': 'Teile Pets Deli mit deinen Freund*innen!',
  'account:refer-friend:sub-header':
    'Teilen macht einfach Spaß, oder? Lade deine Freund*innen jetzt ein, verschenke {amountFriend} und sicher dir dein {amount}-Guthaben!',
  'account:refer-friend:slider-share-link':
    'Teile Pets Deli einfach per Mail oder Einladungslink mit deinen Freund*innen.',
  'account:refer-friend:slider-friend-join':
    'Das Guthaben in Höhe von {amountFriend} wird automatisch dem Warenkorb deiner Freund*innen hinzugefügt.',
  'account:refer-friend:slider-discount':
    'Du erhältst dein {amount}-Guthaben automatisch, wenn dein*e Freund*in eine Bestellung aufgegeben hat.',

  'account:refer-friend:personal-link':
    '<strong> Dein Einladungslink wurde kopiert </strong> <br></br>Verschick den Link jetzt an deine Freund*innen und sicher dir dein {amount}-Guthaben!',
  'account:refer-friend:email-send':
    '<strong> Deine Einladung wurde versendet </strong> <br></br>Du erhältst dein Guthaben automatisch, wenn dein*e Freund*in eine Bestellung aufgegeben hat.',
  'account:refer-friend:slider-header': 'so funktioniert es',
  'account:refer-friend:share': 'Jetzt teilen!',
  'account:refer-friend:give-email-addresss': 'E-Mail deines Freund*innen:',
  'account:refer-friend:give-email-addresss-subtitle':
    'Bitte gib die E-Mail-Adresse nur mit Einverständnis deiner Freund*innen ein.',
  'account:refer-friend:invitation-button': 'Jetzt Einladung Senden',
  'account:refer-friend:invitation-sent': 'Fertig!',
  'account:refer-friend:share-link': 'Oder teile deinen Code per:',
  'account:refer-friend:warning-title': 'Bitte beachte:',
  'account:refer-friend:warning-sub-title':
    '1. Dein*e Freund*in muss erstmalig eine Bestellung über den Pets Deli Onlineshop mit einem Mindestbestellwert von 40€ aufgeben. Storniert dein*e Freund*in die Bestellung oder bezahlt diese nicht, erhältst du kein {amount}-Guthaben. 2. Eine nachträgliche Nennung geworbener Freund*innen per E-Mail, Fax, Telefon oder auf dem Postweg ist aus technischen Gründen nicht möglich.',
  'account:refer-friend:email-required': 'Bitte ausfüllen!',
  'account:refer-friend:email-invalid':
    'Bitte gib eine gültige E-Mail-Adresse ein!',

  'account:credits:title': 'Guthaben',
  'account:credits:date': 'Datum',
  'account:credits:amount': 'Betrag',
  'account:credits:reason': 'Grund',
  'account:credits:balance': 'Pets Deli Guthaben: ',
  'account:credits:info-title': 'Du hast noch keine Transaktionen.',
  'account:credits:info-text':
    'Falls du ein Guthaben bei Pets Deli hast, werden dir die Transaktionen hier angezeigt. Guthaben erhältst du z.B. durch Pets Deli Aktionen oder Gutschriften.',
  'account:credits:reason-Order Application': 'Kauf',
  'account:credits:reason-Referal': 'Freunde Werben',
  'account:credits:reason-Refund': 'Rückzahlung',
  'account:credits:reason-Customer Support': 'Customer Support',
  'account:credits:reason-Bonusprogramm': 'Bonusprogramm',

  'account:order:title': 'Bestellung #{orderNumber}',

  'account:addresses:title': 'Adressen',
  'account:addresses:edit:title': 'Adresse ändern',
  'account:addresses:create:title': 'Adresse hinzufügen',
  'account:addresses:create-success': 'Adresse erfolgreich hinzugefügt',
  'account:addresses:update-success': 'Adresse erfolgreich erstellt',
  'account:addresses:delete-success': 'Adresse erfolgreich gelöscht',
  'account:addresses:set-default-success': 'Adresse erfolgreich festgelegt',
  'account:addresses:add': 'Adresse hinzufügen',
  'account:addresses:phone': 'Tel.: {phone}',
  'account:addresses:change': 'Ändern',
  'account:addresses:delete': 'Löschen',
  'account:addresses:set-default': 'Als Standardadresse festlegen',
  'account:addresses:is-default': 'Als Standard festgelegt',
  'account:addresses:edit-form:submit': 'Speichern',
  'account:addresses:create-form:submit': 'Speichern',

  'account:payment-methods:title': 'Zahlungsmethoden',
  'account:payment-method:change': 'Ändern',
  'account:payment-method:delete': 'Löschen',

  'account:subscriptions:title': 'Regelmäßige Lieferungen',
  'account:subscriptions:details': 'Details',
  'account:subscriptions:active': 'Aktiv',
  'account:subscriptions:paused': 'Pausiert',
  'account:subscriptions:cancelled': 'Beendet',
  'account:subscriptions:title-title': 'Lieferung <span>{shortId}</span>',
  'account:subscriptions:items-amount':
    '{amount, number} {amount, plural, one {Produkt} other {Produkte}}',
  'account:subscriptions:next-delivery': 'Nächster Versand',
  'account:subscriptions:interval': 'Intervall',
  'account:subscriptions:price': 'Warenwert',
  'account:subscriptions:price:info': '(ohne eventuelle Rabatte und Versandkosten)',
  'account:subscriptions:interval-14': 'alle 2 Wochen',
  'account:subscriptions:interval-28': 'alle 4 Wochen',
  'account:subscriptions:interval-42': 'alle 6 Wochen',
  'account:subscriptions:interval-56': 'alle 8 Wochen',

  'account:subscription:title': 'Lieferung #{shortId}',
  'account:subscription:actions:title': 'Regelmäßige Lieferung anpassen',
  'account:subscription:actions:next-shipping-date':
    'Nächsten Versand verschieben',
  'account:subscription:actions:shipping-address': 'Lieferadresse ändern',
  'account:subscription:actions:billing-address': 'Rechnungsadresse ändern',
  'account:subscription:actions:interval': 'Lieferintervall ändern',
  'account:subscription:products:out-of-stock': 'Zur Zeit nicht erhältlich',
  'account:subscription:products:product-slider:title':
    'Produkt zur Lieferung hinzufügen',
  'account:subscription:products:product-slider:subscription:button':
    'Abo & <span>sparen</span>',
  'account:subscription:products:product-slider:one-off:button':
    'Einmalig bestellen',
  'account:subscription:products:product-slider:subscription:modal:cta-button':
    'Jetz abbonieren',
  'account:subscription:products:product-slider:one-off:modal:cta-button':
    'Einmalig bestellen',
  'account:subscription:products:product-slider:subscription:text':
    'Unsere Snacks im Abo und immer <b>10% sparen</b>!',
  'account:subscription:products:product-slider:one-off:text':
    'Das Produkt wird mit der Abo-Bestellung geliefert.',

  'account:subscription:state-change:reason:not_home':
    'Ich kann die Lieferungen in nächster Zeit nicht empfangen (z.B. Urlaub)',
  'account:subscription:state-change:reason:still_enough':
    'Ich habe noch ausreichend Produkte vorrätig',
  'account:subscription:state-change:reason:try_other':
    'Ich möchte in nächster Zeit andere Produkte probieren',
  'account:subscription:state-change:reason:not_happy':
    'Ich bin unzufrieden mit dem aktuellem Angebot',
  'account:subscription:state-change:reason:by_mistake':
    'Aus Versehen angemeldet',
  'account:subscription:state-change:reason:OOS':
    'Das Produkt ist immer ausverkauft',
  'account:subscription:state-change:reason:expensive':
    'Das Produkt ist zu teuer',
  'account:subscription:state-change:reason:unhappy_web':
    'Die Website war schwer zu bedienen',
  'account:subscription:state-change:reason:too_much':
    'Ich habe noch genug Futter zu Hause',
  'account:subscription:state-change:reason:unhappy_CuHa':
    'Ich kann euren Kundenservice / eure Futterberater*innen nicht erreichen',
  'account:subscription:state-change:reason:adjust':
    'Ich möchte mein Abo anpassen',
  'account:subscription:state-change:reason:aging':
    'Mein Haustier benötigt anderes Futter aufgrund des Alters',
  'account:subscription:state-change:reason:health':
    'Mein Haustier benötigt anderes Futter aus gesundheitlichen Gründen',
  'account:subscription:state-change:reason:food_type':
    'Mein Haustier benötigt eine andere Fütterungsart',
  'account:subscription:state-change:reason:acceptancy':
    'Mein Haustier mag das Futter nicht mehr',
  'account:subscription:state-change:reason:other_supplier':
    'Ich bestelle das Futter jetzt woanders',
  'account:subscription:state-change:reason:delivery':
    'Schlechte Erfahrung mit der Lieferung',
  'account:subscription:state-change:reason:other': 'Anderer Grund',
  'account:subscription:state-change:reason:unhappy_web:text-placeholder':
    'Was genau hat dich gestört?',
  'account:subscription:state-change:reason:other_supplier:text-placeholder':
    'Würdest du uns mitteilen, bei wem du nun das Futter kaufst?',
  'account:subscription:state-change:reason:other:text-placeholder':
    'Erzähl uns gern, welche...',

  'account:subscription:state-change:modal:reason:by_mistake:title':
    'Möchtest du wirklich auf deine Vorteile verzichten?',
  'account:subscription:state-change:modal:reason:too_much:title':
    'Wusstest du, dass du dein Abo anpassen/pausieren kannst?',
  'account:subscription:state-change:modal:reason:acceptancy:title':
    'Möchte dein Haustier etwas Neues ausprobieren?',
  'account:subscription:state-change:modal:reason:aging:title':
    'Wir bieten Futter für jede Altersgruppe an!',
  'account:subscription:state-change:modal:reason:health:title':
    'Pets Deli anbietet Futter für spezielle Bedürfnisse!',
  'account:subscription:state-change:modal:reason:health:sub-title':
    'Beispielsweise bei Allergien, Gewichts- und Akzeptanzproblemen. Pets Deli unterstützt dich bei der Gesundheit deines Tieres!',
  'account:subscription:state-change:modal:reason:food_type:title':
    'Wir sind für dich da!',
  'account:subscription:state-change:modal:reason:food_type:sub-title':
    'Egal, ob du Trockenfutter, Nassfutter, BARF oder eine Mischform füttern möchtest - bei Pets Deli findest du abgestimmte Produkte.',

  'account:subscription:state-change:modal:info':
    'Lern mehr dank unserer kostenlosen Futterberatung:',

  'account:subscription:state-change:modal:button:confirm': 'KÜNDIGEN',
  'account:subscription:state-change:modal:button:pause': 'Pausieren',
  'account:subscription:state-change:modal:button:adjust-date':
    'Termin anpassen',

  'account:subscription:state-change:modal:list:item-1':
    '<span>10% Rabatt</span> bei jeder Bestellung',
  'account:subscription:state-change:modal:list:item-2':
    'Immer ausreichend Futter daheim',
  'account:subscription:state-change:modal:list:item-3':
    'Jederzeit flexibel anpassen oder pausieren',

  'account:subscription:state-change:modal:consultation:title':
    'Lass dich <span>kostenlos</span> durch Futterexpert*innen beraten',
  'account:subscription:state-change:modal:consultation:phone':
    'Ruf direkt durch (+49 30-22 05 6 44 05)',
  'account:subscription:state-change:modal:consultation:appointment':
    'Vereinbar einen Termin <span>hier</span>',

  'account:subscription:state-change:cancel:navigation-title': 'Abo Kündigung',
  'account:subscription:state-change:cancel:title': 'Schade, dass du gehst',
  'account:subscription:state-change:cancel:description':
    'Wir arbeiten stets daran, unser Angebot noch nutzerfreundlicher zu gestalten. Bitte nenne uns daher den Grund für die Beendigung Deiner regelmäßigen Lieferung:',
  'account:subscription:state-change:pause:navigation-title':
    'Lieferung pausieren oder verschieben',
  'account:subscription:state-change:pause:delivery:title':
    'Verschiebe deine Lieferung auf deinen Wunschzeitraum',
  'account:subscription:state-change:pause:delivery:description':
    'Hast du noch genügend Produkte zu Hause oder geht es in den wohlverdienten Urlaub? Dann verschiebe Deine nächste Lieferung einfach um bis zu acht Wochen auf einen späteren Zeitpunkt!',
  'account:subscription:state-change:pause:delivery:datepicker-text':
    'Versand der nächsten Lieferung am....',
  'account:subscription:state-change:pause:open-pause-view-cta':
    'Lieferung pausieren',
  'account:subscription:state-change:pause:delivery-cta': 'Versand verschieben',
  'account:subscription:state-change:pause:reason:title':
    'Alles klar! Noch ein Schritt',
  'account:subscription:state-change:pause:reason:description':
    'Wir arbeiten stets daran, unser Angebot noch nutzerfreundlicher zu gestalten. Bitte nenne uns daher den Grund für die Pausierung Deiner regelmäßigen Lieferung:',
  'account:subscription:state-change:pause:reason:submit': 'Jetzt pausieren',
  'account:subscription:state-change:success-message':
    'Lieferungsstatus erfolgreich geändert',
  'account:subscriptions:info-button': 'Vorteile der regelmäßigen Lieferung',
  'account:subscriptions:info-text':
    'Mit einer regelmäßigen Futterlieferung kannst du jeden Monat bares Geld sparen.',
  'account:subscriptions:info-title':
    'Du hast keine aktive regelmäßige Lieferung',

  'account:subscription:summary:interval': 'Lieferintervall',
  'account:subscription:summary:next-shipping': 'Nächster Versand',
  'account:subscription:summary:billing-address-equal-shipping-address':
    'entspricht Lieferadresse',
  'account:subscription:summary:shipping-method': 'Versandart',
  'account:subscription:summary:status': 'Status',
  'account:subscription:summary:payment-method:credit-card': 'Kreditkarte',

  'account:subscription:items-quantity-change:success-message':
    'Menge erfolgreich geändert',
  'account:subscription:item-recover:success-message':
    'Das Produkt wurde erfolgreich hinzugefügt',
  'account:subscription:next-shipping:title': 'Versand verschieben',
  'account:subscription:next-shipping:current-value': 'Aktuelles Versanddatum',
  'account:subscription:next-shipping:new-value': 'Neues Versanddatum',
  'account:subscription:next-shipping:save-button': 'Versand verschieben',
  'account:subscription:next-shipping:success-message':
    'Lieferung erfolgreich verschoben',
  'account:subscription:interval:title': 'Lieferintervall ändern',
  'account:subscription:interval:current-interval': 'Aktuelles Intervall',
  'account:subscription:interval:new-interval': 'Neues Intervall',
  'account:subscription:interval:save': 'Lieferintervall ändern',
  'account:subscription:interval:success-message':
    'Lieferintervall erfolgreich geändert',
  'account:subscription:shipping-address:title': 'Lieferadresse ändern',
  'account:subscription:billing-address:title': 'Rechnungsadresse ändern',
  'account:subscription:shipping-address:success-message':
    'Lieferadresse erfolgreich geändert',
  'account:subscription:addresses:new-address': 'Neue Adresse hinzufügen',
  'account:subscription:addresses:select-cta': 'An diese Adresse versenden',

  'account:subscription:add-item-cta': '+ weiteres Produkt hinzufügen',

  'account:subscription:status-action:cancel': 'Kündigen',
  'account:subscription:status-action:pause': 'Pausieren',
  'account:subscription:status-action:procede': 'Fortsetzen',

  'account:subscription:remove-item': 'Produkt entfernen',
  'account:subscription:add-item-again': 'Wieder Hinzufügen',
  'account:subscription:active-products': 'Aktive Produkte',
  'account:subscription:deleted-products': 'Entfernte Produkte',
  'account:subscription:modal:delete-item:title':
    'Bist du sicher, dass du dieses Produkt aus deinem Abo löschen möchtest?',
  'account:subscription:modal:delete-item:subtitle':
    ' Dieses Produkt wird aus dem Abo entfernt. Das entfernte Produkt siehst du oben in der Übersicht.',
  'account:subscription:modal:delete-item:cancel': 'Das Produkt behalten',
  'account:subscription:modal:delete-item:confirm': 'Ja, das Produkt löschen',

  'account:subscription:modal:question':
    'Deine Lieferung ist gerade nicht aktiv. Möchtest du diese Lieferung wieder erhalten?',
  'account:subscription:modal:confirmation': 'Ja, Lieferung aktivieren',
  'account:subscription:modal:rejection': 'Nein',

  'account:reset:page-title': 'Neues Passwort vergeben',

  'account:subscription:one-time:more-details': 'Mehr Details',
  'account:subscription:in-process': 'In Bearbeitung',
  'account:subscription:run-immediately': 'Sofort Ausführen',
  'account:subscription:deliver-ondemand:confirmation':
    'Dein Versand wird sofort gestartet.{br}Darf’s noch etwas mehr sein?',
  'account:subscription:deliver-ondemand:oos:confirmation':
    'Hey, leider sind einige deiner Produkte nicht vorrätig',
  'account:subscription:deliver-ondemand:oos:confirmation:all-items-oos':
    'Hey, leider sind alle deine Produkte nicht vorrätig',
  'account:subscription:deliver-ondemand:oos:confirmation:message':
    'Möchtest du deine Bestellung ohne diese Produkte erhalten?',
  'account:subscription:deliver-ondemand:oos:confirmation:message-all-items-oos':
    'Möchtest du eine neue Bestellung erstellen?',
  'account:subscription:deliver-ondemand:oos:confirmation:button:all-items-oos':
    'Ja! Jetzt shoppen',
  'account:subscription:deliver-ondemand:confirmation-speed-delivery':
    'Bestätigen',
  'account:subscription:deliver-ondemand:confirmation-contain-oos': 'Weiter',
  'account:subscription:deliver-ondemand:confirmation-added-item':
    'Hinzugefügt',
  'account:subscription:speed-delivery-subtitle':
    '*Das Produkt wird mit der Abo Bestellung geliefert.',
  'account:subscription:deliver-ondemand:customise-subscription':
    'Mein Abo anpassen',
  'account:subscription:progress-step-1': 'Produkte',
  'account:subscription:progress-step-2': 'Bestätigung',
  'account:subscription:progress-step-3': 'Erledigt',
  'account:subscription:modal-success-message-title':
    'Deine Bestellung wird noch heute bearbeitet!',
  'account:subscription:modal-success-message-subtitle':
    'In Kürze erhältst du eine Bestätigung darüber per Email.',
  'account:subscription:info-bar:text':
    '<span>Oder Produkte stöbern und direkt zum Abo hinzufügen. Mehr in den <a>FAQ</a></span>',

  'account:subscription:payment-reauthorisation:header':
    'Bitte autorisiere eine Zahlungsmethode für dein Abonnement',
  'account:subscription:payment-reauthorisation:body':
    'Wir haben unseren Zahlungsanbieter gewechselt.{br}Bitte authorisiere eine der folgenden Zahlungsmethoden, um in Zukunft deine regelmäßigen Lieferungen zu gewährleisten.',
  'account:subscription:payment-reauthorisation:saved-payment-method':
    'Deine aktuellen Zahlungsinformationen:',
  'account:subscription:payment-reauthorisation:new-payment-method':
    'Wähle eine Zahlungsmethode für dein Abo:',
  'account:subscription:payment-reauthorisation:button:confirm': 'Bestätigen',
  [`account:subscription:payment-reauthorisation:option:${PaymentMethodOption.CreditCard}`]:
    'Karte',
  [`account:subscription:payment-reauthorisation:option:${PaymentMethodOption.PayPal}`]:
    'PayPal',
  'account:subscription:payment-reauthorisation:current-payment-method':
    'Die aktuelle Zahlungsmethode verwenden',
  'account:subscription:mov-modal:your request failed':
    'Deine Anfrage ist fehlgeschlagen',
  'account:subscription:mov-modal:make sure your subtotal is':
    'Bitte stelle sicher, dass die Zwischensumme {br} deines Einkaufs über {mov} liegt.',
  'account:subscription:mov-modal:why-mov': 'Warum {mov} Mindestbestellwert?',
  'account:subscription:mov-modal:mov-explanation':
    'Jedes Paket und jeder Versand verursacht CO2-Emissionen.Indem wir darauf achten, dass Pakete eine bestimmte Größe haben, reduzieren wir automatisch Verpackungsmüll und Emissionen und leisten so einen kleinen Beitrag zum Umweltschutz. {br} {br} Vielen Dank für deine Mithilfe!',
  'account:subscription:mov-modal:request help':
    'Hilfe vom Kundenservice anfordern',
  'account:upsell-product:cta': 'Mitbestellen',
  'account:upsell-product:cta-item-added': 'Hinzugefügt',
};
