import { Experience } from '@ninetailed/experience.js-next';
import { IEntry } from 'global';
import React from 'react';
import { parseExperiences } from 'utils/ninetailed';

interface ExperienceWrapperProps<P = any> {
  entry: IEntry;
  component: React.ComponentType<P>;
  loadingComponent?: React.ReactNode;
}

/**
 * Wrapper component for Experience.
 * @TODO : all the props Experience component should be able to override
 */
const ExperienceWrapper: React.FC<ExperienceWrapperProps> = ({
  entry,
  component,
}) => {
  const Component = component;

  return (
    <Experience
      {...entry.fields}
      id={entry.sys.id}
      experiences={parseExperiences({
        entry,
      })}
      component={Component}
    />
  );
};

export default ExperienceWrapper;
