import { Session } from './types';

export interface Event<P = undefined> {
  name: string;
  properties?: P;
  session?: Partial<Session>;
}

export const pageView = (): Event => ({
  name: 'pageView',
});

//deprecated once optimize is gone
export const optimizeActivate = (): Event => ({
  name: 'optimize.activate',
});

//Session Dimensions
export const setSession = (
  session: Partial<Session>
): Event<Partial<Session>> => ({
  name: 'Set Session',
  session,
});

function serializeElementName(element: string | string[]) {
  return `#${typeof element === 'string' ? element : element.join(' #')}`;
}
// Component Tracking

type ElementActionProps = {
  element: string | string[];
  list?: string;
};

export type ElementEvent = Event<
  Omit<ElementActionProps, 'element'> & {
    element?: string;
  }
>;
export const selectElement = elementEvent.bind(null, 'Select');
export const unselectElement = elementEvent.bind(null, 'Unselect');
export const viewElement = elementEvent.bind(null, 'View');
export const scrollElement = elementEvent.bind(null, 'Scroll');
export const zoomElement = elementEvent.bind(null, 'Zoom');

function elementEvent<
  P extends {
    element: string | string[]; //["Mein Hund Ist sehr waehlerisch"]
    list?: string;
  },
>(name: string, properties: P): ElementEvent {
  const { element, ...rest } = properties;
  const serialized = serializeElementName(element);
  console.log(name, 'Element', ':', serialized);
  return {
    name: `${name} Element`,
    properties: {
      ...(element
        ? {
            element: serialized,
          }
        : {}),
      ...rest,
    },
  };
}

//Session Dimensions
export const captureInput = (props: {
  element: string | string[]; //["Mein Hund Ist sehr waehlerisch"]
  input?: string | number | boolean;
}): Event<{ element: string; input?: string | number | boolean }> => ({
  name: 'Capture Input',
  properties: {
    element: serializeElementName(props.element),
    input: props.input,
  },
});
