import deTranslations from '../de';
import account from './account';
import bonuspoint from './bonuspoint';
import cart from './cart';
import checkout from './checkout';
import footer from './footer';
import header from './header';
import productDetails from './product-details';

export default {
  ...deTranslations,
  ...account,
  ...bonuspoint,
  ...footer,
  ...header,
  ...checkout,
  ...cart,
  ...productDetails,
};
