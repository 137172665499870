import account from './account';
import addressForm from './address-form';
import auth from './auth';
import cart from './cart';
import checkout from './checkout';
import collection from './collection';
import common from './common';
import cookieBanner from './cookie-banner';
import coronaFaq from './corona-faq';
import discountFrom from './discount-form';
import errors from './errors';
import expressCheckout from './express-checkout';
import foodaid from './foodaid';
import footer from './footer';
import funnel from './funnel';
import header from './header';
import landingpage from './landingpage';
import magazine from './magazine';
import nutritionPlan from './nutrition-plan';
import pages from './pages';
import periodicSubscription from './periodic-subscription';
import personalizedRibbon from './personalized-ribbon';
import productDetails from './product-details';
import productRecommendations from './product-recommendations';
import puppyClubForm from './puppy-club-form';
import questionnaire from './questionnaire';
import searchResult from './search-result';
import seo from './seo';
import stories from './stories';
import subscriptionFunnel from './subscription-funnel';
import tracking from './tracking';
import trust from './trust';
import validation from './validation';

export default {
  ...account,
  ...addressForm,
  ...auth,
  ...cart,
  ...checkout,
  ...collection,
  ...common,
  ...cookieBanner,
  ...coronaFaq,
  ...discountFrom,
  ...errors,
  ...expressCheckout,
  ...foodaid,
  ...footer,
  ...funnel,
  ...header,
  ...landingpage,
  ...magazine,
  ...nutritionPlan,
  ...pages,
  ...personalizedRibbon,
  ...periodicSubscription,
  ...productDetails,
  ...productRecommendations,
  ...questionnaire,
  ...searchResult,
  ...seo,
  ...stories,
  ...subscriptionFunnel,
  ...tracking,
  ...trust,
  ...validation,
  ...puppyClubForm,
};
