export default {
  'search-result:results-for': 'Suchergebnisse für',
  'search-result:results':
    '{count, number} {count, plural, one {Ergebnis} other {Ergebnisse}}',
  'search-result:no-results:header': 'Du hast gesucht nach ‘{term}’',
  'search-result:no-results:sub-header':
    'Versuche es mit einem anderen Suchbegriff oder prüfe die Schreibweise.',
  'search-result:no-param:header': 'Ups!',
  'search-result:page-title':
    'Pets Deli Premium Tiernahrung | Gesundes Hunde- & Katzenfutter - Pets Deli',
  'search-result:page-title:dogs':
    'Pets Deli Hundefutter | BARF, Nassfutter, Trockenfutter und mehr - Pets Deli',
  'search-result:page-title:cats':
    'Premium Katzenfutter | BARF, Nassfutter und Trockenfutter - Pets Deli',
  'search-result:bestseller-section-title:dogs': 'Beliebt bei Hunden',
  'search-result:bestseller-section-title:cats': 'Beliebt bei Katzen',
};
