import { CheckoutCreateMutation } from 'shopifyTypes';
import { Product } from '../interfaces/product';
import { ShopifyNamespace } from '../interfaces/shopify';

/**
 * Assert function for CheckoutCreateMutation
 */
export function isCheckoutCreateMutation(
  arg: unknown
): arg is CheckoutCreateMutation {
  if (typeof arg === 'object' && !!arg && 'checkoutCreate' in arg) {
    return true;
  }
  return false;
}

export const normalizeShopifyResponse = (data: any): any => {
  if (typeof data !== 'object' || data === null) {
    return data;
  }

  if ('edges' in data) {
    return data.edges.map(({ node }: any) => normalizeShopifyResponse(node));
  }

  if (Array.isArray(data)) {
    return data.map(normalizeShopifyResponse);
  }

  return Object.keys(data).reduce((result, key) => {
    if (key === '__typename') {
      return result;
    }
    result[key] = normalizeShopifyResponse(data[key]);
    return result;
  }, {} as any);
};

/**
 * Convert product id or variant id into Shopify format.
 * used to make requests to Shopify graphql endpoint.
 * @example : "gid://shopify/Product/10079785100"
 * @see : https://shopify.dev/api/storefront/2023-04/scalars/ID
 */
export const shopifyGid = (
  namespace: ShopifyNamespace,
  id: string | number
): string => `gid://shopify/${namespace}/${id}`;

/**
 * Retrieve product id or variant id from Shopify format.
 * used to make requests to Petsdeli backend
 * @example : "10079785100"
 */
export const shopifyGidToId = (gid: string): number => {
  try {
    const matched = gid.match(/.*\/(?<id>.+?)(\?|$)/);
    if (!matched) {
      throw new Error('Not correct Shopify Id');
    }

    const { id } = matched.groups as { id: string };
    return Number(id);
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const generateVariantUrl = (product: Product, variant: any) => {
  return `${product.onlineStoreUrl}?variant=${variant.id}`;
};

export const getShopifyImageUrl = (
  url: string,
  width: number | undefined,
  height?: number | undefined
) => {
  if (!width && !height) {
    return url;
  }

  const dimensionts = `${width || ''}x${height || ''}`;
  const match = url.match(/(.*)(\.\w+)(.*)/);
  if (match) {
    const [, part1, extension, part2] = match;
    return `${part1}_${dimensionts}${extension}${part2}`;
  }

  return url;
};
