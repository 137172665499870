import useImpressionTracking, {
  useImpressionTrackingProps,
} from 'hooks/gtm/use-impression-tracking';
import React, { Children, cloneElement, isValidElement, useRef } from 'react';
import { Gtm, gtm } from 'tracking/gtm';

export interface ImpressionTrackerProps {
  /** Name to identify tracking store */
  trackingGroupName: string;
  /** Id to individual event and used for impression tracking event name */
  trackingLabel: string;
  /** useImpressionTrackingProps */
  options?: useImpressionTrackingProps['options'];
  /** Custom event */
  customEvent?: () => void;
}

/**
 * Automatically track impression of child.
 * if not specified, Component event are used.
 * Child should be wrapped with div or other tags so that tracker works properly
 * @deprecated use instead ImpressionTrackerNew
 */
const ImpressionTracker: React.FC<
  React.PropsWithChildren<ImpressionTrackerProps>
> = ({
  children,
  trackingGroupName,
  trackingLabel,
  options = { threshold: 0.9 },
  customEvent,
}) => {
  const ref = useRef(null);
  useImpressionTracking({
    impressionStoreKey: trackingGroupName,
    impressionId: trackingLabel,
    trackingFn: (isInViewport) => {
      if (isInViewport) {
        if (customEvent) {
          customEvent();
        } else {
          gtm({
            group: Gtm.GroupName.Component,
            name: Gtm.ComponentEventName.Impression,
            data: {
              label: `${trackingLabel}`,
            },
          });
        }
      }
    },
    ref,
    options,
  });

  const child = Children.only(children);

  if (isValidElement<any>(child)) {
    return cloneElement(child, { ref });
  }

  return <>{children}</>;
};

export default ImpressionTracker;
