export const getAllSpecialTestIdsQuery = /* GraphQL */ `
  query getAllSpecialTestIds {
    experimentConfigCollection(
      # fetch entries where special test is true and connected to Ninetailed experience.
      where: { isSpecialTest: true, nt_experiencesCollection_exists: true }
    ) {
      items {
        sys {
          id
        }
      }
    }
  }
`;
