import React from 'react';

const useLockBodyScroll = (locked: boolean): void => {
  // Store the scroll position because it will get lost if we position HTML element fixed
  const scrollPosition = React.useRef(0);

  const reset = React.useCallback(() => {
    const element = document.documentElement;

    element.style.removeProperty('position');
    element.style.removeProperty('width');
    element.style.removeProperty('top');
    element.style.removeProperty('overflow');

    // restore scroll position
    document.documentElement.scrollTop = scrollPosition.current;
  }, []);

  React.useEffect(() => {
    const element = document.documentElement;

    if (locked) {
      // temporarily store scroll position
      scrollPosition.current = document.documentElement.scrollTop;

      // iOS won't render minimal ui on scroll when HTML element is position fixed
      element.style.setProperty('position', 'fixed');
      element.style.setProperty('width', 'calc(100vw + 0px)');

      // visually we wanna fake the scroll position by setting the top property
      element.style.setProperty('top', `-${scrollPosition.current}px`);
      element.style.setProperty('overflow', 'hidden');
    } else {
      reset();
    }
  }, [locked, reset]);
};

export default useLockBodyScroll;
