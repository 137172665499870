import useExperiment from 'hooks/common/use-experiment';
import React, { useEffect } from 'react';
import { useCart } from './cart';

/**
 * Place holder to set up global test.
 * Should be useful because the all important global context is available on this point.
 */
const GlobalTestHolder: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { reFetchEffectiveCart } = useCart();

  /**
   * QA :: FE :: Setup test on /version page to verify tracking behaviour.
   * @SEE : https://petsdeli.atlassian.net/browse/PT-6564
   */
  useExperiment({
    baseEntryId: 'KFgwVfvBRoICtKKFkf51v',
  });

  /**
   * AB test: AB Test green vs. red price communication.
   * @SEE : https://petsdeli.atlassian.net/browse/PT-6262
   */
  const { variant, loading } = useExperiment({
    baseEntryId: 'sa1m0LCcyIE5fr5LlQ98g',
  });

  useEffect(() => {
    const style = document.createElement('style');
    style.id = 'pt-6262';
    style.textContent = `
    .text-red-base, .bg-red-base {
      color: transparent;
      background-color: transparent;
    }
    `;
    document.head.append(style);

    if (!loading && variant === 0) {
      style.textContent = `
      .text-red-base {
        color: #F44336;
      }
      .bg-red-base {
        background-color: #F44336;
      }
      `;
    } else if (!loading && variant === 1) {
      style.textContent = `
      .text-red-base {
        color: #31C48D;
      }
      .bg-red-base {
        background-color: #31C48D;
      }
      `;
    }

    return () => {
      const style = document.querySelector('style#pt-6262');
      if (style) {
        style.remove();
      }
    };
  }, [loading, variant]);

  /**
   * AB test: hardcode subscription first order(FO) discount of certain user audience and for certain product
   * @SEE : https://petsdeli.atlassian.net/browse/PT-5471
   */
  useExperiment({
    baseEntryId: '2YR6EsyBVFAKqh7OEANfwE',
    shouldAddToHeader: true,
    // When user is not eligible to A/B test, then we need to recalcitrate cart.
    // NC => EC
    onExperimentChange: async (profile) => {
      // Unfortunately, propagation of profile update -> cookie ->  experience header
      // happens outside of react state management.
      // we need to make sure it ends by timeout is done.
      setTimeout(async () => {
        await reFetchEffectiveCart();
      }, 2000);
    },
  });

  return <>{children}</>;
};

export default GlobalTestHolder;
