import { Address, PdAddress } from './address';
import { EffectiveCart } from './cart';
import { CheckoutCustomer, PdCustomer } from './customer';
import { PdProductItem } from './line-item';
import { DiscountType } from './order';
import { ProductType } from './product';

export type FormattedShopifyCheckModel = {
  /** Sum of items  */
  totalItemsQuantity: number;
  /** A globally-unique identifier. */
  id: string;
  items: Array<FormattedShopifyItem>;
};

export const KnownCustomAttributes = {
  Sort: 'sort',
  Interval: 'interval',
  RedeemItem: 'redeemItem',
  RedemptionPoints: 'redemptionPoints',
} as const;

export type KnownCustomAttributes =
  (typeof KnownCustomAttributes)[keyof typeof KnownCustomAttributes];

export type FormattedShopifyItem = {
  /** Converted shopify globally-unique identifier, transformed with shopifyGidToId */
  id: number;
  title: string;
  /** A globally-unique identifier in shopify  */
  gid: string;
  quantity: number;
  customAttributes: {
    [key in KnownCustomAttributes]: string;
  };
  price: number;
  variant: {
    /** A globally-unique identifier in shopify  */
    gid: string;
    id: number;
    title: string;
    weight: number;
    availableForSale: boolean;
    sku: string;
    priceV2: {
      amount: string;
    };
    image: {
      /** A globally-unique identifier in shopify  */
      id: string;
      src: string;
      altText: string | null;
    };
    product: {
      tags: Array<string>;
      productType: ProductType;
      /** A globally-unique identifier in shopify  */
      id: string;
      handle: string;
    };
  };
};

export interface PetsDeliCheckout {
  cart: {
    requiresShipping: {
      BOOL: boolean;
    };
    items: Array<PdProductItem>;
  };
  cid: number;
  createdAt: number;
  creditBalance: number;
  customer: CheckoutCustomer;
  id: string;
  shopifyCheckoutId: string;
}

export interface ExpressCheckout {
  actions: {
    capture: string;
    charge: string;
    summary: string;
  };
  addresses: {
    shipping: Address;
    billing: Address;
  };
  customer: {
    id: number;
    email: string;
    ordersCount: number;
    referalToken: string;
  };
  payment: {
    code: 'PAYPAL';
    displayLabel: string;
    resultCode: string;
  };
  provider: {
    params: {
      checkoutSessionId: string;
    };
  };
  source?: {
    cart?: {
      discount?: any;
      gifts?: {
        donated?: boolean;
        selected?: string;
      };
      items: Array<PdProductItem>;
      recurring?: boolean | null;
    };
  };
  effectiveCart: EffectiveCart;
  requestId: string;
  statusCode: 'CREATED' | 'COMPLETED';
}

export interface ExpressCheckoutSummary {
  addresses: {
    shipping: PdAddress;
  };
  customer: PdCustomer;
  effectiveCart: EffectiveCart;
  requestId: string;
  payment: {
    gateway: string;
    method: string;
  };
}

export type ApplePaymentPaymentRequest = {
  method: 'applepay';
  checkoutId: string;
  listParams: {
    cancelUrl: string;
    summaryUrl: string;
    returnUrl: string;
  };
  cart: {
    items?: EffectiveCart['items'];
    discount?: {
      code?: string;
      type?: DiscountType;
    };
  };
  payment: {
    billingContact?: ApplePayJS.ApplePayPaymentContact;
    shippingContact?: ApplePayJS.ApplePayPaymentContact;
    token: ApplePayJS.ApplePayPaymentToken;
    shippingMethod: string;
  };
};
