import ExperienceWrapper from 'components/experience-wrapper';
import { useCommon } from 'contexts/common';
import useIsClient from 'hooks/common/use-is-client';
import PromoBarComponent from 'modules/content-cms/sections/promo-bar';
import React, { memo } from 'react';

const GlobalPromoBar: React.FC = () => {
  const { mainPromoBar } = useCommon();

  const { isClient } = useIsClient();

  if (isClient && mainPromoBar) {
    return (
      <ExperienceWrapper entry={mainPromoBar} component={PromoBarComponent} />
    );
  }

  return null;
};

export default memo(GlobalPromoBar);
