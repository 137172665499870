import { create } from 'zustand';

export const MODAL_STATUS = {
  CLOSE: 'CLOSE',
  STANDARD_OPEN: 'STANDARD_OPEN',
  PAYPAL_OPEN: 'PAYPAL_OPEN',
  APPLEPAY_OPEN: 'APPLEPAY_OPEN',
} as const;

export type ModalStatus = (typeof MODAL_STATUS)[keyof typeof MODAL_STATUS];

type CartStore = {
  modalOosStatus: ModalStatus;
  setModalOosStatus: (status: ModalStatus) => void;
};

/**
 * Zustand Store used for the cart
 */
export const useCartStore = create<CartStore>((set) => ({
  modalOosStatus: MODAL_STATUS.CLOSE,
  setModalOosStatus: (status: ModalStatus) => set({ modalOosStatus: status }),
}));
