import { FeatureBundle, LazyMotion } from 'framer-motion';

interface LazyMotionWrapperProps {
  children: React.ReactNode;
  strict?: boolean;
}

const loadFeatures = (): Promise<FeatureBundle> =>
  import('utils/framer-motion-features').then((res) => res.default);

/**
 * Wrapper component for using the LazyMotion component offered by Framer Motion.
 * This allows the bundle size to be reduced as shown here
 * @see https://www.framer.com/docs/guide-reduce-bundle-size/
 */
const LazyMotionWrapper: React.FC<LazyMotionWrapperProps> = ({
  children,
  strict = true,
}) => (
  <LazyMotion features={loadFeatures} strict={strict}>
    {children}
  </LazyMotion>
);

export default LazyMotionWrapper;
