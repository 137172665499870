import ImpressionTracker, {
  ImpressionTrackerProps,
} from 'components/impression-tracker';
import React, { Children, cloneElement, isValidElement } from 'react';
import { Gtm, gtm } from 'tracking/gtm';

interface ClickImpressionTrackerProps
  extends Omit<ImpressionTrackerProps, 'customEvent'> {
  customClickEvent?: () => void;
  customImpressionEvent?: ImpressionTrackerProps['customEvent'];
}

/**
 * Tracks impression and click event of child.
 * if not specified, Component event are used.
 */
const ClickImpressionTracker: React.FC<
  React.PropsWithChildren<ClickImpressionTrackerProps>
> = ({
  children,
  trackingGroupName,
  trackingLabel,
  options = { threshold: 0.9 },
  customClickEvent,
  customImpressionEvent,
}) => {
  const onClickTracking =
    customClickEvent ||
    function () {
      gtm({
        group: Gtm.GroupName.Component,
        name: Gtm.ComponentEventName.Click,
        data: {
          label: `${trackingLabel}`,
        },
      });
    };

  const child = Children.only(children);

  if (isValidElement<any>(child)) {
    const onClick = child.props.onClick
      ? async () => {
          await child.props.onClick();
          onClickTracking();
        }
      : onClickTracking;
    const cloned = cloneElement(child, { onClick });
    return (
      <ImpressionTracker
        {...{
          trackingGroupName,
          trackingLabel,
          options,
          customEvent: customImpressionEvent,
        }}
      >
        {cloned}
      </ImpressionTracker>
    );
  }

  return <>{children}</>;
};

export default ClickImpressionTracker;
