import cn from 'classnames';
import { useSegmentationContext } from 'contexts/segmentation';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import { PetContextValues, usePetContext } from '../../../../contexts/pet';

interface IPrimaryLinkProps {
  petContextAware: PetContextValues | undefined;
  href: string;
  onClick: () => void;
}

const PrimaryLink: React.FC<React.PropsWithChildren<IPrimaryLinkProps>> = ({
  children,
  petContextAware,
  href,
  onClick,
}) => {
  const { asPath } = useRouter();
  const { pushSegmentationFactory } = useSegmentationContext();
  const pushSegmentation = pushSegmentationFactory({ location: 'navi' });

  const handleSegmentation = useCallback((): void => {
    if (
      (asPath.includes(process.env.CATS) && href.includes(process.env.CATS)) ||
      (asPath.includes(process.env.DOGS) && href.includes(process.env.DOGS))
    ) {
      return;
    } else {
      pushSegmentation({ segmentation: '' });
    }
  }, [asPath, href, pushSegmentation]);

  const { current } = usePetContext();
  return (
    <Link href={href} passHref>
      <a
        data-test={`nav-${children}`}
        className={cn(
          'flex h-14 w-28 items-center justify-center self-end rounded-t-lg border-r border-brand-primary px-4 pt-1 text-lg font-black last:border-r-0',
          petContextAware === current
            ? 'bg-white text-brand-primary'
            : 'text-white'
        )}
        onClick={() => {
          onClick();
          handleSegmentation();
        }}
      >
        {children}
      </a>
    </Link>
  );
};

export default PrimaryLink;
