import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

interface Context {
  routePaths: (string | null)[];
  previousPath: string | null;
}

const RouteContext = React.createContext({} as Context);

const RouteProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [routePaths, setRoutePaths] = useState<(string | null)[]>(
    new Array(4).fill(null)
  );

  const { asPath, events } = useRouter();
  useEffect(() => {
    const routeChangeStart = () => {
      setRoutePaths((prev) => {
        const next = [...prev];
        next.shift();
        next.push(asPath);
        return next;
      });
    };

    events.on('routeChangeStart', routeChangeStart);
    return () => {
      events.off('routeChangeStart', routeChangeStart);
    };
  }, [events, asPath]);

  const previousPath = routePaths[routePaths.length - 1];

  return (
    <RouteContext.Provider value={{ routePaths, previousPath }}>
      {children}
    </RouteContext.Provider>
  );
};
export default RouteProvider;

const useRouteContext = (): Context => React.useContext(RouteContext);

export { RouteProvider, useRouteContext };
