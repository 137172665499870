import AxiosLib, { AxiosError } from 'axios';
import identity from 'lodash/identity';
import {
  errorsHandlingInterceptor,
  requestHandlingInterceptor,
} from '../api/axios-interceptors';

const axios = AxiosLib.create();
axios.interceptors.request.use(requestHandlingInterceptor());
axios.interceptors.response.use(identity, errorsHandlingInterceptor);

const axiosWithoutRequestInterceptor = AxiosLib.create();
axiosWithoutRequestInterceptor.interceptors.response.use(
  identity,
  errorsHandlingInterceptor
);

/**
 * Assert function for AxiosError.
 */
function isAxiosError<T = any>(error: any): error is AxiosError<T> {
  return (error as AxiosError).isAxiosError !== undefined;
}

export { axios, axiosWithoutRequestInterceptor, isAxiosError };
