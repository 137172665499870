export default {
  'subscription-funnel:page-title': 'Pets Deli Futterabo-Konfigurator',
  'subscription-funnel:dog': 'Mein Hund',
  'subscription-funnel:cat': 'Meine Katze',
  'subscription-funnel:WET_FOOD': 'Nassfutter',
  'subscription-funnel:DRY_FOOD': 'Trockenfutter',
  'subscription-funnel:BARF': 'BARF',
  'subscription-funnel:SNACKS': 'Snacks',
  'subscription-funnel:SUPPLEMENTS': 'Nahrungsergänzung',
  'subscription-funnel:HEALTH': 'Gesundheit',
  'subscription-funnel:CAT_LITTER': 'Katzenstreu',
  'subscription-funnel:ACCESSORY': 'Zubehör',
  'subscription-funnel:button:continue': 'Weiter',

  'subscription-funnel:step:preference': 'Präferenz',
  'subscription-funnel:step:products': 'Produkte',
  'subscription-funnel:step:overview': 'Übersicht',
  'subscription-funnel:step:confirm': 'Bestätigung',

  'subscription-funnel:step:payment': 'Bezahlen',
  'subscription-funnel:step:confirmation': 'Erledigt',

  'subscription-funnel:start:header':
    'In 4 Schritten zu deinem persönlichen Pets Deli Futterabo',
  'subscription-funnel:start:list:item-1':
    'Spare mit jeder Lieferung! Im Abo bieten wir dir dauerhaft 10% Rabatt auf deine Lieblingsprodukte.',
  'subscription-funnel:start:list:item-2':
    'Ohne Mindestlaufzeit kannst du dein Abo jederzeit kündigen oder pausieren. Versprochen!',
  'subscription-funnel:start:list:item-3':
    'Volle Flexibilität! Wähle ein individuelles Lieferintervall und entscheiden wann du dein nächstes Paket erhalten möchtest.',
  'subscription-funnel:start:button-configure': '  Jetzt konfigurieren',

  'subscription-funnel:preference:header': 'Wähle deine Präferenzen',
  'subscription-funnel:preference:sub-header':
    'Egal ob für den Hund oder die Katze, nur Nassfutter oder ein Mix aus BARF und Trockenfutter, du hast die Wahl!',
  'subscription-funnel:preference:question-1':
    'Wen möchtest du mit deinem Abo glücklich machen?',
  'subscription-funnel:preference:question-2':
    'Welches Futter soll in dem Abo enthalten sein?',
  'subscription-funnel:preference:selection-info': 'Mehrfachauswahl möglich',
  'subscription-funnel:preference:button:continue': 'Wähle deine Präferenzen',

  'subscription-funnel:products:header': ' Wähle deine Produkte',
  'subscription-funnel:products:sub-header':
    'Klicke dazu einfach auf die Produktkacheln. Über das Info Icon erhältst du noch mehr Information.',
  'subscription-funnel:products:info-loading':
    'Passende Produkte für deinen Vierbeiner laden...',
  'subscription-funnel:products:modal:info-loading':
    'Produkt-Info wird geladen...',
  'subscription-funnel:products:results': 'Ergebnisse',

  'subscription-funnel:overview:header': 'Deine Übersicht',
  'subscription-funnel:overview:header-existing-order': 'Deine Bestätigung',

  'subscription-funnel:overview:sub-header':
    'Du hast es fast geschafft. Überprüfe noch einmal, dass du auch keine Produkte vergessen hast.',

  'subscription-funnel:overview:sub-header-existing-order':
    'Du hast es fast geschafft! Bitte bestätige die Auswahl der Produkte, die neu zu deinem Abo hinzugefügt werden sollen.',

  'subscription-funnel:overview:new-items-existing-order':
    'Neue Produkte in deinem Abo:',

  'subscription-funnel:overview:delivery-address':
    'Deine Lieferadresse und voraussichtliches Lieferdatum',

  'subscription-funnel:overview:delivery-date':
    'Deine Bestellung wird in Kürze bearbeitet und voraussichtlich bis zum {dateStr} fertig sein',

  'subscription-funnel:overview:button:to-checkout': 'Zur Kasse',
  'subscription-funnel:overview:button:to-subscription-update': 'Bestätigen',

  'subscription-funnel:confirmation:sub-header':
    'Du kannst dein Abo jederzeit in deinem Account anpassen',

  'subscription-funnel:confirmation:cta': 'Jetzt Anpassen',

  'subscription-funnel:cart-show': 'Abo anzeigen',
  'subscription-funnel:cart-hide': 'Abo ausblenden',

  'subscription-funnel:existing-subscription:cart-show':
    'Bestellübersicht anzeigen',
  'subscription-funnel:existing-subscription:cart-hide':
    'Bestellübersicht ausblenden',
};
