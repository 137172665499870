export default {
  'cart:ch:free-shipping':
    'Ab CHF 100 versenden wir Deine Lieferung kostenfrei!',
  'cart:bonus-program:info-benefit-1': '1 CHF Einkaufswert = 1 Punkt',
  'cart:bonus-program:info-benefit-2': '200 Bonuspunkte = 5 CHF',
  'cart:bonus-program:info-benefit-3': '500 Bonuspunkte = 15 CHF',
  'cart:summary:shipping:free-test': '<div><span>CHF 9.95</span>CHF 0.00</div>',
  'cart:shipping:existing-customer':
    'Versand <span>(Kostenfrei ab  CHF 100)</span>',
};
