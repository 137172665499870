import { HttpStatus } from 'constants/http-status';
import { axios } from 'utils/axios';
import { omitNullableHandler } from 'utils/type-helper';

export interface StartFubeResponse {
  caseId: string;
  nextQuestion: QuestionView;
}

export interface SubmitAnswerResponse {
  status: boolean;
  isNextQuestionAvailable: boolean;
  /** True if there's no available recommend products  */
  contactSupport: boolean;
  nextQuestion?: QuestionView;
}

export type PreviousQuestionResponse = {
  previousQuestion: QuestionView;
  caseData: Answer;
};

export interface CurrentQuestionResponse {
  currentQuestion: QuestionView;
}

export const ProductType = {
  Barf: 'barf',
  Wetfood: 'wetfood',
  Dryfood: 'dryfood',
} as const;

export type ProductType = (typeof ProductType)[keyof typeof ProductType];

export const ProteinGroup = {
  /** chicken */
  Poultry: 'poultry',
  BeefAndVeal: 'beef_and_veal',
  Horse: 'horse',
  Wild: 'wild',
  Fish: 'fish',
  Lamb: 'lamb',
  Goat: 'goat',
  Kangaroo: 'kangaroo',
  VegetarianOrVegan: 'vegetarian_or_vegan',
};

export type ProteinGroup = (typeof ProteinGroup)[keyof typeof ProteinGroup];

const ProteinOrder = [
  ProteinGroup.Poultry,
  ProteinGroup.BeefAndVeal,
  ProteinGroup.Horse,
  ProteinGroup.Wild,
  ProteinGroup.Fish,
  ProteinGroup.Lamb,
  ProteinGroup.Goat,
  ProteinGroup.Kangaroo,
  ProteinGroup.VegetarianOrVegan,
];

export const FoodType = {
  Barf: 'barf',
  Wetfood: 'wetfood',
  Dryfood: 'dryfood',
  Mousse: 'mousse',
  SaucenMenu: 'saucenmenu',
  PureMeat: 'puremeat',
  FiletMenu: 'filetmenu',
  Pate: 'pate',
};

export type FoodType = (typeof FoodType)[keyof typeof FoodType];

export const AgeCategory = {
  Adult: 'adult',
  Puppy: 'puppy',
  Kitten: 'kitten',
};

export type AgeCategory = (typeof AgeCategory)[keyof typeof AgeCategory];

export const Ingredient = {
  Amaranth: 'amaranth',
  Asparagus: 'asparagus',
  Basil: 'basil',
  Beef: 'beef',
  Beetroot: 'beetroot',
  Broccoli: 'broccoli',
  Buckwheat: 'buckwheat',
  Buffalo: 'buffalo',
  Calf: 'calf',
  Carrot: 'carrot',
  Catnip: 'catnip',
  Chicken: 'chicken',
  Chickpeas: 'chickpeas',
  Cranberry: 'cranberry',
  Dairy: 'dairy',
  Dandelion: 'dandelion',
  Deer: 'deer',
  Duck: 'duck',
  Eggs: 'eggs',
  FishOil: 'fish oil',
  Goat: 'goat',
  Hempseed: 'hemp seed',
  HempseedOil: 'hemp seed oil',
  Horse: 'horse',
  Insects: 'insects',
  Kangaroo: 'kangaroo',
  Lamb: 'lamb',
  Lentils: 'lentils',
  LinseedOil: 'linseed oil',
  Millet: 'millet',
  OliveOil: 'olive oil',
  Parsnip: 'parsnip',
  Peas: 'peas',
  Pheasant: 'pheasant',
  Potato: 'potato',
  Pumpkin: 'pumpkin',
  Quinoa: 'quinoa',
  Rabbit: 'rabbit',
  Rice: 'rice',
  SafflowerOil: 'safflower oil',
  Salmon: 'salmon',
  Spinach: 'spinach',
  SunflowerOil: 'sunflower oil',
  SweetPotato: 'sweet potato',
  Tapioca: 'tapioca',
  Trout: 'trout',
  Tuna: 'tuna',
  Turkey: 'turkey',
  Unknown: 'unknown',
  WildBoar: 'wild boar',
  Yeast: 'yeast',
  Yucca: 'yucca',
  Zucchini: 'zucchini',
};

export type Ingredient = (typeof Ingredient)[keyof typeof Ingredient];

export const Disease = {
  AcuteDiarrhea: 'accute diarrhea',
  Gastrointestinal: 'gastrointestinal',
  Diarrhea: 'accute diarrhea',
  RenalFailure: 'renal failure',
  ChronicLiverFailure: 'chronic liver failure',
  Osteoarthritis: 'osteoarthritis',
  StruviteStones: 'struvite stones',
  DiabetesMellitus: 'diabetes mellitus',
  UrateStones: 'urate stones',
  StorageMites: 'storage mites',
  ExocrinePancreaticInsufficiency: 'exocrine pancreatic insufficiency',
  None: 'none',
  Other: 'other',
} as const;

export type Disease = (typeof Disease)[keyof typeof Disease];

export interface RecommendableVariant {
  productId: string;
  variantId: string;
  productTitle: string;
  shop: 'jzk1';
  productType: ProductType;
  foodType: FoodType;
  petType: PetType;
  ageCategory: AgeCategory;
  breed: string;
  allergies: Ingredient[];
  diseases: Disease[];
  fatPercentage: string;
  caloriesPer100G: string;
  score: string;
  inventoryQuantity: string;
  productHandle: string;
  imageSrc: string;
  weightInGrams: string;
  variantTitle: string;
  price: string;
}

export interface QuestionView {
  /**
   *  Id for question in the format of q-{index},
   * @example
   * "q-0" "q-1"
   */
  id: string;
  question: Question;
}

export interface Question {
  /** Human readable id to identify the question, such as "weightKg"*/
  id: string;
  name: string;
  required?: (keyof Answer)[];
  class: QuestionType;
  props: QuestionProps;
}

export type QuestionType =
  | 'Select'
  | 'Text'
  | 'Composed'
  | 'FuzzyBirthday'
  | 'Autocomplete'
  | 'Separator'
  | 'Slider';

export interface QuestionProps {
  key: keyof Answer;
  dictKey?: string;
  validations?: [
    {
      exp: string;
      hint: string;
    },
  ];
  style?: string;
  /** Array of answer options. When it's yes/no question, it's array of boolean  */
  options?: string[] | boolean[];
  children?: Question[];
  apiGetTerm?: string;
  text?: string;
  petTypeVersions?: boolean;
  min?: number;
  max?: number;
  step?: number;
  marks?: number[];
  unit?: string;
  inputAddon?: string;
  type?: string;
  attributes?: {
    min?: number;
    step?: number;
    naturalNumbersOnly?: boolean;
  };
  multi?: boolean;
  none?: string;
}

export type PetType = 'dog' | 'cat';

/**
 * API input value for Fube Api.
 * which is sync with segmentation ids
 */
export const QuestionSequenceName = {
  /** default value if it's not specified */
  Foodaid: 'foodaid',
  /** intolerances or allergies */
  Intolerance: 'intolerance',
  /** VeryPicky */
  PickyEater: 'pickyeater',
  /** DigestionIssues */
  Digestion: 'digestion',
  /** Skin and coat problems */
  SkinFur: 'skin-fur',
  /** Specific Disease */
  Disease: 'disease',
  /** Puppy */
  Welpe: 'welpe',
  /** Senior  */
  Senior: 'senior',
} as const;

export type QuestionSequenceName =
  (typeof QuestionSequenceName)[keyof typeof QuestionSequenceName];

export interface Answer {
  /** Pet name */
  petName?: string;
  /** Pet breed */
  breed?: string;
  /**
   * Pet birth date, in the format of {year}-{mm}-{dd}
   * @example  "2010-12-31"
   * */
  dateOfBirth?: string;
  /** Pet weight */
  weightKg?: number;
  /** Purpose of foodaid */
  weightGoal?: string;
  /** Pet has any allergies or intolerances */
  allergies?: string[];
  otherAllergies?: string[];
  /** Pet has any illnesses */
  illnesses?: string[];
  /** Food type preference */
  foodTypePreferences?: string[];
  /** Pet's owner email */
  ownerEmail?: string;
  /** True if a pet is neutered */
  neutered?: boolean;
}

/**
 * Old interfaces. We keep it for the sake of references unless we know it's unnecessary
 */
interface CachedProductVariant {
  shop: string;
  product: NormalizedProduct;
  variant: NormalizedVariant;
}
/**
 * Old interfaces. We keep it for the sake of references unless we know it's unnecessary
 */
interface NormalizedVariant {
  id: number;
  price: string;
  compareAtPrice: string;
  sku: string;
  title: string;
  grams: number;
  imageSrc: string;
  metafields: Metafield[];
  createdAt: string;
  updatedAt: string;
  inventoryQuantity: number;
}

export interface NormalizedProduct {
  id: number;
  title: string;
  handle: string;
  type: string;
  imageSrc: string;
  tags: string[];
  metafields: Metafield[];
  createdAt: number;
  updatedAt: number;
}

export interface Metafield {
  id: number;
  namespace: string;
  key: string;
  value: string | number | boolean;
  type: string;
}
/**
 * start foodaid questions
 */
export const fetchFoodaidStart = async (params: {
  petType: PetType;
  sequenceName: QuestionSequenceName;
}): Promise<StartFubeResponse> =>
  axios
    .post(`${process.env.API_ORIGIN_FOODAID_URL}/start`, params, {
      headers: {
        shopid: process.env.SHOP_ID,
      },
    })
    .then((response) => response.data);

/**
 * fetch submit foodaid questions
 */
export const fetchSubmitAnswers = async (
  caseId: string,
  params: { questionId: string; answer: Answer }
): Promise<SubmitAnswerResponse> =>
  axios
    .post(`${process.env.API_ORIGIN_FOODAID_URL}/submit/${caseId}`, params)
    .then((response) => response.data);

/**
 * fetch previous foodaid questions
 */
export const fetchPreviousQuestion = async (
  caseId: string
): Promise<PreviousQuestionResponse> =>
  axios
    .get(`${process.env.API_ORIGIN_FOODAID_URL}/previous/${caseId}`)
    .then((response) => response.data);

/**
 * fetch current foodaid questions
 */
export const fetchCurrentQuestion = async (
  caseId: string
): Promise<CurrentQuestionResponse> => {
  return axios
    .get(`${process.env.API_ORIGIN_FOODAID_URL}/current/${caseId}`)
    .then((response) => response.data);
};

export type FetchRecommendationsResponse =
  | {
      kcalsPerDay: number;
      petName: string;
      maxProductSelection: number;
      products: Array<string>;
    }
  | { shouldContactSupport: boolean };

/**
 * fetch recommendations
 */
export const fetchRecommendations = async ({
  caseId,
  size,
}: {
  caseId: string;
  /**  The size of the products recommended. */
  size: number;
}): Promise<FetchRecommendationsResponse> => {
  return axios
    .get(`${process.env.API_ORIGIN_FOODAID_URL}/recommendations/${caseId}`, {
      params: { size },
      errorHandling: {
        statusesToOmit: [HttpStatus.BAD_REQUEST],
      },
    })
    .then((response) => response.data);
};

export type RecommendResponse = {
  kcalsPerDay: number;
  petName: string;
  maxSelection: number;
  products: Partial<
    Record<
      ProteinGroup,
      {
        order: number;
        recommendations: RecommendableVariant[];
      }
    >
  >;
};

export type RecommendationsByProteinResponse =
  | RecommendResponse
  | { shouldContactSupport: boolean };

export type FormattedRecommendationsByProteinResponse =
  | (Omit<RecommendationsByProteinResponse, 'products'> & {
      products: Array<Record<ProteinGroup, RecommendableVariant[]>>;
    })
  | { shouldContactSupport: boolean };

/**
 * Fetch recommended products for a fube case grouped by protein.
 */
export const getRecommendationsByProtein = async ({
  caseId,
}: {
  caseId: string;
}): Promise<
  FormattedRecommendationsByProteinResponse | { shouldContactSupport: boolean }
> => {
  return axios
    .get<RecommendationsByProteinResponse>(
      `${process.env.API_ORIGIN_FOODAID_URL}/recommendations-by-protein/${caseId}`
    )
    .then((response) => response.data)
    .then((data) => {
      if (typeof data['shouldContactSupport'] === 'boolean') {
        return data as { shouldContactSupport: boolean };
      }
      const _data = data as unknown as RecommendResponse;
      const products = _data.products;
      const proteinSort = Object.entries(_data.products).map((entry) => {
        const protein = entry[0];
        const order = entry[1]?.order as number;
        return {
          protein,
          order,
        };
      });
      proteinSort.sort((a, b) => a.order - b.order);

      const sourtedP = proteinSort
        .map((value) => value.protein)
        .map((key) => {
          return products[key]
            ? {
                [key]: products[key]?.recommendations,
              }
            : null;
        })
        .filter(omitNullableHandler);
      return {
        ..._data,
        products: sourtedP as Array<
          Record<ProteinGroup, RecommendableVariant[]>
        >,
      };
    });
};

export type NutritionalPlanCoverage = Record<
  Interval,
  {
    kcal: number;
    price: number;
    quantity: number;
    variantId: number;
    productId: string;
    mainProtein: Ingredient;
    proteinGroup: ProteinGroup;
    foodType: FoodType;
    variantTitle: string;
    productTitle: string;
    imageSrc: string;
    productHandle: string;
  }[]
>;
type Interval = '2week' | '4week' | '6week' | '8week';

export type GetNutritionalPlanResponse = {
  kcalPerDay: number;
  options: {
    fullCoverage: NutritionalPlanCoverage;
    halfCoverage: NutritionalPlanCoverage;
  };
};

/**
 * Fetches the recommendations for subscriptions.
 * Backend considers OOS situation and only returns available options
 */
export const getNutritionalPlan = async ({
  caseId,
  productIds,
}: {
  caseId: string;
  productIds: Array<string>;
}): Promise<GetNutritionalPlanResponse> => {
  return axios
    .get(`${process.env.API_ORIGIN_FOODAID_URL}/nutritional-plan/${caseId}`, {
      params: {
        productIds: productIds.toString(),
      },
      errorHandling: {
        statusesToOmit: [HttpStatus.BAD_REQUEST],
      },
    })
    .then((response) => response.data);
};
