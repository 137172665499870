import useExperiment from 'hooks/common/use-experiment';
import {
  TestDataConfig5970,
  TestDataConfig5971,
  assertIsTestDataConfig,
  assertTestDataConfig,
} from 'interfaces/reviews-test';
import React, { createContext, useContext, useEffect, useState } from 'react';

interface ReviewsTestContextType {
  loading5970: boolean;
  loading5971: boolean;
  testData5970: TestDataConfig5970 | undefined;
  testData5971: TestDataConfig5971 | undefined;
  variant5970: number | undefined;
  variant5971: number | undefined;
}

const ReviewsTestContext = createContext<ReviewsTestContextType>(
  {} as ReviewsTestContextType
);

/**
 * Provider for the ReviewsTestContext
 * @see https://petsdeli.atlassian.net/browse/PT-5970
 * @see https://petsdeli.atlassian.net/browse/PT-5971
 */
export const ReviewsTestContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const {
    variant: variant5970,
    testData: testData5970,
    loading: loading5970,
  } = useExperiment({
    baseEntryId: '4FZJWuYoDzwi2QaQhYcxoe',
  });
  const {
    variant: variant5971,
    testData: testData5971,
    loading: loading5971,
  } = useExperiment({
    baseEntryId: '6Ga1OL8HT5nvev1RaWH5jY',
  });

  const [_testData5970, setTestData5970] = useState<
    undefined | TestDataConfig5970
  >();

  useEffect(() => {
    if (testData5970 && (variant5970 === 1 || variant5970 === 2)) {
      try {
        assertTestDataConfig(testData5970);
        setTestData5970(testData5970);
      } catch (error) {
        console.warn('error in testData', error);
      }
    }
  }, [testData5970, variant5970]);

  const [_testData5971, setTestData5971] = useState<
    undefined | TestDataConfig5971
  >();

  useEffect(() => {
    if (testData5971) {
      try {
        assertIsTestDataConfig(testData5971);
        setTestData5971(testData5971);
      } catch (error) {
        console.warn('error in testData', error);
      }
    }
  }, [testData5971]);

  const value = {
    loading5970: loading5970,
    loading5971: loading5971,
    testData5970: _testData5970,
    testData5971: _testData5971,
    variant5970: variant5970,
    variant5971: variant5971,
  };

  return (
    <ReviewsTestContext.Provider value={value}>
      {children}
    </ReviewsTestContext.Provider>
  );
};

export const useReviewsTest = (): ReviewsTestContextType =>
  useContext(ReviewsTestContext);
