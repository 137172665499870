import { useRouter } from 'next/router';
import React from 'react';
import { useLocalStorage } from 'react-use';

interface Context {
  referalToken?: string | undefined | null;
  setReferalToken: (token: string | undefined | null) => void;
}

const ReferalContext = React.createContext({} as Context);

const ReferalProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { query } = useRouter();
  const [refererReferalToken, setRefererReferalToken] = useLocalStorage<
    string | undefined | null
  >('pd:referer-referal-token', undefined);

  React.useEffect(() => {
    const token = query['referal-token'] || false;
    if (token) {
      setRefererReferalToken(token as string);
    }
  }, [query, setRefererReferalToken]);

  return (
    <ReferalContext.Provider
      value={{
        referalToken: refererReferalToken,
        setReferalToken: setRefererReferalToken,
      }}
    >
      {children}
    </ReferalContext.Provider>
  );
};

const useReferalToken = (): Context => React.useContext(ReferalContext);

export { ReferalProvider, useReferalToken };
