import IconCheckMark from 'assets/icons/checkmark-new.svg';
import ModalSlideUp from 'components/modal-slide-up';
import { FormattedMessage } from 'react-intl';

interface TastyGuaranteePromoBarModalProps {
  open: boolean;
  onClose: () => void;
}
const TastyGuaranteePromoBarModal: React.FC<
  TastyGuaranteePromoBarModalProps
> = ({ open, onClose }) => {
  const titleSvg = <IconCheckMark className="mr-2 h-7 w-6 text-gold" />;

  return (
    <>
      <ModalSlideUp
        open={open}
        onClose={onClose}
        titleSvg={titleSvg}
        title={
          <FormattedMessage id="header:tastyguarantee:money-back:modal:title" />
        }
        titleClassName="text-brand-primary"
      >
        <div className="text-brand-primary">
          <div className="pt-2">
            <FormattedMessage
              id="header:tastyguarantee:money-back:modal:sub-title"
              values={{
                b: (chunks) => <b>{chunks}</b>,
              }}
            />
          </div>
          <span className="font-black">
            <FormattedMessage id="header:tastyguarantee:money-back:modal:list-title" />
          </span>
          <ol className="list-decimal px-4">
            <li className="pt-2">
              <FormattedMessage
                id="header:tastyguarantee:money-back:modal:step-1"
                values={{
                  a: (chunks) => (
                    <a
                      href="https://help.petsdeli.de/hc/de/requests/new?ticket_form_id=5537409186332"
                      target="_blank"
                      className="font-medium underline"
                      rel="noreferrer"
                    >
                      {chunks}
                    </a>
                  ),
                }}
              />
            </li>
            <li className="pt-2">
              <FormattedMessage id="header:tastyguarantee:money-back:modal:step-2" />
            </li>
            <li className="pt-2">
              <FormattedMessage id="header:tastyguarantee:money-back:modal:step-3" />
            </li>
          </ol>
        </div>
      </ModalSlideUp>
    </>
  );
};

export default TastyGuaranteePromoBarModal;
