import cookie, { CookieSerializeOptions } from 'cookie';
import { create } from 'zustand';

export type CookieObject = { [cookieName: string]: string };

type CookieStore = {
  cookies: CookieObject;
  updateCookie: (
    name: string,
    value: string,
    options?: CookieSerializeOptions
  ) => void;
};

const initialCookies =
  typeof window !== 'undefined' ? cookie.parse(document.cookie) : {};

export const useCookieStore = create<CookieStore>((set) => ({
  cookies: initialCookies,
  updateCookie: (name, value, options) => {
    if (typeof window !== 'undefined') {
      document.cookie = cookie.serialize(name, value, options);
      set({ cookies: cookie.parse(document.cookie) });
    }
  },
}));
