export const VariantFragment = /* GraphQL */ `
  fragment VariantFragment on ProductVariant {
    id
    title
    weight
    availableForSale
    sku
    priceV2 {
      amount
    }
    image {
      id
      src: originalSrc
      altText
    }
  }
`;

export const VariantWithProductFragment = /* GraphQL */ `
  fragment VariantWithProductFragment on ProductVariant {
    ...VariantFragment
    product {
      tags
      productType
      id
      handle
    }
  }
  ${VariantFragment}
`;
