export default {
  'trust:title': 'Ausgezeichnete Qualität',
  'trust:seals:pdj-title': 'Futter Tester.de',
  'trust:seals:klimaneutral-title': 'ClimatePartner',
  'trust:seals:trusted-shop-title': 'Trusted Shops',
  'trust:seals:trusted-shop-rating': '„SEHR GUT“',
  'trust:seals:trusted-shop-review': '4.89/5.00 bei über 4.116 Bewertungen',
  'trust:seals:ethical-award': 'Ethical Award',
  'trust:button': 'Unser Versprechen',
};
