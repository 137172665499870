export default {
  'periodic-subscription:main-title': 'REGELMÄßIG LIEFERN',
  'periodic-subscription:descripton':
    'Mit einem Abonnement genießt du alle Vorteile unserer zahlreichen Produkte - vom Futter bis zum Zubehör für dein Haustier.',
  'periodic-subscription:why-subscribe': 'WIESO ANMELDEN?',
  'periodic-subscription:manage-subscription':
    'EINFACHE BEARBEITUNG DEINES ABONNEMENTS',
  'periodic-subscription:manage-subscription-description':
    'Deine regelmäßigen Lieferungen kannst du ganz einfach mit nur wenigen Klicks in deinem Account unter “Mein Konto” anpassen.',
  'periodic-subscription:subscribe-benefit': 'JETZT ANMELDEN UND PROFITIEREN',
};
