import { useEffect, useState } from 'react';

/**
 * Check if Apple Pay is available in the browser.
 */
const useApplePayAvailability = (): boolean => {
  const [isApplePayAvailable, setIsApplePayAvailable] = useState(false);

  useEffect(() => {
    if (window.ApplePaySession) {
      setIsApplePayAvailable(true);
    }

    return () => setIsApplePayAvailable(false);
  }, []);

  return isApplePayAvailable;
};

export default useApplePayAvailability;
