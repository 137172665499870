import { AddressResponse } from 'api/customer';
import { Address } from '../../interfaces/address';

export const phoneToServerFormat = (phone?: string) =>
  phone && phone.replace('+41', '0041');

export const fromServerFormat = (address: AddressResponse): Address => ({
  id: address.id,
  firstName: address.firstName,
  lastName: address.lastName,
  city: address.city,
  address1: address.address1,
  zip: address.zip,
  countryCode: address.countryCode,
  company: address.company,
  phone: address.phone || '',
  default: address.default,
});

export const toServerFormat = (address: Address) => ({
  id: address.id,
  firstName: address.firstName,
  lastName: address.lastName,
  city: address.city,
  address1: address.address1,
  zip: address.zip,
  countryCode: address.countryCode,
  company: address.company,
  phone: address.phone || null,
  default: address.default,
});

export const toPdServerFormat = (address: Address) => ({
  id: address.id,
  firstName: address.firstName,
  lastName: address.lastName,
  street: address.address1,
  postcode: address.zip,
  city: address.city,
  country: address.countryCode,
  phone: address.phone || null,
  default: address.default,
  company: address.company,
});
